/* eslint-disable no-nested-ternary */
import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../../flexyui';

type props = {
  text: string;
  headerText?: string;
  setShowModal: (data: any) => void;
  showModal: boolean;
  onSave: () => void;
  onCancel?: () => void;
  loading: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmActionVariant?: 'default' | 'outline' | 'destructive';
};
const ConfirmationDialog: React.FC<props> = ({
  showModal,
  setShowModal,
  text,
  headerText = 'Are you sure?',
  onSave,
  onCancel,
  loading,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  confirmActionVariant = 'default'
}) => {
  return (
    <AlertDialog open={showModal} onOpenChange={(value: boolean) => setShowModal(value)}>
      <AlertDialogContent close={true} closeAsWarning={true}>
        <div>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center gap-3 py-2">
              <div>
                <Unicons.UilExclamationTriangle
                  color={confirmActionVariant === 'destructive' ? '#EA4335' : '#FFCC00'}
                  size={30}
                />
              </div>
              <p> {headerText}</p>
            </AlertDialogTitle>
            <AlertDialogDescription className="text-gray-600 !mt-4">{text}</AlertDialogDescription>
          </AlertDialogHeader>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel
            variant={'outline'}
            size={'md'}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}
          >
            {cancelButtonText}
          </AlertDialogCancel>
          <AlertDialogAction
            variant={confirmActionVariant}
            size={'md'}
            onClick={async () => {
              onSave();
              setShowModal(false);
            }}
          >
            {loading ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : confirmButtonText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationDialog;
