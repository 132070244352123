import { AppRoutes } from '../../container/routes';
import { Navbar } from '../../components/navbar/navbar';
import { Sidebar } from '../../components/sidebar/sidebar';

function AuthenticatedApp() {
  return (
    <div className="h-full">
      <Navbar />
      <Sidebar />
      <div className="lg:ml-56 min-h-[calc(100dvh-48px)] scroll-smooth bg-[#fbfbfb] mt-12">
        <AppRoutes />
      </div>
    </div>
  );
}
export default AuthenticatedApp;
