// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from '@tanstack/react-table';

import React from 'react';
import { cn } from '../../../lib/utils';
import { Button } from '../Button';

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column?: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (column && !column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <div className={cn('flex items-center whitespace-nowrap', className)}>
      <Button variant="ghost" size="sm" className="h-8 data-[state=open]:bg-accent">
        <span className="font-medium">{title}</span>
      </Button>
    </div>
  );
}
