import { UilPlus } from '@iconscout/react-unicons';
import React, { useCallback, useEffect, useState } from 'react';
import { UpsellCollectionCard } from '../../components/upsell/collection-card';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { useCollectionsQuery, useUpdateUpSellingCollectionsMutation } from '../../graphql';
import useSaveCancelButtons from '../../hooks/use-save-cancel';
import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Skeleton } from '../../components/flexyui';
import { CollectionSelectionDialog } from '../../components/shared/collection-selection/collection-selection';

type props = {
  collectionModal: boolean;
  setCollectionModal: (data: boolean) => void;
};

const Collection: React.FC<props> = ({ collectionModal, setCollectionModal }) => {
  const [activeId, setActiveId] = useState(null);
  const [collections, setCollections] = useState<Array<any>>([]);
  const [collectionsDuplicate, setCollectionsDuplicate] = useState<Array<any>>([]);
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();
  const [updateUpsellingCollections, { loading }] = useUpdateUpSellingCollectionsMutation();
  const { data, refetch, loading: collectionLoading } = useCollectionsQuery();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setCollections((items) => {
        const oldIndex = items.findIndex((item) => item.collection_id === active.id);
        const newIndex = items.findIndex((item) => item.collection_id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const deleteCollectionCard = async (collection: any) => {
    const updatedCollections = collections.filter(
      (_collection) => collection.collection_id !== _collection.collection_id
    );

    setCollections([...updatedCollections]);
  };

  const updateCollections = useCallback(() => {
    if (data?.upSellingConfig.collections) {
      setCollections(data?.upSellingConfig.collections);
      setCollectionsDuplicate(data?.upSellingConfig.collections);
    }
  }, [data?.upSellingConfig.collections]);

  useEffect(() => {
    updateCollections();
  }, [data?.upSellingConfig.collections]);

  const handleCancel = useCallback(() => {
    updateCollections();
    setLoadingActions(false);
    setShowActions(false);
  }, [data?.upSellingConfig.collections]);

  const handleSave = async () => {
    setLoadingActions(true);
    try {
      await updateUpsellingCollections({
        variables: {
          collections: collections.map((item, index) => {
            return {
              collection_id: item.collection_id,
              position: index
            };
          })
        }
      });
      await refetch();
      setLoadingActions(false);
      setShowActions(false);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
  };

  useEffect(() => {
    const hasChanges = !isEqual(collectionsDuplicate, collections);
    if (hasChanges) {
      if (!loading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [collectionsDuplicate, collections, saveButtonClicked, cancelButtonClicked]);

  if (collectionLoading)
    return (
      <div className="flex flex-wrap">
        {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
          <Skeleton className="w-[200px] h-[220px] rounded-lg" />
        ))}
      </div>
    );

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext items={collections.map((item) => item.collection_id)} strategy={rectSortingStrategy}>
          <div className="flex flex-wrap">
            {collections.length === 0 && (
              <div
                className="w-[200px] h-[220px] cursor-pointer m-2 ml-0 mb-0 rounded-lg flex justify-center items-center bg-secondary border-2 border-dashed border-black hover:bg-buttonHover disabled:bg-border disabled:text-muted-foreground"
                onClick={() => {
                  setCollectionModal(true);
                }}
              >
                <div className="flex flex-col justify-center items-center space-y-2">
                  <div className="h-10 w-10 border-black border-2 flex justify-center items-center rounded-full">
                    <UilPlus size={25} color="#000" />{' '}
                  </div>
                  <div className="text-[#000]">Add Collections</div>
                </div>
              </div>
            )}
            {collections.map((collection, index) => (
              <UpsellCollectionCard
                key={collection.collection_id}
                id={collection.collection_id}
                product={collection}
                index={index}
                onDelete={(data: any) => {
                  deleteCollectionCard(data);
                }}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <CollectionSelectionDialog
        key={'UpsellingCollectionSelection'}
        open={collectionModal}
        setOpen={(val: boolean) => {
          setCollectionModal(val);
        }}
        preSelections={collections}
        onSave={(value: any) => {
          setCollections(value);
        }}
      />
    </>
  );
};

export default Collection;
