import React, { useMemo } from 'react';
import MethodLayoutCard from './method-card';
import MethodLayout1Content from './method-layout-1';
import { PaymentMethodResponse } from '../../../types/customization';
import classNames from 'classnames';

type PaymentItemProps = {
  data: PaymentMethodResponse;
};

const MethodLayout2: React.FC<PaymentItemProps> = ({ data }) => {
  const pricing = {
    cod_price: 50
  };

  const totalPayable = useMemo(() => {
    if (data.key === 'COD') {
      return pricing.cod_price + data.total_payable;
    }
    return data.total_payable;
  }, [data, pricing]);
  return (
    <MethodLayoutCard>
      <div className="relative flex w-full items-center justify-between">
        <div className="flex w-full items-center">
          <MethodLayout1Content data={data} />
        </div>
        <div className="absolute bottom-0 right-0 top-0 flex flex-row justify-end">
          <div className="h-full w-6 bg-gradient-to-r from-transparent via-white to-white pl-6" />
          <div className="flex flex-col justify-center bg-white">
            <div
              className={classNames('text-right text-sm font-bold', {
                'text-green-600': data.discount
              })}
            >
              ₹ {totalPayable}
            </div>
            {data.discount > 0 && (
              <div
                className={classNames('text-right text-xs text-slate-500', {
                  'text-green-600': data.discount
                })}
              >
                {data.discount_data.discount_type === 'FIXED' && '₹'}
                {data.discount_data.amount}
                {data.discount_data.discount_type === 'PERCENTAGE' && '%'} off
              </div>
            )}
            {data.key === 'COD' && pricing.cod_price && (
              <div className="text-right text-xs">Extra ₹{pricing.cod_price} COD fee</div>
            )}
          </div>
        </div>
      </div>
    </MethodLayoutCard>
  );
};

export default MethodLayout2;
