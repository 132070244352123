export const ShippingTableHeaders = [
  {
    title: 'Group name'
  },
  {
    title: 'Default ETD Text'
  },

  {
    title: 'Active Status'
  },
  {
    title: ''
  }
];

export const PricingRange = [
  {
    title: 'Min Order Price'
  },
  {
    title: 'Max Order Price'
  },
  {
    title: 'Shipping Charges'
  }
];
