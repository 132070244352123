'use client';

import * as React from 'react';
import { Label } from '../Label';
import { TimePickerInput } from './time-picker-input';
import { TimePeriodSelect } from './period-select';
import { Period } from '../../../utils/time-picker-utils';
import { useRef, useState } from 'react';

interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
}

export function TimePicker12Demo({ date, setDate }: TimePickerDemoProps) {
  const [period, setPeriod] = useState<Period>('PM');

  const minuteRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);
  // const secondRef = useRef<HTMLInputElement>(null);
  const periodRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="flex items-end justify-center gap-2">
      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xs">
          Hours
        </Label>
        <TimePickerInput
          picker="12hours"
          period={period}
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xs">
          Minutes
        </Label>
        <TimePickerInput
          picker="minutes"
          id="minutes12"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => periodRef.current?.focus()}
        />
      </div>
      {/* <div className="grid gap-1 text-center">*/}
      {/*  <Label htmlFor="seconds" className="text-xs">*/}
      {/*    Seconds*/}
      {/*  </Label>*/}
      {/*  <TimePickerInput*/}
      {/*    picker="seconds"*/}
      {/*    id="seconds12"*/}
      {/*    date={date}*/}
      {/*    setDate={setDate}*/}
      {/*    ref={secondRef}*/}
      {/*    onLeftFocus={() => minuteRef.current?.focus()}*/}
      {/*    onRightFocus={() => periodRef.current?.focus()}*/}
      {/*  />*/}
      {/* </div>*/}
      <div className="grid gap-1 text-center">
        <Label htmlFor="period" className="text-xs">
          Period
        </Label>
        <TimePeriodSelect
          period={period}
          setPeriod={setPeriod}
          date={date}
          setDate={setDate}
          ref={periodRef}
          onLeftFocus={() => hourRef.current?.focus()}
        />
      </div>
    </div>
  );
}
