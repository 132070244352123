'use client';

import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../../lib/utils';

const labelVariants = cva('font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70', {
  variants: {
    variant: {
      default: 'text-[#000]',
      destructive: 'bg-blue-900 text-destructive-foreground hover:bg-destructive/90',
      outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
      secondary: 'text-label',
      ghost: 'hover:bg-accent hover:text-accent-foreground',
      link: 'text-primary underline-offset-4 hover:underline',
      icon: 'bg-secondary border border-border hover:bg-secondary/80'
    },
    size: {
      default: 'text-sm',
      sm: 'text-[12px] font-normal',
      md: 'text-base font-semibold',
      lg: 'text-lg font-semibold',
      heading: 'text-[20px] font-bold',
      paragraph: 'text-sm font-normal',
      icon: 'h-8 rounded-md px-1'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, variant, size, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ className, variant, size }))} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
