export const requiredError = (field: string) => {
  return `${field} is required`;
};

export enum ErrorMessages {
  MIN_PRODUCT_ERROR = 'Select at least one product',
  MIN_COLLECTION_ERROR = 'Select at least one collection',
  NEGATIVE_ERROR = 'Enter a positive integer value',
  DISCOUNT_PERCENTAGE_MAX_ERROR = 'Enter a value less than equal to 100%',
  WHOLE_NO_ERROR = 'Enter a whole number',
  PURCHASE_REQUIREMENT_MAX_AMOUNT_ERROR = 'Enter a value greater than min amount',
  PURCHASE_REQUIREMENT_MAX_QUANTITY_ERROR = 'Enter a value greater than min quantity',
  USER_ORDER_REQUIREMENT_MAX_ERROR = 'Enter a value greater than min order count',
  END_DATE_TIME_ERROR = 'Enter a value greater than start'
}
