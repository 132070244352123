import React, { useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Input } from '../../flexyui/Input';

interface CredentialsInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | undefined;
  error?: boolean;
  errorMessage?: string;
}

export const CredentialsInput: React.FC<CredentialsInputProps> = ({ value, error, errorMessage = '', ...props }) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const handleHide = () => setIsHidden(!isHidden);

  return (
    <Input
      type={isHidden ? 'password' : 'text'}
      value={value}
      icon={
        isHidden ? (
          <Unicons.UilEye size={18} onClick={handleHide} className="cursor-pointer mt-[5px]" />
        ) : (
          <Unicons.UilEyeSlash size={18} onClick={handleHide} className="cursor-pointer mt-[5px]" />
        )
      }
      iconPosition="end"
      className="w-full mt-1"
      error={error}
      errorMessage={errorMessage}
      {...props}
    />
  );
};
