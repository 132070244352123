import { AxiosResponse } from 'axios';
import { LoginWithEmail, LoginResponse, RefreshResponse, ChangePassword } from './types';
import { authAPI } from '..';

export const loginWithEmail = async (credentials: LoginWithEmail): Promise<AxiosResponse<LoginResponse>> => {
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/login`, credentials);
};
export const refreshDashboard = async (merchantId: string, token: string): Promise<AxiosResponse<RefreshResponse>> => {
  const body = {
    merchantId,
    token
  };
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/password/change`, body);
};

export const changePassword = async (credentials: ChangePassword): Promise<AxiosResponse<LoginResponse>> => {
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/password/change`, credentials);
};
