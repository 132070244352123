/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as Unicons from '@iconscout/react-unicons';
import { Formik, FormikErrors } from 'formik';
import { CreateDiscountPreview } from './coupons-preview';
import { addCouponsData, couponPageKeys, createDiscountHeader, ItemsFrom } from '../../../constants/coupons/coupons';
import { CreateDiscountSetup } from '../create-discount-steps/coupons-setup';
import { CreateDiscountBehaviour } from '../create-discount-steps/coupon-user-behaviour';
import { CreateDiscountApplication } from '../create-discount-steps/coupons-application';
import { CreateDiscountUserEligibility } from '../create-discount-steps/coupons-user-eligibility';
import { CreateDiscountUsageLimit } from '../create-discount-steps/coupons-usage-limit';
import {
  ApplicationTarget,
  ApplicationType,
  CouponType,
  DiscountType,
  PurchaseActivityType,
  useCreateCouponMutation,
  useUpdateCouponMutation
} from '../../../graphql';
import { DiscountFields } from './types';
import { ActiveButton } from '../../shared/active-button/active-button';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  Button
} from '../../flexyui';
import { enqueueSnackbar } from 'notistack';
import { CouponSchema } from '../../../utils/coupons/coupon-schema';
import { couponDataHandler } from '../../../utils/coupons/coupon-data-handler';
import { cloneDeep } from 'lodash';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import dayjs from 'dayjs';

type props = {
  open: boolean;
  setShowCreateDiscount: (data: any) => void;
  refetch: () => void;
  couponDetails: any;
  setCouponToEdit: (data: any) => void;
  isEdit: boolean;
  couponToAdd: string;
  setCouponToAdd: (data: any) => void;
};

export const CreateDiscount: FC<props> = ({
  open,
  setShowCreateDiscount,
  refetch,
  couponDetails,
  isEdit,
  setCouponToEdit,
  couponToAdd,
  setCouponToAdd
}) => {
  const [createCouponMutation, { loading: createCouponLoading }] = useCreateCouponMutation();
  const [updateCoupon, { loading: updateCouponLoading }] = useUpdateCouponMutation();

  const [update, setUpdate] = useState(false);
  const [couponIdToEdit, setCouponIdToEdit] = useState(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleSave = async (values: any) => {
    const newValues = cloneDeep(values);
    const body = couponDataHandler(newValues);

    try {
      if (update) {
        await updateCoupon({
          variables: {
            data: body,
            couponId: couponIdToEdit
          }
        });
      } else {
        await createCouponMutation({
          variables: {
            data: body
          }
        });
      }
      setShowCreateDiscount(false);
      setCouponToAdd('');
      enqueueSnackbar(`Hurray! Coupon ${update ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      setShowCreateDiscount(true);
      body.application.products = couponDetails?.application?.products || [];
      body.application.collections = couponDetails?.application?.collections || [];
      body.purchase_requirement.products = couponDetails?.purchase_requirement?.products || [];
      body.purchase_requirement.collections = couponDetails?.purchase_requirement?.collections || [];
      if (body.purchase_activity)
        body.purchase_activity.coupon_usage = couponDetails?.purchase_activity?.coupon_usage || null;
      setCouponToEdit(body);
      console.log(e); // TODO: handle error

      enqueueSnackbar(`Oops! Coupon ${update ? 'update' : 'creation'} failed!`, {
        variant: 'error'
      });
    } finally {
      setActiveStep(0);
    }
  };

  const checkErrorForNavigation = (errors: FormikErrors<DiscountFields>) =>
    couponPageKeys[activeStep].some((key) => Object.keys(errors).includes(key));

  const initialValues = useMemo(() => {
    let couponType = CouponType.CartAmount;
    if (couponToAdd?.length > 0) {
      switch (couponToAdd) {
        case 'ADD_BXGY':
          couponType = CouponType.Bxgy;
          break;
        case 'ADD_PURCHASE_DRIVEN':
          couponType = CouponType.PurchaseHistory;
          break;
        default:
          break;
      }
    }
    if (couponDetails)
      couponType =
        couponDetails?.coupon_type === CouponType.Amount
          ? CouponType.CartAmount
          : couponDetails?.coupon_type || CouponType.CartAmount;

    return {
      code: couponDetails?.code || '',
      title: couponDetails?.title || '',
      has_title: !!couponDetails?.title,
      application_type: couponDetails?.application_type || ApplicationType.Code,
      coupon_type: couponType,
      application: {
        type: couponDetails?.application?.type || ApplicationTarget.Universal,
        products: couponDetails?.application?.products || [],
        collections: couponDetails?.application?.collections || [],
        max_per_order: couponDetails?.application?.max_per_order || null,
        show_max_per_order: !!(couponDetails?.application && couponDetails?.application?.max_per_order),
        min_quantity: couponDetails?.application?.min_quantity || null,
        items_from:
          couponDetails?.application?.products && couponDetails?.application?.products?.length > 0
            ? ItemsFrom.SPECIFIC_PRODUCTS
            : couponDetails?.application?.collections && couponDetails.application?.collections.length > 0
              ? ItemsFrom.SPECIFIC_COLLECTION
              : ItemsFrom.SPECIFIC_PRODUCTS,
        discount_type:
          couponDetails?.coupon_type === CouponType.Bxgy && couponDetails?.discount_amount === 100
            ? 'FREE'
            : couponDetails?.discount_type || DiscountType.Percentage,
        discount_amount: (couponDetails?.coupon_type === CouponType.Bxgy && couponDetails?.discount_amount) || null
      },
      discount_type:
        (couponDetails?.coupon_type !== CouponType.Bxgy && couponDetails?.discount_type) || DiscountType.Percentage,
      discount_amount: (couponDetails?.coupon_type !== CouponType.Bxgy && couponDetails?.discount_amount) || null,
      discount_max_cap: couponDetails?.discount_max_cap || null,
      show_discount_max_cap: !!couponDetails?.discount_max_cap,
      purchase_requirement: {
        show_max_amount: !!couponDetails?.purchase_requirement?.max_amount,
        show_max_quantity: !!couponDetails?.purchase_requirement?.max_quantity,
        type: couponDetails?.purchase_requirement?.type || 'NONE',
        min_amount: couponDetails?.purchase_requirement?.min_amount || null,
        max_amount: couponDetails?.purchase_requirement?.max_amount || null,
        min_quantity: couponDetails?.purchase_requirement?.min_quantity || null,
        max_quantity: couponDetails?.purchase_requirement?.max_quantity || null,
        productType:
          couponDetails?.purchase_requirement?.products?.length > 0
            ? 'PURCHASE_PRODUCTS'
            : couponDetails?.purchase_requirement?.collections?.length > 0
              ? 'PURCHASE_COLLECTIONS'
              : 'PURCHASE_CART',
        products: couponDetails?.purchase_requirement?.products || [],
        collections: couponDetails?.purchase_requirement?.collections || [],
        items_from:
          couponDetails?.purchase_requirement?.products && couponDetails?.purchase_requirement?.products?.length > 0
            ? ItemsFrom.SPECIFIC_PRODUCTS
            : couponDetails?.purchase_requirement?.collections &&
                couponDetails?.purchase_requirement?.collections.length > 0
              ? ItemsFrom.SPECIFIC_COLLECTION
              : ItemsFrom.SPECIFIC_PRODUCTS
      },
      purchase_activity: {
        type: couponDetails?.purchase_activity?.type || PurchaseActivityType.CouponCode,
        min_amount: couponDetails?.purchase_activity?.min_amount || null,
        min_quantity: couponDetails?.purchase_activity?.min_quantity || null,
        coupon_usage: couponDetails?.purchase_activity?.coupon_usage || null,
        selected_coupon_id: couponDetails?.purchase_activity?.coupon_usage?.coupon?.id || ''
      },
      purchase_activity_period: {
        start: couponDetails?.purchase_activity_period?.start || dayjs().format('YYYY-MM-DDTHH:mm'),
        end: couponDetails?.purchase_activity_period?.end || dayjs().format('YYYY-MM-DDTHH:mm'),
        has_endTime: !!couponDetails?.purchase_activity_period?.end
      },
      user_order_requirement: {
        max_order_count: couponDetails?.user_order_requirement?.max_order_count || null,
        max_order_count_needed: !!couponDetails?.user_order_requirement?.max_order_count,
        min_order_count: couponDetails?.user_order_requirement?.min_order_count || null,
        type:
          couponDetails?.user_order_requirement?.max_order_count === 0 &&
          couponDetails?.user_order_requirement?.min_order_count === null
            ? 'first'
            : couponDetails?.user_order_requirement?.min_order_count >= 0 &&
                couponDetails?.user_order_requirement?.min_order_count !== null
              ? 'order'
              : 'all'
      },
      show_per_customer_usage_limit: !!couponDetails?.per_customer_usage_limit,
      show_total_usage_limit: !!couponDetails?.total_usage_limit,
      discoverable: couponDetails?.discoverable || false,
      combine: couponDetails?.combine || false,
      activation_period: {
        start: couponDetails?.activation_period?.start || dayjs().format('YYYY-MM-DDTHH:mm'),
        end: couponDetails?.activation_period?.end || dayjs().format('YYYY-MM-DDTHH:mm'),
        has_endTime: !!couponDetails?.activation_period?.end
      },
      per_customer_usage_limit: couponDetails?.per_customer_usage_limit || null,
      total_usage_limit: couponDetails?.total_usage_limit || null
    };
  }, [couponDetails, couponToAdd]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeButton = scrollContainerRef.current.querySelector(`[data-coupon-step-number="${activeStep}"]`);
      if (activeButton) {
        activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [activeStep]);

  return (
    <Formik<DiscountFields>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={CouponSchema}
      onSubmit={handleSave}
    >
      {({ values, errors, submitForm, resetForm, validateForm, isSubmitting }) => {
        // console.log('errors', errors);

        return (
          <AlertDialog open={open} onOpenChange={(value: boolean) => setShowCreateDiscount(value)}>
            <AlertDialogContent className="!gap-0" size="fullWidth">
              <AlertDialogHeader className="w-full h-full">
                <AlertDialogTitle className="p-4 sm:pl-6 sm:pr-5 bg-[#F9F9F9]">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-4">
                      <Unicons.UilPercentage />
                      <h1 className="text-xl font-medium hidden sm:block">
                        {couponDetails
                          ? 'Edit Discount'
                          : `Create ${addCouponsData.find((coupon) => coupon.id === couponToAdd)?.title} Discount`}
                      </h1>
                      <h1 className="text-xl font-medium block sm:hidden">
                        {couponDetails ? 'Edit Discount' : 'Create Discount'}
                      </h1>
                    </div>
                    <Button
                      size="icon"
                      variant="icon"
                      onClick={() => {
                        setActiveStep(0);
                        setShowCreateDiscount(false);
                        setCouponToEdit(null);
                        setCouponToAdd('');
                      }}
                      className="hover:bg-[#f2f8ff]"
                    >
                      <Unicons.UilTimes className="text-[#2F72FF]" />
                    </Button>
                  </div>
                </AlertDialogTitle>
                <AlertDialogDescription className="!p-0 !m-0 w-full !h-[calc(100dvh-65px)] overflow-hidden text-[#2A324C]">
                  <div className="flex h-full">
                    <div className="flex-1 relative">
                      <div className="border-r-[1px] h-full">
                        <div className="w-[100vw] sm:w-[calc(100vw-22rem)] sticky px-4 sm:px-8">
                          <div
                            className="flex items-center gap-2 overflow-x-auto scrollbar-hide py-3 sm:py-4"
                            ref={scrollContainerRef}
                          >
                            {createDiscountHeader(
                              couponToAdd === 'ADD_PURCHASE_DRIVEN' ||
                                values?.coupon_type === CouponType.PurchaseHistory
                            ).map((header) => {
                              // Check if header.step is 'User Behaviour' and condition is false, skip rendering
                              if (header.step === 'User Behaviour' && !header.condition) {
                                return null;
                              }

                              return (
                                <ActiveButton
                                  key={header.step}
                                  step={header.step}
                                  logo={header.logo}
                                  activeStep={activeStep}
                                  stepNumber={header.stepNumber}
                                  setActiveStep={setActiveStep}
                                  disabled={Object.keys(errors).length > 0}
                                  couponType="AMOUNT"
                                />
                              );
                            })}
                          </div>
                        </div>
                        <hr />

                        <div className="h-[calc(100dvh-180px)] sm:h-[calc(100dvh-190px)] mb-2 overflow-auto">
                          {activeStep === 0 && <CreateDiscountSetup />}
                          {activeStep === 1 && <CreateDiscountBehaviour />}
                          {activeStep === 2 && <CreateDiscountApplication />}
                          {activeStep === 3 && <CreateDiscountUserEligibility />}
                          {activeStep === 4 && <CreateDiscountUsageLimit />}
                        </div>
                      </div>
                      <div className="px-4 max-h-[55px] flex justify-end gap-2 absolute bg-[#F9F9F9] bottom-0 border-t-[1px] border-t-[#E2E2E2] p-3 w-full transition duration-150 ease-out hover:ease-in z-10">
                        {activeStep !== 0 && (
                          <Button
                            variant="backward"
                            className={'h-8'}
                            onClick={() => {
                              if (
                                couponToAdd === 'ADD_PURCHASE_DRIVEN' ||
                                values?.coupon_type === CouponType.PurchaseHistory
                              )
                                setActiveStep((prev: number) => prev - 1);
                              else {
                                if (activeStep === 2) setActiveStep((prev: number) => prev - 2);
                                else setActiveStep((prev: number) => prev - 1);
                              }
                            }}
                          >
                            Back
                          </Button>
                        )}

                        <Button
                          variant="forward"
                          className={'h-8'}
                          onClick={async () => {
                            if (activeStep === 4 && isEdit) {
                              setUpdate(true);
                              setCouponIdToEdit(couponDetails.id);
                            } else {
                              setUpdate(false);
                            }

                            if (activeStep !== 4) {
                              if (
                                couponToAdd === 'ADD_PURCHASE_DRIVEN' ||
                                values?.coupon_type === CouponType.PurchaseHistory
                              )
                                setActiveStep((prev: number) => prev + 1);
                              else {
                                if (activeStep === 0) setActiveStep((prev: number) => prev + 2);
                                else setActiveStep((prev: number) => prev + 1);
                              }
                            } else {
                              await submitForm();
                              resetForm();
                            }
                          }}
                          disabled={checkErrorForNavigation(errors) || isSubmitting}
                        >
                          {createCouponLoading || updateCouponLoading ? (
                            <LoadingIcon height={20} className={'animate-spin text-white'} />
                          ) : activeStep === 4 ? (
                            isEdit ? (
                              'Save'
                            ) : (
                              'Submit'
                            )
                          ) : (
                            'Next'
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="p-4 hidden sm:block">
                      <CreateDiscountPreview activeStep={activeStep} selectedCoupon={null} isEdit={open} />
                    </div>
                  </div>
                </AlertDialogDescription>
              </AlertDialogHeader>
            </AlertDialogContent>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};
