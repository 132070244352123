import React from 'react';
import classNames from 'classnames';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { flexRender, Row } from '@tanstack/react-table';
import { TableCell, TableHead, TableRow } from '../flexyui';
import { UilDraggabledots } from '@iconscout/react-unicons';
import { ColumnDef } from '@tanstack/table-core';

type props = {
  row: Row<any>;
  subRowColumns: ColumnDef<any, any>[];
  dragState: boolean;
};

export const UpsellingProductTableRow: React.FC<props> = ({ row, subRowColumns, dragState }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: row.original.product_id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1
  };

  return (
    <>
      <TableRow
        className="cursor-pointer last-of-type:border-b-0"
        style={style}
        ref={setNodeRef}
        id={row.original.product_id}
        key={row.original.product_id}
        onClick={() => {
          if (row.getCanExpand()) row.toggleExpanded(!row.getIsExpanded());
        }}
      >
        {row.getVisibleCells().map((cell: any, index: number) => (
          <TableCell key={cell.id} className={classNames({ 'flex items-center ': index === 0 })}>
            {index === 0 && (
              <div
                className="text-[#888d9b] pointer-events-auto cursor-grab flex justify-center rounded-full m-2 ml-6 p-1 items-center hover:text-black bg-gray-100 h-6 w-6"
                {...listeners}
                {...attributes}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <UilDraggabledots size={15} className="inline-block" />
              </div>
            )}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>

      {row.getIsExpanded() && (
        <>
          <TableRow id={row.original.product_id + '-head'} className={dragState ? 'hidden' : ''}>
            {subRowColumns.map((subColumn: any) => (
              <TableHead
                key={subColumn.accessorKey}
                className={`bg-[#F9F9F9] text-[#595F74] !h-8 ${subColumn.accessorKey === 'variant_price' && 'ml-40'}`}
              >
                {flexRender(subColumn.header, { column: subColumn })}
              </TableHead>
            ))}
          </TableRow>
          {row.original.variants.map((variant: any) => (
            <TableRow
              key={variant.variant_id}
              id={variant.variant_id}
              className={classNames('last-of-type:border-b-0', {
                hidden: dragState
              })}
            >
              {subRowColumns.map((subColumn: any) => (
                <TableCell key={subColumn.accessorKey}>
                  {flexRender(subColumn.cell, { row: { original: variant } })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};
