import React from 'react';
import { GoogleAds } from './google-ads';
import { GoogleAnalytics } from './google-analytics';
import { IntegrationsOptions } from './integrations-options';
import { FacebookIntegrations } from './facebook-pixel-integration';
import { Route, Routes } from 'react-router-dom';

export const IntegrationsMain: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<IntegrationsOptions />} />
      <Route path="meta" element={<FacebookIntegrations />} />
      <Route path="google-ads" element={<GoogleAds />} />
      <Route path="google-analytics" element={<GoogleAnalytics />} />
    </Routes>
  );
};
