import React from 'react';
import { ComparableCouponPerformanceMetrics, CouponPerformanceMetrics } from '../../../graphql';
import { SkeletonDashboard } from '../../shared/skeleton-dashboard/skeleton-dashboard';
import { convertAmount } from '../../../utils/convert-amount';
import { CouponMetricCard } from './coupon-metric-card';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';
import { Card } from '../../flexyui';

type Props = {
  couponPerformanceData?: ComparableCouponPerformanceMetrics;
  loading: boolean;
  dateRange?: any;
};
export const CouponUsage: React.FC<Props> = ({ couponPerformanceData, loading }) => {
  const percentageChange =
    Number(couponPerformanceData?.current?.with_coupon?.percentage) -
    Number(couponPerformanceData?.current?.without_coupon?.percentage);

  return (
    <Card className="min-h-[10rem]">
      <div className="flex items-end justify-between pt-4 px-4">
        <div className="font-medium text-black mb-0.5 flex items-center">
          <div>Discount performance</div>
          <ButtonWithInfo
            heading="Discount Performance"
            description={
              <div>
                <div className="mb-1">The effectiveness of discounts in driving sales.</div>
                <div className="mb-1">
                  <span className="font-medium">With coupons</span> -{'>'} Total number of orders placed using coupon
                  codes.
                </div>
                <div>
                  <span className="font-medium">Without coupons</span> -{'>'} Total number of orders placed without
                  using coupon codes.
                </div>
              </div>
            }
            calculation={true}
            calculationVariable="Conversions"
            calculationValue="total orders / checkout sessions"
            align="center"
          >
            <Unicons.UilInfoCircle size={15} className="ml-1.5" />
          </ButtonWithInfo>
        </div>
        {(percentageChange || percentageChange === 0) && !loading && (
          <div
            className={`flex items-center font-medium pt-1 ${
              percentageChange >= 0 ? 'text-[#00BA08]' : 'text-[#FF0000]'
            } `}
          >
            {convertAmount(percentageChange)}%
            {percentageChange >= 0 ? <Unicons.UilArrowUp /> : <Unicons.UilArrowDown />}
          </div>
        )}
      </div>
      {loading ? (
        <div className="space-y-1">
          <SkeletonDashboard heightGraph="h-[40px]" />
          <SkeletonDashboard heightGraph="h-[40px]" />
        </div>
      ) : (
        <div className="px-4 pt-6 my-2">
          <CouponMetricCard
            couponPerformanceWithCoupon={
              couponPerformanceData?.current?.with_coupon as CouponPerformanceMetrics['with_coupon']
            }
            withCoupon={true}
          />
          <CouponMetricCard
            couponPerformanceWithOutCoupon={
              couponPerformanceData?.current?.without_coupon as CouponPerformanceMetrics['without_coupon']
            }
            withCoupon={false}
          />
        </div>
      )}
    </Card>
  );
};
