import React from 'react';
import { Chip, Skeleton } from '../../../flexyui';
import { UilArrowRight } from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';

type Props = {
  enabled: boolean;
  loading: boolean;
  integration: string;
  icon: React.ReactNode;
  serviceName: string;
  route: string;
};

const IntegrationItem = ({ enabled, icon, integration, serviceName, loading, route }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      key={integration}
      onClick={() => navigate(route)}
      className="flex items-center p-4 gap-3 cursor-pointer hover:bg-[#f5f5f5]"
    >
      <div>{icon}</div>
      <div className="w-full flex items-center">
        <span className="font-medium">{serviceName}</span>
      </div>
      <div className="flex items-center w-fit">
        {loading ? (
          <Skeleton variant="circular" className="!m-0 w-[70px]" />
        ) : (
          <Chip text={enabled ? 'Enabled' : 'Disabled'} variant={enabled ? 'success' : 'draft'} />
        )}

        <UilArrowRight className="text-[rgba(18, 27, 56, 1)] ml-3" />
      </div>
    </div>
  );
};

export default IntegrationItem;
