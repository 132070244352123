/* eslint-disable import/default */
import React from 'react';
import Charts from 'react-apexcharts';
import { PaymentMetricsQuery } from '../../../graphql';
import { calculatePaymentMethods } from '../../../utils/convert-amount';
import { Select as SelectCN, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../flexyui/Select/index';
import { Skeleton, Card } from '../../flexyui';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  paymentGraphData?: PaymentMetricsQuery;
  loading: boolean;
};

export const PaymentMethod: React.FC<Props> = ({ paymentGraphData, loading }) => {
  const { percentageSplit, sortedLabels, showPaymentMethodUsage } = calculatePaymentMethods(
    paymentGraphData?.paymentMetrics?.current,
    true
  );

  const options: any = {
    colors: showPaymentMethodUsage
      ? ['#00A67E', '#2F72FF', '#A68B00', '#BD69FF', '#FF697B', '#A32C06', '#121B38', '#FFB600']
      : ['#e6e6e6'],

    legend: {
      position: 'right',
      verticalAlign: 'bottom'
    },
    labels: showPaymentMethodUsage ? sortedLabels : ['No data available'],
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '30%'
        }
      }
    },
    dataLabels: {
      enabled: showPaymentMethodUsage
    },
    plugins: {
      legend: {
        show: showPaymentMethodUsage,
        position: showPaymentMethodUsage && 'bottom',
        horizontalAlign: 'left'
      }
    },
    chart: {
      type: 'donut',
      height: 100
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'right'
          }
        }
      }
    ]
  };

  return (
    <Card>
      <div className="font-medium text-black pt-4 px-4 mb-4 flex items-center">
        <div>Payment Method Usage</div>
        <ButtonWithInfo
          heading="Payment Method Usage"
          description="Distribution of different payment methods used by customers including Cash on Delivery (COD)."
          calculation={false}
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      <SelectCN
        value={'All'}
        defaultValue={'All'}
        // onValueChange={(value) => {
        //   handlePaymentChange(value, 'filter');
        // }}
      >
        <SelectTrigger className="h-8 w-[8rem] ml-4 my-2">
          <SelectValue placeholder={'Hello'} />
        </SelectTrigger>
        <SelectContent side="bottom">
          <SelectItem value={'All'}>All</SelectItem>
        </SelectContent>
      </SelectCN>
      {loading ? (
        <div className="p-4">
          <Skeleton className="w-full !m-0 h-[100px]" />
        </div>
      ) : (
        <>
          {showPaymentMethodUsage ? (
            <Charts options={options} series={percentageSplit} type="donut" />
          ) : (
            <Charts options={options} series={[100]} type="donut" colors={'#A0A4AF'} />
          )}
        </>
      )}
    </Card>
  );
};
