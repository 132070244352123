import React from 'react';

import * as Unicons from '@iconscout/react-unicons';
import { convertAmount } from '../../../utils/convert-amount';

type Props = {
  loading: boolean;
  value?: string;
  title: string;
  prevValue?: string;
  percentageChange?: number;
};

export const DashboardGraphTitle: React.FC<Props> = ({ loading, value, title, prevValue, percentageChange }) => {
  return (
    <>
      {value && (
        <div className="flex justify-between px-4 pt-2 my-2">
          <div className="flex flex-wrap font-bold text-2xl items-baseline gap-2 ">
            <span className="text-[#121B38]">
              {title !== 'Total Orders' && title !== 'Average Session Duration' && '₹ '}
              {value}
            </span>
            {(percentageChange || percentageChange === 0) && !loading && (
              <div
                className={`flex items-center font-medium text-xs ${
                  percentageChange >= 0 ? 'text-[#00BA08]' : 'text-[#FF0000]'
                } `}
              >
                {convertAmount(percentageChange)}%
                {percentageChange >= 0 ? <Unicons.UilArrowUp /> : <Unicons.UilArrowDown />}
              </div>
            )}
          </div>

          {prevValue && (
            <div className="text-[#C5C5C5] font-semibold text-lg">
              {(title !== 'Total Orders' || 'Average Session Duration') && '₹ '}
              {prevValue}
            </div>
          )}
        </div>
      )}
    </>
  );
};
