import * as Unicons from '@iconscout/react-unicons';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SideBar = [
  {
    name: 'Dashboard',
    exact: true,
    to: '/',
    icon: <Unicons.UilGraphBar size={18} />
  },
  {
    name: 'Orders',
    // exact: true,
    to: 'orders',
    icon: <Unicons.UilBox size={18} />
  },
  // {
  //   name: 'Abandoned Cart',
  //   exact: true,
  //   to: 'cart',
  //   icon: <Unicons.UilShoppingCart size={18} />
  // },
  {
    name: 'Shipping',
    exact: true,
    to: 'shipping',
    icon: <Unicons.UilTruck size={18} />
  },
  {
    name: 'Coupons',
    exact: true,
    to: 'coupons',
    icon: <Unicons.UilPercentage size={18} />
  },
  {
    name: 'Payment Offers',
    exact: true,
    to: 'payment-offers',
    icon: <Unicons.UilPricetagAlt size={18} />
  },
  {
    name: 'Upsell',
    exact: true,
    to: 'upsell',
    icon: <Unicons.UilMapPin size={18} />
  },
  {
    name: 'Customization',
    exact: true,
    to: 'customization#General',
    icon: <Unicons.UilBrushAlt size={18} />
  },
  {
    name: 'RTO Suite',
    exact: true,
    to: 'rto-suite',
    icon: <Unicons.UilLuggageCart size={18} />
  },
  {
    name: 'Settings',
    exact: true,
    to: 'settings',
    icon: <Unicons.UilSetting size={18} />
  }
];
