/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DashboardState = {
  store: string;
  sideBarState: boolean;
  storeLogo: string | ArrayBuffer | null | undefined;
  storeName: string | null;
  storeUrl: string;
  showSaveCancel: boolean;
  saveButtonClicked: boolean;
  cancelButtonClicked: boolean;
  saveCancelLoading: boolean;
  confirmationDialog: boolean;
  routeLink: string;
  shippingDiscardDialog: boolean;
  shippingBackDropClicked: boolean;
};

const initialState: DashboardState = {
  store: '',
  sideBarState: false,
  storeLogo: 'null',
  storeName: null,
  storeUrl: '',
  showSaveCancel: false,
  saveButtonClicked: false,
  cancelButtonClicked: false,
  saveCancelLoading: false,
  confirmationDialog: false,
  routeLink: '',
  shippingDiscardDialog: false,
  shippingBackDropClicked: false
};

export const mainSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action: PayloadAction<string>) => {
      state.store = action.payload;
    },
    setSidebarState: (state, action: PayloadAction<boolean>) => {
      state.sideBarState = action.payload;
    },
    setStoreLogo: (state, action: PayloadAction<string>) => {
      state.storeLogo = action.payload;
    },
    setStoreName: (state, action: PayloadAction<string | null>) => {
      state.storeName = action.payload;
    },
    setStoreUrl: (state, action: PayloadAction<string>) => {
      state.storeUrl = action.payload;
    },
    setShowSaveCancel: (state, action: PayloadAction<boolean>) => {
      state.showSaveCancel = action.payload;
    },
    setSaveButtonClicked: (state, action: PayloadAction<boolean>) => {
      state.saveButtonClicked = action.payload;
    },
    setCancelButtonClicked: (state, action: PayloadAction<boolean>) => {
      state.cancelButtonClicked = action.payload;
    },
    setSaveCancelLoading: (state, action: PayloadAction<boolean>) => {
      state.saveCancelLoading = action.payload;
    },
    setConfirmationDialog: (state, action: PayloadAction<boolean>) => {
      state.confirmationDialog = action.payload;
    },
    setRouteLink: (state, action: PayloadAction<string>) => {
      state.routeLink = action.payload;
    },
    setShippingDiscardDialog: (state, action: PayloadAction<boolean>) => {
      state.shippingDiscardDialog = action.payload;
    },
    setShippingBackDropClicked: (state, action: PayloadAction<boolean>) => {
      state.shippingBackDropClicked = action.payload;
    }
  }
});

export const {
  setStore,
  setStoreLogo,
  setStoreName,
  setStoreUrl,
  setRouteLink,
  setSidebarState,
  setShowSaveCancel,
  setSaveButtonClicked,
  setSaveCancelLoading,
  setConfirmationDialog,
  setCancelButtonClicked,
  setShippingDiscardDialog,
  setShippingBackDropClicked
} = mainSlice.actions;

export default mainSlice.reducer;
