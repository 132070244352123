/* eslint-disable indent */
import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import * as Unicons from '@iconscout/react-unicons';
import { CreateOfferInfoCard } from './create-offer-info-card';
import { InfoCardApplication } from '../../../constants/coupons/coupons';
import { OfferFields } from '../create-offer/types';
import { Input, RadioGroup, Button } from '../../flexyui';
import { CheckboxWithLabel } from '../../shared/checkbox-with-label/checkbox-with-label';
import { RadioWithLabel } from '../../shared/radio-with-label/radio-with-label';
import { ProductSelectionDialog } from '../../shared/product-selection/product-selection';
import SelectedProducts from '../../coupons/create-discount-steps/selected-products';

export const CreateOfferApplication: FC = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<OfferFields>();

  const [openPurchaseProductSelection, setOpenPurchaseProductSelection] = useState<boolean>(false);
  const [purchaseProductSelectionSearch, setPurchaseProductSelectionSearch] = useState('');

  useEffect(() => {
    if (values?.purchase_requirement?.productType === 'PRODUCTS' && purchaseProductSelectionSearch.length > 0) {
      setOpenPurchaseProductSelection(true);
    } else {
      setOpenPurchaseProductSelection(false);
    }
  }, [purchaseProductSelectionSearch]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <CreateOfferInfoCard data={InfoCardApplication[1]} />

        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">Are there any purchase requirements?</div>
          <RadioGroup {...getFieldProps('purchase_requirement.type')} defaultValue="NONE">
            <RadioWithLabel
              label="No purchase requirements"
              value="NONE"
              onClick={() => setFieldValue('purchase_requirement.type', 'NONE')}
            />
            <RadioWithLabel
              label="Minimum/Maximum purchase amount"
              value="PURCHASE_AMOUNT"
              onClick={() => setFieldValue('purchase_requirement.type', 'PURCHASE_AMOUNT')}
            />
            <RadioWithLabel
              label="Minimum/Maximum quantity of items"
              value="PURCHASE_QUANTITY"
              onClick={() => setFieldValue('purchase_requirement.type', 'PURCHASE_QUANTITY')}
            />
          </RadioGroup>
          {/* if purchase requirement is purchase amount */}
          {values?.purchase_requirement?.type === 'PURCHASE_AMOUNT' && (
            <>
              <div className="flex gap-4 mt-8">
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Minimum amount</div>
                  <Input
                    {...getFieldProps('purchase_requirement.min_amount')}
                    type="number"
                    iconPosition="start"
                    icon={<Unicons.UilRupeeSign size={15} />}
                    error={touched.purchase_requirement?.min_amount && !!errors.purchase_requirement?.min_amount}
                    errorMessage={errors.purchase_requirement?.min_amount}
                  />
                </div>
                {values?.purchase_requirement?.show_max_amount && (
                  <div className={'w-[150px]'}>
                    <div className="font-medium mb-1">Maximum amount</div>
                    <Input
                      {...getFieldProps('purchase_requirement.max_amount')}
                      type="number"
                      iconPosition="start"
                      icon={<Unicons.UilRupeeSign size={15} />}
                      error={touched.purchase_requirement?.max_amount && !!errors.purchase_requirement?.max_amount}
                      errorMessage={errors.purchase_requirement?.max_amount}
                    />
                  </div>
                )}
              </div>
              <CheckboxWithLabel
                label="Maximum purchase amount cap"
                value="purchase_requirement.show_max_amount"
                checked={values.purchase_requirement.show_max_amount}
                onClick={() =>
                  setFieldValue('purchase_requirement.show_max_amount', !values.purchase_requirement.show_max_amount)
                }
              />
            </>
          )}

          {values?.purchase_requirement?.type === 'PURCHASE_QUANTITY' && (
            <>
              <div className="flex gap-4 mt-8">
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Minimum Quantity</div>
                  <Input
                    {...getFieldProps('purchase_requirement.min_quantity')}
                    type="number"
                    error={touched.purchase_requirement?.min_quantity && !!errors.purchase_requirement?.min_quantity}
                    errorMessage={errors.purchase_requirement?.min_quantity}
                  />
                </div>
                {values?.purchase_requirement?.show_max_quantity && (
                  <div className={'w-[150px]'}>
                    <div className="font-medium mb-1">Maximum Quantity</div>
                    <Input
                      {...getFieldProps('purchase_requirement.max_quantity')}
                      type="number"
                      error={touched.purchase_requirement?.max_quantity && !!errors.purchase_requirement?.max_quantity}
                      errorMessage={errors.purchase_requirement?.max_quantity}
                    />
                  </div>
                )}
              </div>
              <CheckboxWithLabel
                label="Maximum quantity cap"
                value="purchase_requirement.show_max_quantity"
                checked={values.purchase_requirement.show_max_quantity}
                onClick={() =>
                  setFieldValue(
                    'purchase_requirement.show_max_quantity',
                    !values.purchase_requirement.show_max_quantity
                  )
                }
              />
            </>
          )}
          <div className={'mt-4'}>
            {(values?.purchase_requirement?.type === 'PURCHASE_QUANTITY' ||
              values?.purchase_requirement?.type === 'PURCHASE_AMOUNT') && (
              <>
                <RadioGroup {...getFieldProps('purchase_requirement.productType')} defaultValue="CART">
                  <RadioWithLabel
                    label="For entire cart"
                    value="CART"
                    onClick={() => setFieldValue('purchase_requirement.productType', 'CART')}
                  />
                  <RadioWithLabel
                    label="For specific products"
                    value="PRODUCTS"
                    onClick={() => setFieldValue('purchase_requirement.productType', 'PRODUCTS')}
                  />
                </RadioGroup>
                {values?.purchase_requirement?.productType === 'PRODUCTS' && (
                  <div>
                    <div className="flex mt-4 gap-2">
                      <div className="flex-1">
                        <Input
                          type="text"
                          placeholder="Search"
                          iconPosition="start"
                          icon={<Unicons.UilSearch size={18} />}
                          value={purchaseProductSelectionSearch}
                          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            setPurchaseProductSelectionSearch(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          variant="outline"
                          className="h-10 hover:border-[#2F72FF] hover:bg-[#f9fcff] border-[1px] rounded-lg font-medium w-fit"
                          onClick={() => {
                            setOpenPurchaseProductSelection(true);
                          }}
                        >
                          Browse
                        </Button>
                        <ProductSelectionDialog
                          key={'PurchaseProductSelection'}
                          open={openPurchaseProductSelection}
                          setOpen={(val: boolean) => {
                            setOpenPurchaseProductSelection(val);
                            setPurchaseProductSelectionSearch('');
                          }}
                          search={purchaseProductSelectionSearch}
                          preSelections={values.purchase_requirement.products}
                          onSave={(value: any) => {
                            setFieldValue('purchase_requirement.products', value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <SelectedProducts
                        on="purchase_requirement.products"
                        setFieldValue={setFieldValue}
                        value={values.purchase_requirement.products}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
