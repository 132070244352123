import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { parseRules } from './validate-shipping';

type Props = {
  rows: number[];
};
export const ValidateRules: React.FC<Props> = ({ rows }) => {
  const { values, setFieldValue } = useFormikContext();
  const finalRules = useMemo(() => parseRules(values, rows), [rows, values]);

  useEffect(() => {
    for (let index = 0; index < finalRules.length; index += 1) {
      const currRule = finalRules[index];
      const nextRule = finalRules[index + 1];

      if (
        nextRule &&
        currRule?.maximum_order_price &&
        nextRule?.minimum_order_price !== currRule.maximum_order_price + 1
      ) {
        const nextMinName = `minimum_order_price_${nextRule._index}`;
        setFieldValue(nextMinName, currRule.maximum_order_price + 1);
      }
    }
  }, [finalRules, setFieldValue]);

  return <></>;
};
