/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import { ApplicationTarget, ApplicationType, CouponType, DiscountType, PurchaseActivityType } from '../../graphql';
import { ItemsFrom } from '../../constants/coupons/coupons';
import { isCouponTypeAmount } from './coupon-type-check';

export const getCollectionSelection = (collections: any) => {
  const collectionList: any = [];
  if (collections?.length > 0) {
    if (typeof collections[0] !== 'string' && typeof collections[0] !== 'number') {
      for (let i = 0; i < collections.length; i += 1) {
        collectionList.push({ collection_id: collections[i].collection_id });
      }
    }
  }
  return collectionList;
};

export const couponDataHandler = (values: any) => {
  const body = { ...values };

  if (body?.has_title === false) {
    body.title = null;
  }

  if (body?.application_type === ApplicationType.Automatic) {
    body.discoverable = false;
  }

  if (body?.show_discount_max_cap === false) {
    body.discount_max_cap = null;
  }
  if (body?.purchase_requirement?.show_max_amount === false) {
    body.purchase_requirement.max_amount = null;
  }
  if (
    (isCouponTypeAmount(body?.coupon_type) || body?.coupon_type === CouponType.PurchaseHistory) &&
    body?.application?.type === ApplicationTarget.Universal
  ) {
    delete body.application.products;
    delete body.application.collections;
  }
  if (
    (isCouponTypeAmount(body?.coupon_type) || body?.coupon_type === CouponType.PurchaseHistory) &&
    body?.purchase_requirement?.productType === 'PURCHASE_CART'
  ) {
    delete body.purchase_requirement.products;
    delete body.purchase_requirement.collections;
  }

  if (
    body.purchase_requirement.products &&
    body.purchase_requirement.products.length &&
    body.purchase_requirement.products[0]?.variants?.length
  ) {
    const products = [...body.purchase_requirement.products];
    let variants: any = [];
    if (typeof products[0] !== 'string' && typeof products[0] !== 'number') {
      for (let i = 0; i < products.length; i += 1) {
        if (products[i]?.variants?.length) {
          variants = [
            ...variants,
            ...products[i].variants.map((item: any) => {
              return { product_id: products[i].product_id, variant_id: item.variant_id };
            })
          ];
        }
      }
    }
    body.purchase_requirement.products = variants;
  }

  if (body.application.products && body.application.products.length && body.application.products[0]?.variants?.length) {
    const products = [...body.application.products];
    let variants: any = [];
    if (typeof products[0] !== 'string' && typeof products[0] !== 'number') {
      for (let i = 0; i < products.length; i += 1) {
        if (products[i]?.variants?.length) {
          variants = [
            ...variants,
            ...products[i].variants.map((item: any) => {
              return { product_id: products[i].product_id, variant_id: item.variant_id };
            })
          ];
        }
      }
    }
    body.application.products = variants;
  }
  if (
    body.purchase_requirement.collections &&
    body.purchase_requirement.collections.length &&
    body.purchase_requirement.collections[0]?.title
  ) {
    body.purchase_requirement.collections = getCollectionSelection(body.purchase_requirement.collections);
  }
  if (body.application.collections && body.application.collections.length && body.application.collections[0]?.title) {
    body.application.collections = getCollectionSelection(body.application.collections);
  }

  if (body?.coupon_type === CouponType.Bxgy && body?.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
    body.purchase_requirement.collections = [];
  } else if (
    body?.coupon_type === CouponType.Bxgy &&
    body?.purchase_requirement.items_from === ItemsFrom.SPECIFIC_COLLECTION
  ) {
    body.purchase_requirement.products = [];
  }

  if (body?.coupon_type === CouponType.Bxgy && body?.application.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
    body.application.collections = [];
  } else if (body?.coupon_type === CouponType.Bxgy && body?.application.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
    body.application.products = [];
  }

  if (
    (isCouponTypeAmount(body?.coupon_type) || body?.coupon_type === CouponType.PurchaseHistory) &&
    body?.purchase_requirement.productType === 'PURCHASE_PRODUCTS'
  ) {
    body.purchase_requirement.collections = [];
  } else if (
    (isCouponTypeAmount(body?.coupon_type) || body?.coupon_type === CouponType.PurchaseHistory) &&
    body?.purchase_requirement.productType === 'PURCHASE_COLLECTIONS'
  ) {
    body.purchase_requirement.products = [];
  }

  if (
    (isCouponTypeAmount(body?.coupon_type) || body?.coupon_type === CouponType.PurchaseHistory) &&
    body?.application?.type === ApplicationTarget.Products
  ) {
    body.application.collections = [];
  } else if (
    (isCouponTypeAmount(body?.coupon_type) || body?.coupon_type === CouponType.PurchaseHistory) &&
    body?.application?.type === ApplicationTarget.Collections
  ) {
    body.application.products = [];
  }

  if (body?.purchase_requirement?.type === 'NONE') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
    delete body.purchase_requirement.products;
    delete body.purchase_requirement.collections;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_AMOUNT') {
    delete body.purchase_requirement.min_quantity;
    delete body.purchase_requirement.max_quantity;
  } else if (body?.purchase_requirement?.type === 'PURCHASE_QUANTITY') {
    delete body.purchase_requirement.min_amount;
    delete body.purchase_requirement.max_amount;
  }

  if (isCouponTypeAmount(body?.coupon_type)) {
    delete body.application.max_per_order;
    delete body.application.min_quantity;
  }

  if (body?.purchase_requirement?.max_quantity_needed === false) {
    body.purchase_requirement.max_quantity = null;
  }

  if (body?.purchase_requirement?.show_max_quantity === false) {
    body.purchase_requirement.max_quantity = null;
  }

  if (body?.coupon_type === CouponType.PurchaseHistory) {
    switch (body?.purchase_activity?.type) {
      case PurchaseActivityType.CouponCode:
        body.purchase_activity.coupon_usage = {};
        body.purchase_activity.coupon_usage.coupon_id = body?.purchase_activity?.selected_coupon_id;
        body.purchase_activity.min_amount = null;
        body.purchase_activity.min_quantity = null;
        break;
      case PurchaseActivityType.OrderAmount:
        body.purchase_activity.coupon_usage = null;
        body.purchase_activity.min_quantity = null;
        break;
      case PurchaseActivityType.OrderCount:
        body.purchase_activity.coupon_usage = null;
        body.purchase_activity.min_amount = null;
        break;
      default:
        break;
    }

    if (body?.purchase_activity_period?.start === null) {
      body.purchase_activity_period.start = dayjs(new Date()).toISOString();
    } else body.purchase_activity_period.start = dayjs(body.purchase_activity_period.start).toISOString();

    if (body?.purchase_activity_period?.has_endTime) {
      if (body?.purchase_activity_period?.end === null)
        body.purchase_activity_period.end = dayjs(new Date()).toISOString();
      else body.purchase_activity_period.end = dayjs(body.purchase_activity_period.end).toISOString();
    } else body.purchase_activity_period.end = null;
  } else {
    body.purchase_activity = null;
    body.purchase_activity_period = null;
  }

  if (body.per_customer_usage_limit !== '' && body.show_per_customer_usage_limit) {
    body.per_customer_usage_limit = Number(parseInt(body.per_customer_usage_limit, 10));
  }
  if (body.show_total_usage_limit) {
    body.total_usage_limit = Number(parseInt(body.total_usage_limit, 10));
  }
  if (body.show_total_usage_limit === false) {
    body.total_usage_limit = null;
  }

  if (body.show_per_customer_usage_limit === false) {
    body.per_customer_usage_limit = null;
  }

  if (body?.user_order_requirement?.type === 'all') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = null;
    delete body?.user_order_requirement?.type;
  }
  if (body?.user_order_requirement?.type === 'first') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = 0;
    delete body?.user_order_requirement?.type;
  }
  if (body?.user_order_requirement?.type === 'order') {
    delete body?.user_order_requirement?.type;
    body.user_order_requirement.max_order_count = body.user_order_requirement.max_order_count_needed
      ? body.user_order_requirement.max_order_count
      : null;
  }

  if (body?.activation_period?.start === null) {
    body.activation_period.start = dayjs(new Date()).toISOString();
  } else body.activation_period.start = dayjs(body.activation_period.start).toISOString();

  if (body?.activation_period?.has_endTime) {
    if (body?.activation_period?.end === null) body.activation_period.end = dayjs(new Date()).toISOString();
    else body.activation_period.end = dayjs(body.activation_period.end).toISOString();
  } else body.activation_period.end = null;

  if (body.coupon_type === CouponType.Bxgy) {
    body.discount_type = body.application.discount_type;
    body.discount_amount = body.application.discount_amount;
    body.combine = false;
  }

  if (body.coupon_type === CouponType.Bxgy && !body?.application?.show_max_per_order) {
    body.application.max_per_order = null;
  }

  if (body.coupon_type === CouponType.Bxgy && body.application.products && body.application.products.length > 0) {
    body.application.type = ApplicationTarget.Products;
  }

  if (body.coupon_type === CouponType.Bxgy && body.application.collections && body.application.collections.length > 0) {
    body.application.type = ApplicationTarget.Collections;
  }

  if (body.coupon_type === CouponType.Bxgy && body.discount_type === 'FREE') {
    body.discount_type = DiscountType.Percentage;
    body.discount_amount = 100;
  }

  delete body?.has_title;
  delete body?.activation_period.has_endTime;
  delete body?.purchase_activity_period?.has_endTime;
  delete body?.purchase_activity?.selected_coupon_id;
  delete body.show_discount_max_cap;
  delete body?.user_order_requirement?.max_order_count_needed;
  delete body?.purchase_requirement?.max_quantity_needed;
  delete body?.purchase_requirement.show_max_amount;
  delete body?.purchase_requirement.show_max_quantity;
  delete body?.purchase_requirement.productType;
  delete body?.purchase_requirement.items_from;
  delete body?.show_per_customer_usage_limit;
  delete body?.show_total_usage_limit;
  delete body?.application?.discount_type;
  delete body?.application?.discount_amount;
  delete body?.application?.items_from;
  delete body?.application?.show_max_per_order;

  return body;
};
