/* eslint-disable camelcase */
import { authAPI } from '..';
import { AxiosResponse } from 'axios';

export const forgotPassword = async (email: string): Promise<AxiosResponse> => {
  const body = { email };
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/password/forgot`, body);
};

export const validateResetPasswordToken = async (token: string): Promise<AxiosResponse> => {
  const body = { token };
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/password/verifylink`, body);
};

export const resetPassword = async (
  token: string,
  new_password: string,
  confirm_new_password: string
): Promise<AxiosResponse> => {
  const body = {
    token,
    new_password,
    confirm_new_password
  };
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/password/reset`, body);
};
