/* eslint-disable indent */
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import * as Unicons from '@iconscout/react-unicons';
import { CreateDiscountInfoCard } from './create-coupons-info-card';
import { InfoCardSetup } from '../../../constants/coupons/coupons';
import { DiscountFields } from '../create-discount/types';
import { ApplicationType, CouponType, DiscountType } from '../../../graphql';
import { Input, RadioGroup } from '../../flexyui';
import { RadioWithLabel } from '../../shared/radio-with-label/radio-with-label';
import { CheckboxWithLabel } from '../../shared/checkbox-with-label/checkbox-with-label';
import { isCouponTypeAmount } from '../../../utils/coupons/coupon-type-check';

export const CreateDiscountSetup: FC = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <CreateDiscountInfoCard data={InfoCardSetup[0]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">How does the discount apply?</div>
          <RadioGroup {...getFieldProps('application_type')} defaultValue={ApplicationType.Code}>
            <RadioWithLabel
              label="Coupon Code"
              value={ApplicationType.Code}
              onClick={() => setFieldValue('application_type', ApplicationType.Code)}
            />
            <RadioWithLabel
              label="Automatic"
              value={ApplicationType.Automatic}
              onClick={() => setFieldValue('application_type', ApplicationType.Automatic)}
            />
          </RadioGroup>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="font-medium mt-4 mb-1">Discount Code</div>
              <Input
                {...getFieldProps('code')}
                type="text"
                error={touched.code && !!errors.code}
                errorMessage={errors.code}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let fieldValue = e.target.value;
                  if (values.application_type === ApplicationType.Code) {
                    fieldValue = e.target.value.toUpperCase();
                  }
                  setFieldValue('code', fieldValue);
                }}
              />
            </div>
            {values?.has_title && (
              <div>
                <div className="font-medium mt-4 mb-1">Discount Title</div>
                <Input
                  {...getFieldProps('title')}
                  type="text"
                  error={touched.title && !!errors.title}
                  errorMessage={errors.title}
                />
              </div>
            )}
          </div>

          <CheckboxWithLabel
            label="Custom discount title"
            value="has_title"
            checked={values.has_title}
            onClick={() => setFieldValue('has_title', !values.has_title)}
          />

          {values.application_type !== ApplicationType.Automatic && (
            <CheckboxWithLabel
              label="Show discount coupon to customer"
              value="discoverable"
              checked={values.discoverable}
              onClick={() => setFieldValue('discoverable', !values.discoverable)}
            />
          )}
        </div>
      </div>

      {isCouponTypeAmount(values?.coupon_type) && (
        <div>
          <hr />
          <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
            <CreateDiscountInfoCard data={InfoCardSetup[1]} />
            <div>
              <div className="font-semibold text-base mb-4 block sm:hidden">What should this discount do?</div>
              <RadioGroup {...getFieldProps('coupon_type')} defaultValue={CouponType.CartAmount}>
                <RadioWithLabel
                  label="Amount off order"
                  value={CouponType.CartAmount}
                  onClick={() => setFieldValue('coupon_type', CouponType.CartAmount)}
                />
                <RadioWithLabel
                  label="Amount off products"
                  value={CouponType.ProductAmount}
                  onClick={() => setFieldValue('coupon_type', CouponType.ProductAmount)}
                />
                {/* <RadioWithLabel*/}
                {/*  label="Buy X get Y"*/}
                {/*  value={CouponType.Bxgy}*/}
                {/*  onClick={() => setFieldValue('coupon_type', CouponType.Bxgy)}*/}
                {/* />*/}
                {/* <RadioWithLabel*/}
                {/*  label="Purchase Driven"*/}
                {/*  value={CouponType.PurchaseHistory}*/}
                {/*  onClick={() => setFieldValue('coupon_type', CouponType.PurchaseHistory)}*/}
                {/* />*/}
              </RadioGroup>
            </div>
          </div>
        </div>
      )}

      {values?.coupon_type !== CouponType.Bxgy ? (
        <div>
          <hr />
          <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
            <CreateDiscountInfoCard data={InfoCardSetup[2]} />

            <div>
              <div className="font-semibold text-base mb-4 block sm:hidden">What is the discount value?</div>
              <RadioGroup {...getFieldProps('discount_type')} defaultValue={DiscountType.Percentage}>
                <RadioWithLabel
                  label="Percentage off"
                  value={DiscountType.Percentage}
                  onClick={() => setFieldValue('discount_type', DiscountType.Percentage)}
                />
                <RadioWithLabel
                  label="Fixed amount"
                  value={DiscountType.Fixed}
                  onClick={() => setFieldValue('discount_type', DiscountType.Fixed)}
                />
              </RadioGroup>
              {values?.discount_type && values?.discount_type === DiscountType.Percentage ? (
                <>
                  <div className="flex gap-4 mt-4">
                    <div className={'w-[150px]'}>
                      <div className="font-medium mb-1">Percentage off</div>
                      <Input
                        {...getFieldProps('discount_amount')}
                        type="number"
                        iconPosition="end"
                        icon={<Unicons.UilPercentage size={18} />}
                        error={touched.discount_amount && !!errors.discount_amount}
                        errorMessage={errors.discount_amount}
                      />
                    </div>
                    {values.show_discount_max_cap && (
                      <div className={'w-[150px]'}>
                        <div className="font-medium mb-1">Percentage off upto</div>
                        <Input
                          {...getFieldProps('discount_max_cap')}
                          type="number"
                          iconPosition="start"
                          icon={<Unicons.UilRupeeSign size={15} />}
                          error={touched.discount_max_cap && !!errors.discount_max_cap}
                          errorMessage={errors.discount_max_cap}
                        />
                      </div>
                    )}
                  </div>
                  <CheckboxWithLabel
                    label="Maximum purchase amount cap"
                    value="show_discount_max_cap"
                    checked={values.show_discount_max_cap}
                    onClick={() => setFieldValue('show_discount_max_cap', !values.show_discount_max_cap)}
                  />
                </>
              ) : (
                <div className="mt-4 w-[150px]">
                  <Input
                    {...getFieldProps('discount_amount')}
                    type="number"
                    iconPosition="start"
                    icon={<Unicons.UilRupeeSign size={15} />}
                    error={touched.discount_amount && !!errors.discount_amount}
                    errorMessage={errors.discount_amount}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
