import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../flexyui/Dialog/index';
import * as Unicons from '@iconscout/react-unicons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useProductsLazyQuery } from '../../../graphql';
import debounce from 'debounce';
import { Input } from '../../flexyui/Input';
import { Checkbox } from '../../flexyui/Checkbox';
import { numberWithCommas } from '../../../utils/format-price';
import { Button, Skeleton } from '../../flexyui';

interface Variant {
  variant_id: number;
  price: number;
  title: string;
  image_src: string;
  variant_title: string;
  variant_image_src: string;
  inventory_quantity: number;
}

interface Product {
  title: string;
  image_src: string;
  variants: Variant[];
  product_id: number;
  status: string;
}

interface SearchValue {
  input: string;
  productId: number | null;
  skip: number;
  limit: number;
}

type props = {
  open: boolean;
  setOpen: (data: any) => void;
  preSelections: Product[];
  onSave: (value: Product[]) => void;
  search?: string;
  searchId?: number | null;
};
export const ProductSelectionDialog: FC<props> = ({
  open,
  setOpen,
  preSelections,
  onSave,
  search = '',
  searchId = null
}) => {
  const limit = 10;

  const [page, setPage] = useState(0);
  const [selectedVariantIds, setSelectedVariantIds] = useState<Array<number>>([]);
  const [selectedProducts, setSelectedProducts] = useState<Array<any>>(preSelections);

  const [products, setProducts] = useState<Array<any>>([]);
  const [totalProductCount, setTotalProductCount] = useState<number>(0);

  const [getData, { data, loading }] = useProductsLazyQuery({
    variables: {
      productTitle: '',
      productId: searchId,
      skip: 0,
      limit: 10
    }
  });

  const [searchedValue, setSearchedValue] = useState<SearchValue>({
    input: '',
    productId: null,
    skip: 0,
    limit: 10
  });

  const handleSearch = (searchParams: any, skip: number, limit: number) => {
    getData({
      variables: {
        productTitle: searchParams.input || '',
        productId: searchParams.productId || null,
        skip,
        limit
      }
    }).then((response) => {
      const { data } = response;
      const newProducts = data?.products?.data || [];

      setTotalProductCount(data?.products?.page_info?.total_count || 0);

      if (products.length > 0) {
        // setProducts([...products, ...newProducts])
        setProducts((prevState) => [...prevState, ...newProducts]);
      } else {
        setProducts([...newProducts]);
      }
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), [products]);

  const handleProductSelection = (productId: number, item: Product) => {
    const selectedProductIndex = selectedProducts.findIndex((product: Product) => product.product_id === productId);

    const newSelectedProducts = [...selectedProducts];
    const newSelectedVariantIds = new Set(selectedVariantIds);

    const itemVariantIds = new Set(getAllVariantIds(item));

    if (selectedProductIndex !== -1) {
      if (selectedProducts[selectedProductIndex].variants.length < item.variants.length) {
        // Update product
        newSelectedProducts[selectedProductIndex] = item;
        itemVariantIds?.forEach((id) => newSelectedVariantIds.add(id));
      } else {
        // Remove product
        newSelectedProducts.splice(selectedProductIndex, 1);
        itemVariantIds?.forEach((id) => newSelectedVariantIds.delete(id));
      }
    } else {
      // Add product
      newSelectedProducts.push(item);
      itemVariantIds?.forEach((id) => newSelectedVariantIds.add(id));
    }

    // Set the updated state
    setSelectedProducts(newSelectedProducts);
    setSelectedVariantIds([...newSelectedVariantIds]);
  };

  const handleVariantSelection = (variantId: number, item: Product) => {
    const clickedVariant = item.variants.find((variant: Variant) => variant.variant_id === variantId);
    const selectedProductIndex = selectedProducts.findIndex(
      (product: Product) => product.product_id === item.product_id
    );

    let newSelectedProducts = [...selectedProducts];
    const newSelectedVariantIds = new Set(selectedVariantIds);

    if (newSelectedVariantIds.has(variantId)) {
      // Remove variant and update product if needed
      newSelectedProducts = newSelectedProducts
        .map((product) => {
          if (product.product_id === item.product_id) {
            const filteredVariants = product.variants.filter((variant: Variant) => variant.variant_id !== variantId);
            if (filteredVariants.length > 0) {
              return { ...product, variants: filteredVariants };
            } else {
              return null;
            }
          }
          return product;
        })
        .filter(Boolean);

      newSelectedVariantIds.delete(variantId);
    } else {
      // Add variant and update product
      if (selectedProductIndex !== -1) {
        const existingProduct = newSelectedProducts[selectedProductIndex];
        newSelectedProducts[selectedProductIndex] = {
          ...item,
          variants: [...existingProduct.variants, clickedVariant]
        };
      } else {
        newSelectedProducts.push({ ...item, variants: [clickedVariant] });
      }

      newSelectedVariantIds.add(variantId);
    }

    // Set the updated state
    setSelectedProducts(newSelectedProducts);
    setSelectedVariantIds([...newSelectedVariantIds]);
  };

  const getAllVariantIds = (item: Product) => {
    const variantIds: number[] = [];

    item.variants?.forEach((variant: Variant) => {
      variantIds.push(variant.variant_id);
    });

    return variantIds;
  };

  const handlePreSelectionChange = () => {
    const initialSelectedVariantIds: number[] = [];

    preSelections?.forEach((product) => {
      const variantIds = getAllVariantIds(product);
      initialSelectedVariantIds.push(...variantIds);
    });

    setSelectedVariantIds(initialSelectedVariantIds);
  };

  useEffect(() => {
    handlePreSelectionChange();
    setSelectedProducts(preSelections);
  }, [preSelections]);

  useEffect(() => {
    setPage(0);
    setProducts([]);
    setSearchedValue({
      input: search,
      productId: searchId,
      skip: 0,
      limit: 10
    });
  }, [search, searchId]);

  useEffect(() => {
    debouncedSearch(searchedValue, limit * page, limit);
  }, [searchedValue, page]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (open && !!search) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(search.length, search.length);
        }
      }, 0);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Select Products</p>
              </DialogTitle>
              {!searchId && (
                <div className="sticky z-10 bg-white top-0 p-3 !m-0 border-b-[1px]">
                  <Input
                    ref={inputRef}
                    type="text"
                    name="search_products"
                    placeholder="Search Products"
                    className="!m-0"
                    autoFocus={search.length > 0}
                    value={searchedValue.input}
                    onChange={(e: any) => {
                      setPage(0);
                      setProducts([]);
                      setSearchedValue((prevState) => {
                        return {
                          ...prevState,
                          input: e.target.value
                        };
                      });
                    }}
                  />
                </div>
              )}
              <DialogDescription className="!p-0 !m-0">
                <div id="scrollableDiv" className="w-full h-[60vh] overflow-y-auto scroll-smooth">
                  <InfiniteScroll
                    dataLength={products.length}
                    next={() => {
                      if ((products.length || 0) < totalProductCount) setPage((prevState) => prevState + 1);
                    }}
                    hasMore={products.length < totalProductCount}
                    loader={''}
                    scrollableTarget="scrollableDiv"
                  >
                    {products.length > 0 ? (
                      products?.map((item) => (
                        <React.Fragment key={item?.product_id}>
                          <div
                            className="w-[100%] h-[66px] flex items-center border-b-[1px] cursor-pointer px-4 sm:px-6"
                            onClick={() => handleProductSelection(item?.product_id, item)}
                          >
                            <Checkbox
                              name={'product-checkbox'}
                              checked={getAllVariantIds(item).every((variantId) =>
                                selectedVariantIds.includes(variantId)
                              )}
                            />
                            <div className="border rounded-lg border-black-200 border-solid p-2 ml-4">
                              {!item.image_src || item?.image_src === '' ? (
                                <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
                              ) : (
                                <img src={item?.image_src} style={{ height: '1.5rem', width: '1.5rem' }} />
                              )}
                            </div>
                            <div className="ml-2 text-gray-700">
                              <div>{item?.title}</div>
                              {item?.status === 'draft' && (
                                <div className="mt-1.5 bg-[#d5ebff] rounded-lg px-2 py-0.5 w-fit text-xs">Draft</div>
                              )}
                            </div>
                          </div>

                          {item?.variants?.map((variant: any) => (
                            <div
                              className="w-[100%] h-[45px] flex items-center border-b-[1px] cursor-pointer pl-12 pr-4 sm:pl-16 sm:pr-6"
                              onClick={() => handleVariantSelection(variant?.variant_id, item)}
                              key={variant?.variant_id}
                            >
                              <Checkbox
                                name={'variant-checkbox'}
                                checked={selectedVariantIds.includes(variant.variant_id)}
                              />
                              <div className="ml-2 text-gray-700 flex flex-1 items-center justify-between">
                                <div>{variant?.variant_title}</div>
                                <div>&#x20B9;{numberWithCommas(Number(variant?.price))}</div>
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))
                    ) : data && data.products.data?.length === 0 ? (
                      <div className="px-6 py-4 text-gray-500">No Results found for "{searchedValue.input}"</div>
                    ) : (
                      [0, 1, 2, 4].map((index) => (
                        <div key={index} className="flex justify-between py-2 px-5">
                          <div className="flex items-center gap-2">
                            <Skeleton className="!m-0 h-5 w-5" />
                            <Skeleton className="h-[42px] w-[42px]" />
                            <div>
                              <Skeleton size={'label'} className="w-32 sm:w-40 h-5" />
                              <Skeleton size={'md'} className="mt-1.5" />
                            </div>
                          </div>
                          <Skeleton size={'label'} className="w-12 sm:w-20" />
                        </div>
                      ))
                    )}
                  </InfiniteScroll>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={async () => {
                handlePreSelectionChange();
                setSelectedProducts(preSelections);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              onClick={async () => {
                onSave(selectedProducts);
                setOpen(false);
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
