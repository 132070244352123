import React, { useEffect, useState } from 'react';
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/flexyui';
import Collection from './collection';
import * as Unicons from '@iconscout/react-unicons';
import { ButtonWithInfo } from '../../components/flexyui/ButtonWithInfo';
import { Title } from '../../components/shared/dashboard-title/title';
import { UpsellingProduct } from './upselling-product';

export const Upsell: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('UpsellingProducts');
  const [productModal, setProductModal] = useState<boolean>(false);
  const [collectionModal, setCollectionModal] = useState<boolean>(false);
  const [productSearchId, setProductSearchId] = useState<number | null>(null);

  useEffect(() => {
    if (productModal) return;
    setProductSearchId(null);
  }, [productModal]);

  useEffect(() => {
    if (productSearchId) {
      setProductModal(true);
    } else {
      setProductModal(false);
    }
  }, [productSearchId]);

  return (
    <>
      <div className="px-4">
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center">
            <Title title="Upselling" />
            <ButtonWithInfo
              heading="Note"
              description="Draft and out of stock items will be automatically filtered on checkout."
              calculation={false}
            >
              <Unicons.UilInfoCircle size={15} className="ml-1.5" />
            </ButtonWithInfo>
          </div>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              if (activeTab === 'UpsellingProducts') setProductModal(true);
              else setCollectionModal(true);
            }}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add {activeTab === 'UpsellingProducts' ? 'Products' : 'Collections'}
          </Button>
        </div>

        <Tabs
          defaultValue="UpsellingProducts"
          className="justify-center w-full mt-0 sm:mt-2"
          onValueChange={(value) => setActiveTab(value)}
        >
          <TabsList className="max-w-fit justify-between backdrop-blur-3xl">
            <TabsTrigger value="UpsellingProducts" className="px-4">
              Products
            </TabsTrigger>
            <TabsTrigger value="Collection" className="px-4">
              Collection
            </TabsTrigger>
          </TabsList>
          <div className="mt-3 mb-4">
            <TabsContent value="UpsellingProducts">
              <UpsellingProduct
                productModal={productModal}
                setProductModal={setProductModal}
                productSearchId={productSearchId}
                setProductSearchId={setProductSearchId}
              />
            </TabsContent>
            <TabsContent value="Collection">
              <Collection collectionModal={collectionModal} setCollectionModal={setCollectionModal} />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </>
  );
};
