/* eslint-disable import/no-cycle */
import {
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';

import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import { Input } from '../Input';
import { DataTablePagination } from './data-tabale-pagination';
import { Skeleton } from '../../flexyui/Skeleton';
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import { enqueueSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import { DataTableRowDnd } from './data-table-row-dnd';

// eslint-disable-next-line max-len
export function DataTableDnd({
  columns,
  data,
  showFilterInput = true,
  onRowClick,
  rowSelection,
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  setPage,
  onPageChange,
  onRowsPerPageChange,
  showPagination = false,
  handleDndSaveChanges,
  updateMutationLoading,
  loadingRows = 5
}: any) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    enablePinning: true,
    manualPagination: true,
    autoResetAll: false
  });

  const [activeId, setActiveId] = useState(null);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [tableRowsDuplicate, setTableRowsDuplicate] = useState<any[]>([]);
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setTableRows((items) => {
        const oldIndex = items.findIndex((row) => row.original.id === active.id);
        const newIndex = items.findIndex((row) => row.original.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleTableRows = () => {
    const rows = table?.getRowModel()?.rows || [];
    setTableRows(rows);
    setTableRowsDuplicate(rows);
  };

  useEffect(() => {
    if (data) handleTableRows();
  }, [data]);

  const handleSave = async () => {
    setLoadingActions(true);
    try {
      await handleDndSaveChanges(tableRows);
      setLoadingActions(false);
      setShowActions(false);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
  };

  const handleCancel = useCallback(() => {
    handleTableRows();
    setLoadingActions(false);
    setShowActions(false);
  }, [data]);

  useEffect(() => {
    const hasChanges = !isEqual(tableRowsDuplicate, tableRows);
    if (hasChanges) {
      if (!updateMutationLoading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [tableRows, tableRowsDuplicate, saveButtonClicked, cancelButtonClicked]);

  return (
    <div>
      {showFilterInput && (
        <div className="flex items-center py-4">
          <Input
            placeholder="Filter codes..."
            value={(table.getColumn('code')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('code')?.setFilterValue(event.target.value)}
            type="text"
            className="min-w-[350px]"
          />
        </div>
      )}
      <div className="rounded-md border">
        <Table className="rounded-md">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="bg-[#F3F4F5] text-[#595F74]">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {data && table.getRowModel().rows?.length > 0 ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
              >
                <SortableContext items={tableRows.map((row) => row.original.id)} strategy={verticalListSortingStrategy}>
                  {tableRows.map((row) => (
                    <DataTableRowDnd row={row} onRowClick={onRowClick} />
                  ))}
                </SortableContext>
              </DndContext>
            ) : data && table.getRowModel().rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            ) : (
              [...Array(loadingRows)].map((_, index) => (
                <TableRow key={index}>
                  {table.getHeaderGroups()[0].headers.map((header) => (
                    <TableCell key={header.id} className="px-2 py-4">
                      <Skeleton size="xl" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      {showPagination && data && table.getRowModel().rows?.length > 0 && (
        <div className="py-6">
          <DataTablePagination
            table={table}
            rowSelection={rowSelection}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            count={count}
            page={page}
            setPage={setPage}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      )}
    </div>
  );
}
