import React, { useEffect, useState } from 'react';
import { Card, Tabs, TabsContent, TabsList, TabsTrigger } from '../flexyui';
import { AddressTab, GeneralTab, LoginTab, PaymentsTab } from './customization-tabs';
import useSaveCancelButtons from '../../../src/hooks/use-save-cancel';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../src/store';
import { setConfirmationDialog, setRouteLink } from '../../../src/store/main-slice';
import { useLocation, useNavigate } from 'react-router-dom';

export const CustomizationLeftPanel: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.hash.slice(1));
  const { showActions } = useSaveCancelButtons();

  if (!location.hash) {
    navigate('/customization#General', { replace: true });
  }

  useEffect(() => {
    setActiveTab(location.hash.slice(1));
  }, [location]);

  useEffect(() => {
    history.pushState(null, location.hash.slice(1), `customization#${location.hash.slice(1)}`);
  }, []);

  return (
    <div>
      <Tabs
        defaultValue="General"
        value={activeTab}
        className="justify-center"
        onValueChange={(value) => {
          dispatch(setRouteLink(`/customization#${value}`));
          history.pushState(null, value, `customization#${value}`);
          if (showActions === true) {
            dispatch(setConfirmationDialog(true));
          } else {
            setActiveTab(value);
          }
        }}
      >
        <TabsList className="max-w-[500px] justify-between backdrop-blur-3xl">
          <TabsTrigger value="General" className="px-4">
            General
          </TabsTrigger>
          <TabsTrigger value="Login" className="px-4">
            Login
          </TabsTrigger>
          <TabsTrigger value="Address" className="px-4">
            Address
          </TabsTrigger>
          <TabsTrigger value="Payments" className="px-4">
            Payments
          </TabsTrigger>
        </TabsList>
        <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05)] mt-3 mb-4 pt-3 pb-3 sm:pb-5">
          <div className="px-3 sm:px-5">
            <TabsContent value="General">
              <GeneralTab />
            </TabsContent>
            <TabsContent value="Login">
              <LoginTab />
            </TabsContent>
            <TabsContent value="Address">
              <AddressTab />
            </TabsContent>
            <TabsContent value="Payments">
              <PaymentsTab />
            </TabsContent>
          </div>
        </Card>
      </Tabs>
    </div>
  );
};
