import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { AppDispatch } from '../store';
import { useAuth } from '../context/auth';
import { useDispatch } from 'react-redux';
import { Login } from '../pages/login/login';
import { posthogIdentity } from '../utils/log-event';
import { setStore, setStoreName } from '../store/main-slice';
import { useLocation, useSearchParams } from 'react-router-dom';
import AuthenticatedApp from '../pages/authenticated-app/authenticated-app';
import { ForgotPassword } from '../pages/forgot-password/forgot-password';
import { ResetPassword } from '../pages/reset-password/reset-password';

function MainApp() {
  const location = useLocation();
  const { accessToken, setAccessToken } = useAuth();
  const [_, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('access');
  const resetPasswordToken = queryParameters.get('token');

  if (token) {
    const user: any = jwtDecode(token); // decode the token here
    setAccessToken(token);
    dispatch(setStore(user.payload.merchant_id));
    dispatch(setStoreName(user.payload.merchant_name));
    posthogIdentity({
      merchantId: user.payload.merchant_id,
      merchantName: user.payload.merchant_name
    });
    localStorage.setItem('access_token', token);
    localStorage.setItem('merchant_id', user.payload.merchant_id);
    localStorage.setItem('store_name', user.payload.merchant_name);
    setSearchParams('');
  }

  if (accessToken) {
    const user: any = jwtDecode(accessToken);
    posthogIdentity({
      merchantId: user.payload.merchant_id,
      merchantName: user.payload.merchant_name
    });
  }

  if (location.pathname === '/reset-password' && !!resetPasswordToken)
    return <ResetPassword token={resetPasswordToken} />;
  if (forgotPasswordActive) {
    return <ForgotPassword setForgotPasswordActive={setForgotPasswordActive} />;
  }
  if (!accessToken) {
    return <Login setForgotPasswordActive={setForgotPasswordActive} />;
  }
  return <AuthenticatedApp />;
}
export default MainApp;
