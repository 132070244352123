import { Separator, Skeleton } from '../../../flexyui';

const GeneralTabLoading = () => {
  return (
    <div>
      <Skeleton variant={'label'} size={'label'} />
      <div className="flex">
        <Skeleton className="w-[88px] h-10 p-3 mr-6" />
        <Skeleton className="w-[284px] h-[104px] m-0" />
      </div>
      <Separator />
      <Skeleton variant={'label'} size={'label'} />
      <div className="flex gap-4">
        {[1, 2].map((_, index) => (
          <div className="flex" key={index}>
            <Skeleton className="h-10" size="md" />
            <div>
              <Skeleton variant={'text'} size={'md'} />
              <Skeleton size={'sm'} className="w-16" />
            </div>
          </div>
        ))}
      </div>
      <Separator />
      <Skeleton variant={'label'} size={'label'} />
      <div className="flex items-center space-x-2 mr-3">
        <Skeleton className="w-[213px] h-[80px]" />
        <Skeleton className="w-[213px] h-[80px]" />
      </div>
      <Separator />
      <Skeleton variant={'label'} size={'label'} />
      {[1, 2, 3].map((_, index) => (
        <div className="flex" key={index}>
          <Skeleton variant={'rounded'} size={'xl'} className="h-10" />
        </div>
      ))}
      <Separator />
      <div className="flex items-center justify-end">
        <Skeleton className="w-[130px] h-[40px]" />
        <Skeleton className="w-[130px] h-[40px]" />
      </div>
    </div>
  );
};

export default GeneralTabLoading;
