import axios from 'axios';

export const authAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || ''
});

export const dashboardAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

dashboardAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token') || '';
  config.headers.Authorization = token;
  return config;
});
