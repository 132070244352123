/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { createContext, useContext } from 'react';
import jwt_decode from 'jwt-decode';

interface AuthContextProps {
  accessToken: string | null;
  storeToken: string | null;
  setAccessToken: (type: string) => void;
}

export const AuthContext = createContext({} as AuthContextProps);

export function useAuth() {
  return useContext(AuthContext);
}

export const isTokenExpired = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('access');

  const accessToken = localStorage.getItem('access_token') || token;
  if (!accessToken) {
    return true; // Treat as expired if the token is not present
  }
  const decodedToken: any = jwt_decode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};
