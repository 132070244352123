import * as Yup from 'yup';
import { ErrorMessages, requiredError } from '../../constants/error-messages';
import { CouponType, DiscountType } from '../../../src/graphql';

const paymentMethodSchema = () => {
  return Yup.object().shape({
    active: Yup.boolean().default(false),
    amount: Yup.number()
      .test('required', requiredError('Amount'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' && parent.active) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val !== null &&
          val !== undefined &&
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active
        )
          return val > 0;
        else return true;
      })
      .test('percentage', ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val &&
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active &&
          from?.[2]?.value['discount_type'] === DiscountType.Percentage
        )
          return val < 100;
        else return true;
      }),
    discount_max_cap: Yup.number()
      .test('required', requiredError('Maximum cap'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active &&
          parent.show_discount_max_cap
        ) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val !== null &&
          val !== undefined &&
          from?.[2]?.value['apply_discount_to'] === 'SPECIFIC_PAYMENT_MODE' &&
          parent.active &&
          parent.show_discount_max_cap
        )
          return val > 0;
        else return true;
      }),
    show_discount_max_cap: Yup.boolean().default(false)
  });
};

export const PaymentOfferSchema = Yup.object({
  code: Yup.string().required(requiredError('Discount code/title')),
  coupon_type: Yup.string().default(CouponType.Payment).required(),
  discount_type: Yup.string().default(DiscountType.Percentage).required(),
  discount_amount: Yup.number()
    .when('apply_discount_to', {
      is: (val: string) => val === 'ALL_PAYMENT_MODE',
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Discount value'))
    })
    .when(['apply_discount_to', 'discount_type'], {
      is: (applyDiscountTo: string, discountType: DiscountType) =>
        applyDiscountTo === 'ALL_PAYMENT_MODE' && discountType === DiscountType.Percentage,
      then: (schema) => schema.lessThan(100, ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR)
    }),
  discount_max_cap: Yup.number().when(['apply_discount_to', 'discount_type', 'show_discount_max_cap'], {
    is: (applyDiscountTo: string, discountType: DiscountType, showDiscountMaxCap: boolean) =>
      applyDiscountTo === 'ALL_PAYMENT_MODE' && discountType === DiscountType.Percentage && showDiscountMaxCap,
    then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Maximum cap')),
    otherwise: (schema) => schema.nullable()
  }),
  show_discount_max_cap: Yup.boolean().default(false),
  apply_discount_to: Yup.string()
    .default('ALL_PAYMENT_MODE')
    .test('required', 'This field is required', function (val) {
      // eslint-disable-next-line no-invalid-this
      const { parent } = this;
      if (
        parent.apply_discount_to === 'SPECIFIC_PAYMENT_MODE' &&
        !(
          parent.payment_offers.upi.active ||
          parent.payment_offers.emi.active ||
          parent.payment_offers.wallets.active ||
          parent.payment_offers.cards.active ||
          parent.payment_offers.pay_later.active ||
          parent.payment_offers.net_banking.active
        )
      ) {
        return false;
      } else return true;
    }),
  payment_offers: Yup.object().shape({
    upi: paymentMethodSchema(),
    emi: paymentMethodSchema(),
    wallets: paymentMethodSchema(),
    pay_later: paymentMethodSchema(),
    net_banking: paymentMethodSchema(),
    cards: paymentMethodSchema()
  }),
  purchase_requirement: Yup.object().shape({
    type: Yup.string().default('NONE').required(),
    min_amount: Yup.number().when('type', {
      is: (val: string) => val === 'PURCHASE_AMOUNT',
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Minimum amount')),
      otherwise: (schema) => schema.nullable()
    }),
    max_amount: Yup.number().when(['type', 'show_max_amount'], {
      is: (type: string, showMaxAmount: boolean) => type === 'PURCHASE_AMOUNT' && showMaxAmount,
      then: (schema) =>
        schema
          .min(Yup.ref('min_amount'), ErrorMessages.PURCHASE_REQUIREMENT_MAX_AMOUNT_ERROR)
          .required(requiredError('Maximum amount')),
      otherwise: (schema) => schema.nullable()
    }),
    show_max_amount: Yup.boolean().default(false),
    min_quantity: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('type', {
        is: (val: string) => val === 'PURCHASE_QUANTITY',
        then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Minimum quantity')),
        otherwise: (schema) => schema.nullable()
      }),
    max_quantity: Yup.number().when(['type', 'show_max_quantity'], {
      is: (type: string, showMaxQuantity: boolean) => type === 'PURCHASE_QUANTITY' && showMaxQuantity,
      then: (schema) =>
        schema
          .integer(ErrorMessages.WHOLE_NO_ERROR)
          .min(Yup.ref('min_quantity'), ErrorMessages.PURCHASE_REQUIREMENT_MAX_QUANTITY_ERROR)
          .required(requiredError('Maximum quantity')),
      otherwise: (schema) => schema.nullable()
    }),
    show_max_quantity: Yup.boolean().default(false),
    productType: Yup.string().default('CART'),
    products: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PRODUCTS',
        then: (schema) => schema.required().min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      })
  }),
  user_order_requirement: Yup.object().shape({
    type: Yup.string().default('all').required(),
    min_order_count: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('type', {
        is: (val: string) => val === 'order',
        then: (schema) =>
          schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Minimum order count')),
        otherwise: (schema) => schema.nullable()
      }),
    max_order_count: Yup.number().when(['type', 'max_order_count_needed'], {
      is: (type: string, maxOrderCountNeeded: boolean) => type === 'order' && maxOrderCountNeeded,
      then: (schema) =>
        schema
          .integer(ErrorMessages.WHOLE_NO_ERROR)
          .min(Yup.ref('min_order_count'), ErrorMessages.USER_ORDER_REQUIREMENT_MAX_ERROR)
          .required(requiredError('Maximum order count')),
      otherwise: (schema) => schema.nullable()
    }),
    max_order_count_needed: Yup.boolean().default(false)
  }),
  total_usage_limit: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('show_total_usage_limit', {
      is: true,
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Total usage limit')),
      otherwise: (schema) => schema.nullable()
    }),
  show_total_usage_limit: Yup.boolean().default(false),
  per_customer_usage_limit: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('show_per_customer_usage_limit', {
      is: true,
      then: (schema) =>
        schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Per customer usage limit')),
      otherwise: (schema) => schema.nullable()
    }),
  show_per_customer_usage_limit: Yup.boolean().default(false),
  discoverable: Yup.boolean().default(false),
  combine: Yup.boolean().default(false),
  activation_period: Yup.object().shape({
    start: Yup.date().required(requiredError('Start Date')),
    end: Yup.date()
      .when('has_endTime', {
        is: true,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('is-greater', ErrorMessages.END_DATE_TIME_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent }: any = this;
        if (parent.has_endTime) {
          return val && parent.start && new Date(val) > new Date(parent.start);
        } else return true;
      }),
    has_endTime: Yup.boolean().default(false)
  })
});
