export const translations = {
  EMAIL: {
    primaryKey: 'email',
    value: 'Email'
  },
  PASSWORD: {
    primaryKey: 'password',
    value: 'Password'
  },
  LOGIN: {
    primaryKey: 'login',
    value: 'LOGIN'
  }
};
