import * as React from 'react';

import { cn } from '../../../lib/utils';

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  endIcon?: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
}

const Input = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ className, type, icon, iconPosition = 'start', endIcon, error, errorMessage, ...props }, ref) => {
    const Icon = icon;

    const handleKeyDown = (e: any) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      }
    };

    return (
      <>
        <div className="relative flex items-center">
          {icon && iconPosition === 'start' && <div className="z-[9] absolute left-[10px]">{Icon}</div>}
          <input
            type={type}
            className={cn(
              'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-[#2F72FF] file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:bg-[#F1F1F1] relative',
              className,
              icon && iconPosition === 'start' ? 'pl-[35px]' : 'pr-[35px]',
              error &&
                'border-2 border-[#E50000] ring-0 ring-offset-[#cf3c3c] outline-none focus-visible:ring-offset-0 focus-visible:ring-0'
            )}
            onWheel={(e: any) => e.target.blur()}
            onKeyDown={handleKeyDown}
            ref={ref}
            {...props}
          />
          {icon && iconPosition === 'end' && <div className="z-[9] absolute right-[10px]">{Icon}</div>}
          {endIcon && <div className="z-[9] absolute right-[10px]">{endIcon}</div>}
        </div>
        {error && !!errorMessage && <p className="text-[#cf3c3c] text-xs mt-0.5 ml-0.5">{errorMessage}</p>}
      </>
    );
  }
);
Input.displayName = 'Input';

export { Input };
