/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthState = {
  // context_id: string;
  loggedIn: boolean;
};

const initialState: AuthState = {
  // context_id: '',
  loggedIn: false
};

export const authSlice = createSlice({
  name: 'auth-store',
  initialState,
  reducers: {
    // setContextId: (state, action: PayloadAction<string>) => {
    //   state.context_id = action.payload;
    // },

    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    }
  }
});

export const { setLoggedIn } = authSlice.actions;

export default authSlice.reducer;
