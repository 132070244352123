import React from 'react';
import { Card } from '../../../flexyui';
import IntegrationItem from './integration-item';
import { useAnalyticsConfigQuery } from '../../../../graphql';
import { ReactComponent as MetaSVG } from '../../../../assets/images/facebook-svg.svg';
import { ReactComponent as GoogleAdsSVG } from '../../../../assets/images/google-ads.svg';
import { ReactComponent as GoogleAnalyticsSVG } from '../../../../assets/images/google-analytics.svg';

export const IntegrationsOptions: React.FC = () => {
  const { data: integrationData, loading } = useAnalyticsConfigQuery();

  return (
    <Card className="h-fit overflow-hidden">
      <IntegrationItem
        loading={loading}
        icon={<MetaSVG />}
        integration="FACEBOOK_INTEGRATION"
        // serviceName="Facebook Pixel and Conversions"
        serviceName="Meta Pixels"
        route="/settings/integrations/meta"
        enabled={integrationData?.analyticsConfig.facebook?.enabled || false}
      />
      <hr />
      <IntegrationItem
        loading={loading}
        icon={<GoogleAnalyticsSVG />}
        integration="GOOGLE_ANALYTICS"
        serviceName="Google Analytics"
        route="/settings/integrations/google-analytics"
        enabled={integrationData?.analyticsConfig.google_analytics?.ua_enabled || false}
      />
      <hr />
      <IntegrationItem
        loading={loading}
        icon={<GoogleAdsSVG />}
        integration="GOOGLE_ADS"
        serviceName="Google Ads"
        route="/settings/integrations/google-ads"
        enabled={
          (integrationData?.analyticsConfig &&
            integrationData?.analyticsConfig?.google_ads?.length > 0 &&
            integrationData?.analyticsConfig?.google_ads[0]?.enabled) ||
          false
        }
      />
    </Card>
  );
};
