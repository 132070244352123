import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
// eslint-disable-next-line import/no-cycle
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Button } from '../index';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  rowSelection: boolean;
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  count: number;
  page: number;
  setPage: any;
  onPageChange: (_: any, value: number) => void;
  onRowsPerPageChange: (value: number) => void;
}

// eslint-disable-next-line max-len
export function DataTablePagination<TData>({
  table,
  rowSelection,
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  setPage,
  onPageChange,
  onRowsPerPageChange
}: DataTablePaginationProps<TData>) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex-1">
        {rowSelection && (
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
            selected.
          </div>
        )}
      </div>
      <div className="flex flex-col-reverse items-end space-x-6 lg:space-x-8 sm:items-center sm:flex-row mb-2 sm:mb-0">
        <div className="flex items-center space-x-2 mt-4 sm:mt-0">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${rowsPerPage}`}
            onValueChange={(value) => {
              onRowsPerPageChange(Number(value));
              table.setPageSize(Number(value));
              table.setPageCount(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {rowsPerPageOptions?.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-3">
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:flex"
            onClick={() => {
              onPageChange({}, page - 1);
            }}
            disabled={page < 1}
          >
            <span className="sr-only">previous page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <div className="text-sm font-medium">
            {`${page * rowsPerPage + 1} - ${(page + 1) * rowsPerPage >= count ? count : (page + 1) * rowsPerPage}`} of{' '}
            {count}
          </div>
          <Button
            variant="outline"
            className="h-8 w-8 p-0 lg:flex"
            onClick={() => {
              setPage((page: number) => page + 1);
            }}
            disabled={(page + 1) * rowsPerPage >= count}
          >
            <span className="sr-only">next page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
