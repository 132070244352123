import React from 'react';
import { Card } from '../flexyui';
import classNames from 'classnames';
import { settingsHeader } from '../../constants/settings/settings';
import { useLocation, useNavigate } from 'react-router-dom';

export const SettingsLeftPanel: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Card className="h-fit overflow-hidden mr-0 sm:mr-4">
        {settingsHeader.map((setting, index) => (
          <div
            key={setting.title}
            onClick={() => navigate(setting.route)}
            className={classNames('flex items-center px-4 py-2 gap-3 cursor-pointer', {
              'hover:bg-[#f5f5f5]': !location.pathname.startsWith(setting.route),
              'bg-[#e5e5e5]': location.pathname.startsWith(setting.route),
              'border-b': index !== settingsHeader.length - 1
            })}
          >
            {setting.icon}
            <span className="font-medium">{setting.title}</span>
          </div>
        ))}
      </Card>
    </>
  );
};
