import React from 'react';
import { useSalesChannelMetricsQuery } from '../../../graphql';
import { SalesChannelIcons, SalesChannelKey, salesChannelsItems } from '../../../constants/dashboard/dashboard';
import { convertAmount } from '../../../utils/convert-amount';
import { Skeleton, Card } from '../../flexyui';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  dateRange?: any;
};

export const SalesChannels: React.FC<Props> = ({ dateRange }) => {
  const { data, loading } = useSalesChannelMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(dateRange.startDate).toISOString(),
        end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  function getIcon(source?: string) {
    const key = source as SalesChannelKey;
    if (key in SalesChannelIcons) {
      return SalesChannelIcons[key];
    }
    // else {
    //   return <Unicons.UilSearch size={18} />; // Default fallback icon
    // }
  }

  return (
    <Card className="p-4 col-span-1 sm:col-span-2">
      <div className="font-medium text-black flex items-center">
        <div>Sales Channels</div>
        <ButtonWithInfo
          heading="Sales Channels"
          description="Distribution of sales from different social media channels."
          calculation={false}
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      {loading ? (
        <div className="pt-6 grid gap-3 grid-cols-1 sm:grid-cols-2">
          {Array.from({ length: 6 }, (_, index) => (
            <Skeleton className="w-full !m-0 h-[65px]" />
          ))}
        </div>
      ) : (
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2 mt-4">
          {(data?.salesChannelMetrics?.current?.length || 0) > 0 ? (
            salesChannelsItems?.map((each) => {
              const current = data?.salesChannelMetrics?.current?.find((item) => item?.utm_source === each);
              return (
                <div key={each} className="rounded-md bg-[#F9F9F9] p-3 flex justify-between items-center">
                  <div className="flex items-center">
                    {each !== 'other' ? <img alt={each} src={getIcon(each)} /> : <Unicons.UilLayerGroup size={18} />}
                    <div className="ml-2 font-medium">{each?.charAt(0)?.toUpperCase() + each?.slice(1)}</div>
                  </div>
                  <div className="flex flex-col items-end">
                    <div className="font-semibold text-base">₹{convertAmount(Number(current?.total_sales || 0))}</div>
                    <div>{current?.total_orders || 0} orders</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mt-12 ml-4">No data available</div>
          )}
        </div>
      )}
    </Card>
  );
};
