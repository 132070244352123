/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DateTime } from 'luxon';
import { refreshDashboard } from '../../network/authentication/authentication';
import { DatePicker } from '../../components/shared/date-picker/date-picker';

import { Title } from '../../components/shared/dashboard-title/title';
import { DatePickerDisplayButton } from '../../components/shared/date-picker/date-picker-display';
import {
  useMetricsQuery,
  usePaymentMetricsQuery,
  useCouponPerformanceMetricsQuery,
  ComparableCouponPerformanceMetrics,
  useUpsellingMetricsQuery
} from '../../graphql';
import { TotalOrders } from '../../components/dashboard/orders/orders';
import { TotalSales } from '../../components/dashboard/sales/sales';
import { PaymentMethod } from '../../components/dashboard/payment-methods/payment-methods';
import { AverageOrderValue } from '../../components/dashboard/average-order-value/average-order-value';
import { RevenuePerVisitor } from '../../components/dashboard/revenue-per-visitor/revenue-per-visitor';
import { AverageSessionDuration } from '../../components/dashboard/average-session-duration/average-session-duration';
import { PaymentSuccessRates } from '../../components/dashboard/payment-success-rates/payment-success-rates';
import { ConversionFunnel } from '../../components/dashboard/conversion-funnel/conversion-funnel';
import { CouponUsage } from '../../components/dashboard/coupon-usage/coupon-usage';
import LayoutWrapper from '../../components/layout/layout-wrapper';
import UpsellingPerformanceMetrics from '../../components/dashboard/upselling-metrics/upselling-metrics';
import { SalesChannels } from '../../components/dashboard/sales-channels/sales-channels';
import { Card } from '../../components/flexyui';

export const Dashboard: React.FC = () => {
  const [showDateRange, setShowDateRange] = useState(false);
  const savedRanges = localStorage.getItem('date-range');
  const queryClient = useQueryClient();
  const token = localStorage.getItem('access_token') || '';
  const merchantId = localStorage.getItem('merchant_id') || '';
  // eslint-disable-next-line no-undef
  const intervalRef = React.useRef<any>(null);

  const [ranges, setRanges] = useState(
    savedRanges
      ? JSON.parse(savedRanges)
      : {
          startDate: DateTime.now()
            .minus({
              days: 7
            })
            .toISODate(),
          endDate: DateTime.now().toISODate()
        }
  );
  const [enabled, setEnabled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expirationTime, setExpirationTime] = useState<number | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  const {
    data: metricData,
    loading: metricLoading,
    refetch: refetchMetrics
  } = useMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  const {
    data: couponPerformanceData,
    loading: couponPerformanceLoading,
    refetch: refetchCouponPerformanceMetrics
  } = useCouponPerformanceMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  // const {
  //   data: UpsellingPerformanceData,
  //   loading: UpsellingPerformanceLoading,
  //   refetch: refetchUpsellingPerformanceMetrics
  // } = useUpsellingMetricsQuery({
  //   variables: {
  //     dateRange: {
  //       start: new Date(ranges.startDate).toISOString(),
  //       end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
  //     }
  //   }
  // });

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTimeRemaining = prevTime ? prevTime - 1000 : null;
        if (!newTimeRemaining || newTimeRemaining < 1000) {
          clearInterval(intervalRef.current);
          setExpirationTime(null);
          localStorage.removeItem('expirationTime');
          return null;
        }
        return newTimeRemaining;
      });
    }, 1000);
  };

  const refreshMutation = useMutation(() => refreshDashboard(merchantId, token), {
    onSuccess: async () => {
      queryClient.invalidateQueries(['dashboardMetrics']);
      setEnabled(false);
      await refetchMetrics();
      await refetchCouponPerformanceMetrics();
      // await refetchUpsellingPerformanceMetrics();
      const currentTime = new Date().getTime();
      const newExpirationTime = currentTime + 60 * 60 * 1000; // 1 hour in milliseconds
      localStorage.setItem('expirationTime', String(newExpirationTime));
      setExpirationTime(newExpirationTime);
      setTimeRemaining(60 * 60 * 1000);
      startTimer();
    },

    onError: () => {
      setEnabled(false);
    }
  });

  const { isLoading: refreshing } = useQuery(['dashboardMetrics'], () => refreshMutation.mutateAsync(), {
    refetchInterval: 2000, // Polling interval: 2 seconds
    // eslint-disable-next-line no-use-before-define
    enabled
  });

  useEffect(() => {
    const storedExpirationTime = localStorage.getItem('expirationTime');
    const currentTime = new Date().getTime();

    if (storedExpirationTime && currentTime < Number(storedExpirationTime)) {
      const remainingTime = Number(storedExpirationTime) - currentTime;
      setTimeRemaining(remainingTime);
      startTimer();
    } else if (enabled && !refreshing) {
      const newExpirationTime = currentTime + 60 * 60 * 1000; // 1 hour in milliseconds
      localStorage.setItem('expirationTime', String(newExpirationTime));
      setExpirationTime(newExpirationTime);
      setTimeRemaining(60 * 60 * 1000);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('date-range', JSON.stringify(ranges));
  }, [ranges]);

  const { data: paymentGraphData, loading: loadingPaymentMetric } = usePaymentMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });
  const handleCloseDatePicker = () => {
    setShowDateRange(!showDateRange);
  };
  useEffect(() => {
    const handleBeforeUnload = () => {
      clearInterval(intervalRef.current);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <>
      <LayoutWrapper>
        <div className="mx-4 overflow-x-hidden">
          <Title title="Dashboard">
            <DatePickerDisplayButton
              setShowDateRange={setShowDateRange}
              showDateRange={showDateRange}
              ranges={ranges}
            />
          </Title>

          <div className="mb-4 grid gap-3 sm:gap-2 grid-cols-1 lg:grid-cols-3 sm:grid-cols-2">
            <Card className="col-span-1 sm:col-span-2 row-span-2">
              <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2">
                <TotalSales metricsData={metricData?.metrics} loading={metricLoading} dateRange={ranges} />
                <AverageOrderValue metricsData={metricData?.metrics} loading={metricLoading} dateRange={ranges} />
                <RevenuePerVisitor metricsData={metricData?.metrics} loading={metricLoading} dateRange={ranges} />
              </div>
            </Card>
            <TotalOrders metricsData={metricData?.metrics} loading={metricLoading} dateRange={ranges} />
            <AverageSessionDuration metricsData={metricData?.metrics} loading={metricLoading} dateRange={ranges} />
            <ConversionFunnel metricsData={metricData?.metrics} loading={metricLoading} dateRange={ranges} />
            <CouponUsage
              couponPerformanceData={
                couponPerformanceData?.couponPerformanceMetrics as ComparableCouponPerformanceMetrics
              }
              loading={couponPerformanceLoading}
            />
            {/* <PaymentSuccessRates paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
            {/* <PaymentMethod paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
            {/* <UpsellingPerformanceMetrics*/}
            {/*  totalOrders={Number(metricData?.metrics?.current?.total_orders)}*/}
            {/*  metrics={UpsellingPerformanceData?.upsellingMetrics?.current}*/}
            {/*  loading={UpsellingPerformanceLoading}*/}
            {/* />*/}
            <SalesChannels dateRange={ranges} />
            <PaymentMethod paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />
            <PaymentSuccessRates paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />
          </div>
        </div>
      </LayoutWrapper>

      <DatePicker
        open={showDateRange}
        setShowDateRange={setShowDateRange}
        // rangesPrev={ranges}
        onClose={handleCloseDatePicker}
        // minDate={new Date(String(installationDate))}
        setDateRange={(ranges: any) => {
          setRanges(ranges);
        }}
      />
    </>
  );
};
