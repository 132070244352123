import styled from 'styled-components';
import { SnackbarProviderProps, MaterialDesignContent } from 'notistack';
import { ReactComponent as CheckCircle } from '../assets/images/check-circle.svg';
import { ReactComponent as ExclamationTriangle } from '../assets/images/exclamation-triangle.svg';
import { ReactComponent as InfoCircle } from '../assets/images/info-circle.svg';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    border: '1px solid #45b970',
    borderRadius: '4px !important',
    backgroundColor: '#daf1e2 !important',
    color: '#279450 !important',
    boxShadow: 'none !important'
  },
  '&.notistack-MuiContent-error': {
    border: '1px solid #d74545',
    borderRadius: '4px !important',
    backgroundColor: '#f0d8d8 !important',
    color: '#d74545 !important',
    boxShadow: 'none !important'
  },
  // TODO: update info color
  '&.notistack-MuiContent-info': {
    border: '1px solid #d74545',
    borderRadius: '4px !important',
    backgroundColor: '#f0d8d8 !important',
    color: '#d74545 !important',
    boxShadow: 'none !important'
  }
}));

const iconStyle = {
  style: {
    marginRight: '10px',
    height: '18px'
  }
};

const snackbarConfig: SnackbarProviderProps = {
  iconVariant: {
    success: <CheckCircle {...iconStyle} fill="#279450" />,
    error: <ExclamationTriangle {...iconStyle} fill="#d74545" />,
    warning: <ExclamationTriangle {...iconStyle} />,
    info: <InfoCircle {...iconStyle} />
  },
  maxSnack: 3,
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom'
  },
  variant: 'success',
  Components: {
    success: StyledMaterialDesignContent,
    error: StyledMaterialDesignContent,
    info: StyledMaterialDesignContent,
    warning: StyledMaterialDesignContent
  },
  autoHideDuration: 4000
};

export default snackbarConfig;
