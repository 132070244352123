/* eslint-disable import/no-cycle */

import {
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';

import React from 'react';
import classNames from 'classnames';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import { Input } from '../Input';
import { DataTablePagination } from './data-tabale-pagination';
import { Skeleton } from '../../flexyui/Skeleton';

// eslint-disable-next-line max-len
export function DataTable({
  columns,
  data,
  showFilterInput = true,
  onRowClick,
  rowSelection,
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  setPage,
  onPageChange,
  onRowsPerPageChange,
  showPagination = false,
  loadingRows = 5
}: any) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    enablePinning: true,
    manualPagination: true,
    autoResetAll: false
  });
  return (
    <div>
      {showFilterInput && (
        <div className="flex items-center py-4">
          <Input
            placeholder="Filter codes..."
            value={(table.getColumn('code')?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn('code')?.setFilterValue(event.target.value)}
            type="text"
            className="min-w-[350px]"
          />
        </div>
      )}
      <div className="rounded-md border">
        <Table className="rounded-md">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="bg-[#F3F4F5] text-[#595F74]">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {data && table.getRowModel().rows?.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="cursor-pointer"
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => {
                    if (onRowClick) {
                      onRowClick(row.original);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell, index) => {
                    const lastIndex = index === 0;
                    return (
                      <TableCell
                        key={cell.id}
                        className={classNames('border-l border-gray-100', {
                          'border-l-0': lastIndex
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : data && table.getRowModel().rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            ) : (
              [...Array(loadingRows)].map((_, index) => (
                <TableRow key={index}>
                  {table.getHeaderGroups()[0].headers.map((header) => (
                    <TableCell key={header.id} className="px-2 py-4">
                      <Skeleton size="xl" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      {showPagination && data && table.getRowModel().rows?.length > 0 && (
        <div className="py-6">
          <DataTablePagination
            table={table}
            rowSelection={rowSelection}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            count={count}
            page={page}
            setPage={setPage}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      )}
    </div>
  );
}
