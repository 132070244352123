import posthog from 'posthog-js';

export const logEvent = (event: string, data?: object) => {
  posthog?.capture(event, data);
};

export const groupIdentity = (merchantId: string | number, merchantName: string) => {
  posthog.group('merchant', String(merchantId), {
    name: merchantName
  });
};

export const personIdentity = (merchantName: string) => {
  posthog.setPersonProperties({ merchant_name: merchantName });
};

export const posthogIdentity = (props: { merchantId: string | number; merchantName: string }) => {
  personIdentity(props.merchantName);
  groupIdentity(props.merchantId, props.merchantName);
};
