import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import CustomizationLayout from '../../dashboard/customizations/layout';
import { AppDispatch, RootState } from '../../../store';
import { setLoginCustomization, setPage } from '../../../store/customization-slice';
import { useLoginQuery, useUpdateLoginCustomisationMutation } from '../../../graphql';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import ErrorHandling from '../../error-handling/error-handling';
import { enqueueSnackbar } from 'notistack';

const LayoutTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loginCustomization = useSelector((state: RootState) => state.customization.login);
  const { data: loginData, loading: loginLoading, error: loginError, refetch } = useLoginQuery();
  const [updateLoginCustomisationMutation] = useUpdateLoginCustomisationMutation();
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();
  const [bannerCards, setBannerCards] = useState<any>([]);

  useEffect(() => {
    if (loginData?.customisations?.login_customisation_config) {
      dispatch(setLoginCustomization(loginData.customisations.login_customisation_config));
    }
  }, [loginData?.customisations.login_customisation_config]);

  useEffect(() => {
    dispatch(setPage('Login'));
  }, []);

  useEffect(() => {
    if (loginData?.customisations?.login_customisation_config?.banner?.banners) {
      setBannerCards(loginData?.customisations?.login_customisation_config?.banner?.banners);
    }
  }, [loginData]);

  const handleSave = async () => {
    setLoadingActions(true);
    try {
      await updateLoginCustomisationMutation({
        variables: {
          configs: {
            ...loginCustomization
          }
        }
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
    await refetch();
    setLoadingActions(false);
    // Don't remove
    // if (loginData?.customisations?.login_customisation_config) {
    //   dispatch(setLoginCustomization(loginData.customisations.login_customisation_config));
    // }
  };
  const handleCancel = () => {
    if (loginData?.customisations.login_customisation_config) {
      dispatch(setLoginCustomization(loginData?.customisations.login_customisation_config));
      setBannerCards(loginData?.customisations.login_customisation_config.banner.banners);
    }
  };

  useEffect(() => {
    const hasChanges = !isEqual(loginData?.customisations.login_customisation_config, loginCustomization);
    if (hasChanges) {
      if (!loginLoading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [
    loginData?.customisations.login_customisation_config,
    loginCustomization,
    saveButtonClicked,
    cancelButtonClicked
  ]);

  if (loginError?.networkError) return <ErrorHandling />;

  return (
    <>
      <CustomizationLayout
        tab="Login"
        lockCoupons={true}
        placeholder="Continue"
        loading={loginLoading}
        tabData={loginCustomization}
        setCustomization={setLoginCustomization}
        cards={bannerCards}
        setCards={setBannerCards}
      />
    </>
  );
};

export default LayoutTab;
