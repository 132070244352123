import * as Yup from 'yup';
import { ErrorMessages, requiredError } from '../../constants/error-messages';
import {
  ApplicationTarget,
  ApplicationType,
  CouponType,
  DiscountType,
  PurchaseActivityType,
  PurchaseRequirementType
} from '../../../src/graphql';
import { ItemsFrom } from '../../constants/coupons/coupons';
import { isCouponTypeAmount } from './coupon-type-check';

export const CouponSchema = Yup.object({
  code: Yup.string().required(requiredError('Discount code')),
  title: Yup.string().when('has_title', {
    is: true,
    then: (schema) => schema.required(requiredError('Discount title')),
    otherwise: (schema) => schema.nullable()
  }),
  has_title: Yup.boolean().default(false),
  application_type: Yup.string().default(ApplicationType.Code).required(),
  coupon_type: Yup.string().default(CouponType.CartAmount).required(),
  application: Yup.object().shape({
    type: Yup.string().default(ApplicationTarget.Universal).required(),
    min_quantity: Yup.number()
      .nullable()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .test('required', requiredError('Quantity'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { from } = this;
        if (val !== null && val !== undefined && from?.[1]?.value.coupon_type === CouponType.Bxgy) return val > 0;
        else return true;
      }),
    products: Yup.array()
      .default([])
      .test('required', ErrorMessages.MIN_PRODUCT_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_PRODUCT_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (isCouponTypeAmount(from?.[1]?.value.coupon_type) && parent.type === ApplicationTarget.Products) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_PRODUCT_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.PurchaseHistory && parent.type === ApplicationTarget.Products) {
          return val.length > 0;
        } else return true;
      }),
    collections: Yup.array()
      .default([])
      .test('required', ErrorMessages.MIN_COLLECTION_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_COLLECTION_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (isCouponTypeAmount(from?.[1]?.value.coupon_type) && parent.type === ApplicationTarget.Collections) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_COLLECTION_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type === ApplicationTarget.Collections
        ) {
          return val.length > 0;
        } else return true;
      }),
    items_from: Yup.string().default(ItemsFrom.SPECIFIC_PRODUCTS).required(),
    max_per_order: Yup.number()
      .nullable()
      .test('integer', ErrorMessages.WHOLE_NO_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.show_max_per_order) {
          return Number.isInteger(val);
        } else return true;
      })
      .test('required', requiredError('Max uses'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.show_max_per_order) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val !== null &&
          val !== undefined &&
          from?.[1]?.value.coupon_type === CouponType.Bxgy &&
          parent.show_max_per_order
        )
          return val > 0;
        else return true;
      }),
    show_max_per_order: Yup.boolean().default(false),
    discount_type: Yup.string().default(DiscountType.Percentage).required(),
    discount_amount: Yup.number()
      .nullable()
      .test('required', requiredError('Amount'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.discount_type != 'FREE') {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val !== null &&
          val !== undefined &&
          from?.[1]?.value.coupon_type === CouponType.Bxgy &&
          parent.discount_type != 'FREE'
        )
          return val > 0;
        else return true;
      })
      .test('percentage', ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (val && from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.discount_type === DiscountType.Percentage)
          return val <= 100;
        else return true;
      })
  }),
  discount_type: Yup.string().default(DiscountType.Percentage).required(),
  discount_amount: Yup.number()
    .nullable()
    .test('required', requiredError('Discount value'), function (val) {
      // eslint-disable-next-line no-invalid-this
      const { parent } = this;
      if (parent.coupon_type !== CouponType.Bxgy) {
        return typeof val === 'number';
      } else return true;
    })
    .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
      if (val !== null && val !== undefined) return val > 0;
      else return true;
    })
    .test('percentage', ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR, function (val) {
      // eslint-disable-next-line no-invalid-this
      const { parent } = this;
      if (val && parent.discount_type === DiscountType.Percentage) return val < 100;
      else return true;
    }),
  discount_max_cap: Yup.number().when(['coupon_type', 'show_discount_max_cap'], {
    is: (couponType: string, showDiscountMaxCap: boolean) => isCouponTypeAmount(couponType) && showDiscountMaxCap,
    then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Maximum cap')),
    otherwise: (schema) => schema.nullable()
  }),
  show_discount_max_cap: Yup.boolean().default(false),
  purchase_requirement: Yup.object().shape({
    type: Yup.string().default('NONE').required(),
    min_amount: Yup.number().when('type', {
      is: (val: string) => val === PurchaseRequirementType.PurchaseAmount,
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Amount')),
      otherwise: (schema) => schema.nullable()
    }),
    max_amount: Yup.number().when(['type', 'show_max_amount'], {
      is: (type: string, showMaxAmount: boolean) => type === PurchaseRequirementType.PurchaseAmount && showMaxAmount,
      then: (schema) =>
        schema
          .min(Yup.ref('min_amount'), ErrorMessages.PURCHASE_REQUIREMENT_MAX_AMOUNT_ERROR)
          .required(requiredError('Maximum amount')),
      otherwise: (schema) => schema.nullable()
    }),
    show_max_amount: Yup.boolean().default(false),
    min_quantity: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('type', {
        is: (val: string) => val === PurchaseRequirementType.PurchaseQuantity,
        then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Quantity')),
        otherwise: (schema) => schema.nullable()
      }),
    max_quantity: Yup.number().when(['type', 'show_max_quantity'], {
      is: (type: string, showMaxQuantity: boolean) =>
        type === PurchaseRequirementType.PurchaseQuantity && showMaxQuantity,
      then: (schema) =>
        schema
          .integer(ErrorMessages.WHOLE_NO_ERROR)
          .min(Yup.ref('min_quantity'), ErrorMessages.PURCHASE_REQUIREMENT_MAX_QUANTITY_ERROR)
          .required(requiredError('Maximum quantity')),
      otherwise: (schema) => schema.nullable()
    }),
    show_max_quantity: Yup.boolean().default(false),
    productType: Yup.string().default('PURCHASE_CART'),
    products: Yup.array()
      .default([])
      .test('required', ErrorMessages.MIN_PRODUCT_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_PRODUCT_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          isCouponTypeAmount(from?.[1]?.value.coupon_type) &&
          parent.type !== 'NONE' &&
          parent.productType === 'PURCHASE_PRODUCTS'
        ) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_PRODUCT_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type !== 'NONE' &&
          parent.productType === 'PURCHASE_PRODUCTS'
        ) {
          return val.length > 0;
        } else return true;
      }),
    collections: Yup.array()
      .default([])
      .test('required', ErrorMessages.MIN_COLLECTION_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (from?.[1]?.value.coupon_type === CouponType.Bxgy && parent.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_COLLECTION_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          isCouponTypeAmount(from?.[1]?.value.coupon_type) &&
          parent.type !== 'NONE' &&
          parent.productType === 'PURCHASE_COLLECTIONS'
        ) {
          return val.length > 0;
        } else return true;
      })
      .test('required', ErrorMessages.MIN_COLLECTION_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type !== 'NONE' &&
          parent.productType === 'PURCHASE_COLLECTIONS'
        ) {
          return val.length > 0;
        } else return true;
      }),
    items_from: Yup.string().default(ItemsFrom.SPECIFIC_PRODUCTS).required()
  }),
  purchase_activity: Yup.object().shape({
    type: Yup.string().default(PurchaseActivityType.CouponCode).required(),
    min_amount: Yup.number()
      .nullable()
      .test('required', requiredError('Amount'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type === PurchaseActivityType.OrderAmount
        ) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val &&
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type === PurchaseActivityType.OrderAmount
        )
          return val >= 0;
        else return true;
      }),
    min_quantity: Yup.number()
      .nullable()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .test('required', requiredError('Quantity'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type === PurchaseActivityType.OrderCount
        ) {
          return typeof val === 'number';
        } else return true;
      })
      .test('min_value', ErrorMessages.NEGATIVE_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          val &&
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type === PurchaseActivityType.OrderCount
        )
          return val >= 0;
        else return true;
      }),
    selected_coupon_id: Yup.string()
      .nullable()
      .test('required', requiredError('Coupon'), function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent, from } = this;
        if (
          from?.[1]?.value.coupon_type === CouponType.PurchaseHistory &&
          parent.type === PurchaseActivityType.CouponCode
        ) {
          return (val?.length || 0) > 0;
        } else return true;
      })
  }),
  purchase_activity_period: Yup.object().shape({
    start: Yup.date().required(requiredError('Start Date')),
    end: Yup.date()
      .when('has_endTime', {
        is: true,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('is-greater', ErrorMessages.END_DATE_TIME_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent }: any = this;
        if (parent.has_endTime) {
          return val && parent.start && new Date(val) > new Date(parent.start);
        } else return true;
      }),
    has_endTime: Yup.boolean().default(false)
  }),
  user_order_requirement: Yup.object().shape({
    type: Yup.string().default('all').required(),
    min_order_count: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('type', {
        is: (val: string) => val === 'order',
        then: (schema) =>
          schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Minimum order count')),
        otherwise: (schema) => schema.nullable()
      }),
    max_order_count: Yup.number().when(['type', 'max_order_count_needed'], {
      is: (type: string, maxOrderCountNeeded: boolean) => type === 'order' && maxOrderCountNeeded,
      then: (schema) =>
        schema
          .integer(ErrorMessages.WHOLE_NO_ERROR)
          .min(Yup.ref('min_order_count'), ErrorMessages.USER_ORDER_REQUIREMENT_MAX_ERROR)
          .required(requiredError('Maximum order count')),
      otherwise: (schema) => schema.nullable()
    }),
    max_order_count_needed: Yup.boolean().default(false)
  }),
  total_usage_limit: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('show_total_usage_limit', {
      is: true,
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Total usage limit')),
      otherwise: (schema) => schema.nullable()
    }),
  show_total_usage_limit: Yup.boolean().default(false),
  per_customer_usage_limit: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('show_per_customer_usage_limit', {
      is: true,
      then: (schema) =>
        schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Per customer usage limit')),
      otherwise: (schema) => schema.nullable()
    }),
  show_per_customer_usage_limit: Yup.boolean().default(false),
  discoverable: Yup.boolean().default(false),
  combine: Yup.boolean().default(false),
  activation_period: Yup.object().shape({
    start: Yup.date().required(requiredError('Start Date')),
    end: Yup.date()
      .when('has_endTime', {
        is: true,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('is-greater', ErrorMessages.END_DATE_TIME_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent }: any = this;
        if (parent.has_endTime) {
          return val && parent.start && new Date(val) > new Date(parent.start);
        } else return true;
      }),
    has_endTime: Yup.boolean().default(false)
  })
});
