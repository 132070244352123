export const convertAmount = (amount: number) => {
  const roundedNumber = Math.round(amount * 100) / 100; // Round the number to 2 decimal places
  const parts = roundedNumber.toLocaleString('en-IN', { minimumFractionDigits: 2 }).split('.');
  const integerPart = parts[0];
  let decimalPart = parts[1] || '';

  if (decimalPart === '00') {
    decimalPart = '';
  }

  // Combine the formatted parts
  const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  return Number.isNaN(formattedNumber) ? '0' : formattedNumber.toString();
};

export const calculateIncrement = (current: number, previous: number) => {
  return ((current - previous) / (previous === 0 ? 1 : previous)) * 100;
};
const excludedKeys = ['start', 'end'];

export enum PaymentMethods {
  card = 'Card',
  upi = 'UPI',
  wallet = 'Wallet',
  cod = 'COD',
  paylater = 'Paylater',
  netbanking = 'Netbanking',
  emi = 'EMI',
  partial_cod = 'Partial COD'
}
export const calculatePaymentMethods = (data: any, ...rest: any[]) => {
  const localData = { ...data };

  // eslint-disable-next-line no-param-reassign
  if (localData?.cod && rest.length === 0) delete localData.cod;

  if (localData) {
    const filteredData = Object.entries(localData)?.filter(([key]) => !excludedKeys.includes(key));
    const currentData = filteredData.map(([, method]) => {
      if (!method) {
        return { count: 0, success_rate: 0, amount: 0 };
      }
      return method;
    });
    const totalCount = currentData?.reduce(
      (total: number, method: any) => Number(total) + Number(method.count || 0),
      0
    );
    const sortedData = Object.keys(localData)
      .filter((key) => !excludedKeys.includes(key))
      ?.map((key: any) => {
        const method = localData[key] ? localData[key] : { count: 0, success_rate: 0, amount: 0 };
        return {
          ...method,
          success_rate: Number(method.success_rate).toFixed(2),
          key
        };
      })
      .sort((a, b) => b.success_rate - a.success_rate);

    // Calculate the percentage split for each payment method count
    const percentageSplit = sortedData?.map((method: any) => {
      return Number(((Number(method?.count || 0) / totalCount) * 100).toFixed(2));
    });

    const successRates = sortedData.map((d) => d?.success_rate);

    const sortedLabels = sortedData.map((d: any) => (PaymentMethods as any)[d?.key as keyof string]);

    let showPaymentMethodUsage = false;
    percentageSplit.forEach((localData) => {
      if (localData > 0) {
        showPaymentMethodUsage = true;
      }
    });

    return {
      showPaymentMethodUsage,
      successRates,
      currentData: [],
      filteredData,
      sortedLabels,
      percentageSplit
    };
  }
  return {
    showPaymentMethodUsage: false,
    successRates: [],
    currentData: [],
    filteredData: [],
    sortedLabels: [],
    percentageSplit: []
  };
};
