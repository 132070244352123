/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as Unicons from '@iconscout/react-unicons';
import { Formik, FormikErrors } from 'formik';
import { CreateOfferPreview } from './offer-preview';
import { CreateOfferSetup } from '../create-offer-steps/offer-setup';
import { CreateOfferApplication } from '../create-offer-steps/offer-application';
import { CreateOfferUserEligibility } from '../create-offer-steps/offer-user-eligibility';
import { CreateOfferUsageLimit } from '../create-offer-steps/offer-usage-limit';
import { OfferFields } from './types';
import { CouponType, useCreatePaymentOfferMutation, useUpdatePaymentOfferMutation } from '../../../graphql';
import { ActiveButton } from '../../shared/active-button/active-button';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  Button
} from '../../flexyui';
import { enqueueSnackbar } from 'notistack';
import { PaymentOfferSchema } from '../../../utils/payment-offers/payment-offer-schema';
import { paymentOfferDataHandler } from '../../../utils/payment-offers/payment-offer-data-handler';
import { createOfferHeader, paymentOfferPageKeys } from '../../../constants/payment-offers/payment-offers';
import { cloneDeep } from 'lodash';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import dayjs from 'dayjs';

type props = {
  open: boolean;
  setShowCreateDiscount: (data: any) => void;
  refetch: () => void;
  offerDetails: any;
  setCouponToEdit: (data: any) => void;
  isEdit: boolean;
};

export const CreateOffer: FC<props> = ({
  open,
  setShowCreateDiscount,
  refetch,
  offerDetails,
  isEdit,
  setCouponToEdit
}) => {
  const [createPaymentOffer, { loading: createPaymentOfferLoading }] = useCreatePaymentOfferMutation();
  const [updatePaymentOffer, { loading: updatePaymentOfferLoading }] = useUpdatePaymentOfferMutation();

  const [update, setUpdate] = useState(false);
  const [couponIdToEdit, setCouponIdToEdit] = useState(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleSave = async (values: any) => {
    const newValues = cloneDeep(values);
    const body = paymentOfferDataHandler(newValues);
    try {
      if (update) {
        await updatePaymentOffer({
          variables: {
            couponId: couponIdToEdit,
            data: body
          }
        });
      } else {
        await createPaymentOffer({
          variables: {
            data: body
          }
        });
      }
      setShowCreateDiscount(false);
      enqueueSnackbar(`Hurray! Payment Offer ${update ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      setShowCreateDiscount(true);
      body.purchase_requirement.products = offerDetails?.purchase_requirement?.products || [];
      setCouponToEdit(body);
      console.log(e); // TODO: handle error

      enqueueSnackbar(`Oops! Payment Offer ${update ? 'update' : 'creation'} failed!`, {
        variant: 'error'
      });
    } finally {
      setActiveStep(0);
    }
  };

  const checkErrorForNavigation = (errors: FormikErrors<OfferFields>) =>
    paymentOfferPageKeys[activeStep].some((key) => Object.keys(errors).includes(key));

  const initialValues = useMemo(
    () => ({
      code: offerDetails?.code || '',
      coupon_type: CouponType.Payment,
      apply_discount_to: offerDetails?.payment_offers?.ALL !== null ? 'ALL_PAYMENT_MODE' : 'SPECIFIC_PAYMENT_MODE',
      application: {
        type: offerDetails?.application?.type || 'UNIVERSAL',
        products: offerDetails?.application?.products || []
      },
      discount_type: offerDetails?.discount_type || 'PERCENTAGE',
      discount_amount: offerDetails?.payment_offers?.ALL !== null ? offerDetails?.payment_offers?.ALL.amount : '',
      discount_max_cap:
        (offerDetails?.payment_offers?.ALL !== null && offerDetails?.payment_offers?.ALL?.discount_max_cap) || '',
      show_discount_max_cap: offerDetails?.payment_offers?.ALL?.discount_max_cap > 0,
      payment_offers: {
        upi: {
          active: offerDetails?.payment_offers?.UPI?.active || false,
          amount: offerDetails?.payment_offers?.UPI?.amount || '',
          discount_max_cap: offerDetails?.payment_offers?.UPI?.discount_max_cap || '',
          show_discount_max_cap:
            (offerDetails?.payment_offers?.UPI && offerDetails?.payment_offers?.UPI?.discount_max_cap !== 0) || false
        },
        emi: {
          active: offerDetails?.payment_offers?.EMI?.active || false,
          amount: offerDetails?.payment_offers?.EMI?.amount || '',
          discount_max_cap: offerDetails?.payment_offers?.EMI?.discount_max_cap || '',
          show_discount_max_cap:
            (offerDetails?.payment_offers?.EMI && offerDetails?.payment_offers?.EMI?.discount_max_cap !== 0) || false
        },
        wallets: {
          active: offerDetails?.payment_offers?.WALLET?.active || false,
          amount: offerDetails?.payment_offers?.WALLET?.amount || '',
          discount_max_cap: offerDetails?.payment_offers?.WALLET?.discount_max_cap || '',
          show_discount_max_cap:
            (offerDetails?.payment_offers?.WALLET && offerDetails?.payment_offers?.WALLET?.discount_max_cap !== 0) ||
            false
        },
        pay_later: {
          active: offerDetails?.payment_offers?.PAY_LATER?.active || false,
          amount: offerDetails?.payment_offers?.PAY_LATER?.amount || '',
          discount_max_cap: offerDetails?.payment_offers?.PAY_LATER?.discount_max_cap || '',
          show_discount_max_cap:
            (offerDetails?.payment_offers?.PAY_LATER &&
              offerDetails?.payment_offers?.PAY_LATER?.discount_max_cap !== 0) ||
            false
        },
        net_banking: {
          active: offerDetails?.payment_offers?.NET_BANKING?.active || false,
          amount: offerDetails?.payment_offers?.NET_BANKING?.amount || '',
          discount_max_cap: offerDetails?.payment_offers?.NET_BANKING?.discount_max_cap || '',
          show_discount_max_cap:
            (offerDetails?.payment_offers?.NET_BANKING &&
              offerDetails?.payment_offers?.NET_BANKING?.discount_max_cap !== 0) ||
            false
        },
        cards: {
          active: offerDetails?.payment_offers?.CARD?.active || false,
          amount: offerDetails?.payment_offers?.CARD?.amount || '',
          discount_max_cap: offerDetails?.payment_offers?.CARD?.discount_max_cap || '',
          show_discount_max_cap:
            (offerDetails?.payment_offers?.CARD && offerDetails?.payment_offers?.CARD?.discount_max_cap !== 0) || false
        }
      },
      purchase_requirement: {
        type: offerDetails?.purchase_requirement?.type || 'NONE',
        show_max_amount: !!offerDetails?.purchase_requirement?.max_amount,
        show_max_quantity: !!offerDetails?.purchase_requirement?.max_quantity,
        min_amount: offerDetails?.purchase_requirement?.min_amount || null,
        max_amount: offerDetails?.purchase_requirement?.max_amount || null,
        min_quantity: offerDetails?.purchase_requirement?.min_quantity || null,
        max_quantity: offerDetails?.purchase_requirement?.max_quantity || null,
        productType: offerDetails?.purchase_requirement?.products?.length > 0 ? 'PRODUCTS' : 'CART',
        products: offerDetails?.purchase_requirement?.products || []
      },
      user_order_requirement: {
        max_order_count: offerDetails?.user_order_requirement?.max_order_count || null,
        max_order_count_needed: !!offerDetails?.user_order_requirement?.max_order_count,
        min_order_count: offerDetails?.user_order_requirement?.min_order_count || null,
        type:
          offerDetails?.user_order_requirement?.max_order_count === 0 &&
          offerDetails?.user_order_requirement?.min_order_count === null
            ? 'first'
            : offerDetails?.user_order_requirement?.min_order_count >= 0 &&
                offerDetails?.user_order_requirement?.min_order_count !== null
              ? 'order'
              : 'all'
      },
      show_per_customer_usage_limit: !!offerDetails?.per_customer_usage_limit,
      show_total_usage_limit: !!offerDetails?.total_usage_limit,
      discoverable: offerDetails?.discoverable || false,
      combine: offerDetails?.combine || false,
      activation_period: {
        start: offerDetails?.activation_period?.start || dayjs().format('YYYY-MM-DDTHH:mm'),
        end: offerDetails?.activation_period?.end || dayjs().format('YYYY-MM-DDTHH:mm'),
        has_endTime: !!offerDetails?.activation_period?.end
      },
      per_customer_usage_limit: offerDetails?.per_customer_usage_limit || null,
      total_usage_limit: offerDetails?.total_usage_limit || null
    }),
    [offerDetails]
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeButton = scrollContainerRef.current.querySelector(`[data-coupon-step-number="${activeStep}"]`);
      if (activeButton) {
        activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [activeStep]);

  return (
    <Formik<OfferFields>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={PaymentOfferSchema}
      onSubmit={handleSave}
    >
      {({ errors, submitForm, resetForm, validateForm, isSubmitting }) => {
        // console.log('errors', errors)

        return (
          <AlertDialog open={open} onOpenChange={(value: boolean) => setShowCreateDiscount(value)}>
            <AlertDialogContent className="!gap-0" size="fullWidth">
              <AlertDialogHeader className="w-full h-full">
                <AlertDialogTitle className="p-4 sm:pl-6 sm:pr-5 bg-[#F9F9F9]">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-4">
                      <Unicons.UilPercentage />
                      <h1 className="text-xl font-medium">
                        {offerDetails ? 'Edit Payment Offer' : 'Create Payment Offer'}
                      </h1>
                    </div>
                    <Button
                      size="icon"
                      variant="icon"
                      onClick={() => {
                        setActiveStep(0);
                        setShowCreateDiscount(false);
                        setCouponToEdit(null);
                      }}
                      className="hover:bg-[#f2f8ff]"
                    >
                      <Unicons.UilTimes className="text-[#2F72FF]" />
                    </Button>
                  </div>
                </AlertDialogTitle>
                <AlertDialogDescription className="!p-0 !m-0 w-full !h-[calc(100dvh-65px)] overflow-hidden text-[#2A324C]">
                  <div className="flex h-full">
                    <div className="flex-1 relative">
                      <div className="border-r-[1px] h-full">
                        <div className="w-[100vw] sm:w-[calc(100vw-22rem)] sticky px-4 sm:px-8">
                          <div
                            className="flex items-center gap-2 overflow-x-auto scrollbar-hide py-3 sm:py-4"
                            ref={scrollContainerRef}
                          >
                            {createOfferHeader.map((header) => {
                              return (
                                <ActiveButton
                                  step={header.step}
                                  logo={header.logo}
                                  activeStep={activeStep}
                                  stepNumber={header.stepNumber}
                                  setActiveStep={setActiveStep}
                                  disabled={Object.keys(errors).length > 0}
                                  couponType="PAYMENT"
                                />
                              );
                            })}
                          </div>
                        </div>
                        <hr />

                        <div className="h-[calc(100dvh-180px)] sm:h-[calc(100dvh-190px)] mb-2 overflow-auto">
                          {activeStep === 0 && <CreateOfferSetup />}
                          {activeStep === 1 && <CreateOfferApplication />}
                          {activeStep === 2 && <CreateOfferUserEligibility />}
                          {activeStep === 3 && <CreateOfferUsageLimit />}
                        </div>
                      </div>
                      <div className="px-4 max-h-[55px] flex justify-end gap-2 absolute bg-[#F9F9F9] bottom-0 border-t-[1px] border-t-[#E2E2E2] p-3 w-full transition duration-150 ease-out hover:ease-in z-10">
                        {activeStep !== 0 && (
                          <Button
                            variant="backward"
                            className={'h-8'}
                            onClick={() => {
                              setActiveStep((prev: number) => {
                                return prev - 1;
                              });
                            }}
                          >
                            Back
                          </Button>
                        )}

                        <Button
                          variant="forward"
                          className={'h-8'}
                          onClick={async () => {
                            if (activeStep === 3 && isEdit) {
                              setUpdate(true);
                              setCouponIdToEdit(offerDetails.id);
                            } else {
                              setUpdate(false);
                            }
                            if (activeStep !== 3) {
                              setActiveStep((prev: number) => prev + 1);
                            } else {
                              await submitForm();
                              resetForm();
                            }
                          }}
                          disabled={checkErrorForNavigation(errors) || isSubmitting}
                        >
                          {createPaymentOfferLoading || updatePaymentOfferLoading ? (
                            <LoadingIcon height={20} className={'animate-spin text-white'} />
                          ) : activeStep === 3 ? (
                            isEdit ? (
                              'Save'
                            ) : (
                              'Submit'
                            )
                          ) : (
                            'Next'
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="p-4 hidden sm:block">
                      <CreateOfferPreview activeStep={activeStep} selectedPaymentOffer={null} isEdit={isEdit} />
                    </div>
                  </div>
                </AlertDialogDescription>
              </AlertDialogHeader>
            </AlertDialogContent>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};
