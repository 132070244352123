import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppDispatch, RootState } from '../store';
import {
  setShowSaveCancel,
  setSaveButtonClicked,
  setCancelButtonClicked,
  setSaveCancelLoading
} from '../store/main-slice';

type ActionFunction = () => void;

type ActionType = {
  onSave: ActionFunction;
  onCancel: ActionFunction;
  showActions: boolean;
  cancelButtonClicked: any;
  saveButtonClicked: any;
  isSaveCancelLoading?: boolean;
  setShowActions: (value: boolean) => void;
  setLoadingActions: (value: boolean) => void;
};

const useSaveCancelButtons = (): ActionType => {
  const dispatch = useDispatch<AppDispatch>();
  const showActions = useSelector((state: RootState) => state.main.showSaveCancel);
  const saveClicked = useSelector((state: RootState) => state.main.saveButtonClicked);
  const cancelClicked = useSelector((state: RootState) => state.main.cancelButtonClicked);
  const isSaveCancelLoading = useSelector((state: RootState) => state.main.saveCancelLoading);

  const setShowActions: ActionType['setShowActions'] = (state) => {
    dispatch(setShowSaveCancel(state));
  };
  const setLoadingActions: ActionType['setLoadingActions'] = (state) => {
    dispatch(setSaveCancelLoading(state));
  };

  const onSave: ActionType['onSave'] = () => {
    dispatch(setSaveButtonClicked(true));
  };

  const onCancel: ActionType['onCancel'] = () => {
    dispatch(setCancelButtonClicked(true));
  };

  useEffect(() => {
    if (saveClicked) {
      dispatch(setSaveButtonClicked(false));
    }
    if (cancelClicked) {
      dispatch(setCancelButtonClicked(false));
    }
  }, [saveClicked, cancelClicked]);

  return {
    showActions,
    setShowActions,
    onSave,
    onCancel,
    isSaveCancelLoading,
    setLoadingActions,
    saveButtonClicked: saveClicked,
    cancelButtonClicked: cancelClicked
  };
};

export default useSaveCancelButtons;
