import { ReactComponent as PMUpiIcon } from '../../assets/images/pm-upi-bold.svg';
export const paymentMethod1 = {
  key: 'UPI / QR Code',
  visibility: true,
  provider: 'RAZORPAY',
  title: 'UPI',
  description: 'Pay with installed apps, or any UPI ID',
  position: 0,
  discount: 37.5,
  total_payable: 712.45,
  discount_data: {
    amount: 5,
    discount_type: 'PERCENTAGE',
    coupon_id: '762d5efa-7a28-4bb6-8594-f76b992be8f1',
    code: 'PAY'
  },
  icon: PMUpiIcon
};
