import classNames from 'classnames';
import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from '../../flexyui';

type Props = {
  value: any[];
  setFieldValue: any;
  on: string;
};

const SelectedProducts = ({ value, setFieldValue, on }: Props) => {
  const getBorderRadius = (index: number, length: number) => {
    if (length <= 1) {
      return 'border rounded-lg';
    }
    if (index === 0) {
      return 'border rounded-t-lg';
    }
    if (index === length - 1) {
      return 'border border-t-[0px] rounded-b-lg';
    }
    return 'border border-t-[0px]';
  };

  return (
    <div>
      {value &&
        value.length > 0 &&
        value?.map((item: any, index: number) => {
          return (
            <div
              className={classNames(
                'flex justify-center flex-col w-full pr-4 border-solid border-black-100',
                getBorderRadius(index, value.length ? value.length : -1)
              )}
            >
              <div className="flex this-testing items-center justify-between gap-2 border-black font-medium text-sm">
                <div className="pl-2.5 py-2.5">
                  <div className="flex items-center">
                    <div className="border rounded-sm border-black-200 border-solid p-1">
                      {!item.image_src || item?.image_src === '' ? (
                        <Unicons.UilImage style={{ height: '1.5rem' }} />
                      ) : (
                        <img src={item?.image_src} style={{ height: '1.5rem' }} />
                      )}
                    </div>
                    <div className="flex flex-col ml-2">
                      <div className="font-normal">{item?.title}</div>
                      {item.total_variants > 1 && (
                        <div className="text-xs font-normal text-[#a0a0a0] mt-[-2px]">
                          (selected {item.variants.length}/{item.total_variants} variants)
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Button
                  size="icon"
                  variant="ghost"
                  className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
                  onClick={() => {
                    setFieldValue(
                      on,
                      value?.filter((product: any) => product?.product_id !== item?.product_id)
                    );
                  }}
                >
                  <Unicons.UilMultiply size={16} className="text-[#2A324C]" />
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SelectedProducts;
