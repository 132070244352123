/* eslint-disable arrow-body-style */
import MethodLayoutCard from './method-card';
import { PaymentMethodResponse } from '../../../types/customization';
import UPIImage from '../../../assets/images/bundle-upi-methods.svg';
import classNames from 'classnames';

type PaymentItemProps = {
  data: PaymentMethodResponse;
};

const MethodLayout3: React.FC<PaymentItemProps> = ({ data }) => {
  const pricing = {
    cod_price: 50
  };

  return (
    <MethodLayoutCard>
      <div className="relative flex w-full items-center justify-between">
        <div className="flex w-full items-center">
          <div className="mx-1 flex flex-col">
            <div className="text-md flex flex-row">
              <div
                className={classNames('font-bold text-[#4B5563]', {
                  //  'text-green-600': data.discount
                })}
              >
                ₹ {data.total_payable}
              </div>
              {data.discount > 0 || (data.key === 'COD' && pricing.cod_price) ? (
                <div
                  className={classNames('mx-2 flex items-center rounded-full px-2 text-xs', {
                    'bg-slate-100': data.key === 'COD',
                    'bg-green-100': data.key !== 'COD',
                    'text-green-600': data.discount > 0
                  })}
                >
                  {data.discount > 0 && (
                    <>
                      Extra {data.discount_data.discount_type === 'FIXED' && '₹'}
                      {data.discount_data.amount}
                      {data.discount_data.discount_type === 'PERCENTAGE' && '%'} off
                    </>
                  )}
                  {data.key === 'COD' && pricing.cod_price && <>Additional ₹{pricing.cod_price} COD fee</>}
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              className={classNames('whitespace-nowrap text-sm font-normal text-[#4B5563]', {
                // 'text-green-600': data.discount,
              })}
            >
              {data.key !== 'COD' && data.key !== 'ONLINE' && 'Pay via'} {data.title}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 top-0 flex flex-row items-center justify-end">
          <div className="h-full w-6 bg-gradient-to-r from-transparent via-white to-white pl-6" />
          <div className="flex h-full flex-row items-center bg-white">
            <img src={UPIImage} className="h-full py-1 max-md:h-8" alt="payment method" />
          </div>
        </div>
      </div>
    </MethodLayoutCard>
  );
};

export default MethodLayout3;
