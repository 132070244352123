import React, { useEffect } from 'react';
import { Title } from '../../components/shared/dashboard-title/title';
import { SettingsLeftPanel } from '../../components/settings/settings-left-panel';
import { SettingsStoreDetails } from '../../components/settings/settings-right-panel/store-details';
import { SettingsPayment } from '../../components/settings/settings-right-panel/payment';
import { TaxesAndDuties } from '../../components/settings/settings-right-panel/taxes-and-duties';
import { SettingPasswordDetails } from '../../components/settings/settings-right-panel/change-password';
import { IntegrationsMain } from '../../components/settings/settings-right-panel/integrations-screens/integrations-main';
import LayoutWrapper from '../../components/layout/layout-wrapper';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useScreenSize from '../../hooks/use-screen-size';
import * as Unicons from '@iconscout/react-unicons';
import { getPageName, pathMap } from '../../constants/settings/settings';
import { Button } from '../../components/flexyui';
import { useProfileQuery } from '../../graphql';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { setStoreUrl } from '../../store/main-slice';

export const Settings: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useScreenSize().width < 640;
  const { data, loading } = useProfileQuery();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (data) dispatch(setStoreUrl(data?.profile?.domains?.main_domain));
  }, [data]);

  useEffect(() => {
    const path = location.pathname;
    const isSettingsPath = path.startsWith('/settings');
    const isValidPath = Object.keys(pathMap).includes(path);

    if (isMobile) {
      if (isSettingsPath && !isValidPath) {
        navigate('/settings');
      }
    } else {
      if (isSettingsPath && (!isValidPath || path === '/settings')) {
        navigate('/settings/store');
      }
    }
  }, [location.pathname, navigate]);

  return (
    <LayoutWrapper>
      <div className="mx-4">
        <div className="flex items-center gap-1">
          {isMobile && location.pathname !== '/settings' && (
            <Button
              size="icon"
              variant="ghost"
              className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0 -ml-1"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Unicons.UilArrowLeft className="text-[#2A324C]" />
            </Button>
          )}
          <Title title={getPageName(location.pathname, isMobile)} />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-4 mt-0 sm:mt-2">
          <div className="hidden sm:block">
            <SettingsLeftPanel />
          </div>
          <div className="col-span-3 mb-4">
            <Routes>
              <Route path="" element={<SettingsLeftPanel />} />
              <Route path="store" element={<SettingsStoreDetails data={data} loading={loading} />} />
              <Route path="payment" element={<SettingsPayment />} />
              <Route path="taxes" element={<TaxesAndDuties />} />
              <Route path="integrations/*" element={<IntegrationsMain />} />
              <Route path="change-password" element={<SettingPasswordDetails />} />
            </Routes>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};
