import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { translations } from '../login/i18n';
import { Formik, Form as FormikForm } from 'formik';
import { isValidEmail } from '../../utils/validation';
import { InputTextField } from '../../components/shared';
import { Button, Card, Label } from '../../components/flexyui';
import { forgotPassword } from '../../network/reset-password/reset-password';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';

type Props = {
  setForgotPasswordActive: (toggle: boolean) => void | null;
};

export const ForgotPassword: React.FC<Props> = ({ setForgotPasswordActive }) => {
  const [buttonText, setButtonText] = useState('Reset Password');

  const handleSubmit = async (email: string) => {
    if (isValidEmail(email)) {
      try {
        await forgotPassword(email);
        enqueueSnackbar('Reset password mail has been sent successfully.', {
          variant: 'success'
        });
        setButtonText('Resend Password Reset Mail');
      } catch (e) {
        enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
          variant: 'error'
        });
      }
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>, email: string, resetForm: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleSubmit(email);
      resetForm();
    }
  };

  const handleLogInClick = (): void => {
    setForgotPasswordActive(false);
  };

  return (
    <div className="px-4 flex flex-col items-center mt-[133px] h-screen">
      <Logo />
      <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05)] p-[26px] min-w-[390px] mt-14 autofill:bg-primary">
        <Formik
          initialValues={{
            email: ''
          }}
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values.email);
            resetForm();
          }}
        >
          {(props) => (
            <FormikForm>
              <h2 className="text-lg font-semibold mb-5">Recover Account</h2>
              <Label>{translations.EMAIL.value}</Label>
              <div className="mt-1 mb-4">
                <InputTextField
                  type="email"
                  name="email"
                  placeholder="johndoe@flexype.in"
                  handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleKeyDown(e, props.values.email, props.resetForm)
                  }
                />
              </div>

              <Button type="submit" disabled={props.values.email === '' || !isValidEmail(props.values.email)}>
                {buttonText}
              </Button>
            </FormikForm>
          )}
        </Formik>
        <div className="m-auto underline hover:no-underline w-fit mt-4 cursor-pointer" onClick={handleLogInClick}>
          Login?
        </div>
      </Card>
    </div>
  );
};
