/* eslint-disable camelcase */
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form as FormikForm } from 'formik';
import { Button, Card, Label, Skeleton } from '../../components/flexyui';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';
import { CredentialsInput } from '../../components/settings/credentials-input';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { ResetPasswordSchema } from '../../utils/validation-schemas/authentication';
import { resetPassword, validateResetPasswordToken } from '../../network/reset-password/reset-password';

type Props = {
  token: string;
};

interface ResetPasswordProps {
  token: string;
  new_password: string;
  confirm_new_password: string;
}

export const ResetPassword: React.FC<Props> = ({ token }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const validateResetPasswordTokenMutation = useMutation({
    mutationFn: validateResetPasswordToken,
    onMutate: () => setLoading(true),
    onSuccess: () => setTimeout(() => setLoading(false), 500),
    onError: () => {
      setTimeout(() => {
        enqueueSnackbar('Oops! The link has expired, please try again later.', {
          variant: 'error'
        });
        navigate('');
      }, 1500);
    }
  });

  useEffect(() => {
    validateResetPasswordTokenMutation.mutate(token);
  }, [token]);

  const resetPasswordMutation = useMutation({
    mutationFn: ({ token, new_password, confirm_new_password }: ResetPasswordProps) =>
      resetPassword(token, new_password, confirm_new_password),
    onSuccess: () => {
      enqueueSnackbar('Password changed successfully! Please login to continue', {
        variant: 'success'
      });
      navigate('');
    },
    onError: () => {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  });

  const handleSubmit = (values: ResetPasswordProps) => {
    resetPasswordMutation.mutate(values);
  };

  return (
    <div className="px-4 flex flex-col items-center justify-center h-[100dvh]">
      <Logo />
      <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05)] p-[26px] min-w-[350px] autofill:bg-primary mt-8 sm:mt-14">
        <h2 className="text-lg font-medium mb-5">Reset Password</h2>
        {loading ? (
          <>
            <Skeleton className="w-full h-[40px] !m-0 !mb-4" />
            <Skeleton className="w-full h-[40px] !m-0 !mb-4" />
            <Skeleton className="w-full h-[40px] !m-0 !mb-6" />
            <div className="flex justify-end">
              <Skeleton className="w-[30%] !m-0" />
            </div>
          </>
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={{
                token: token,
                new_password: '',
                confirm_new_password: ''
              }}
              onSubmit={handleSubmit}
              validationSchema={ResetPasswordSchema}
            >
              {({ errors, touched, getFieldProps }) => (
                <FormikForm>
                  <Label>New Password</Label>
                  <div className="mb-4">
                    <CredentialsInput
                      {...getFieldProps('new_password')}
                      error={touched.new_password && !!errors?.new_password}
                      errorMessage={errors?.new_password}
                    />
                  </div>
                  <Label>Confirm Password</Label>
                  <div className="mb-4">
                    <CredentialsInput
                      {...getFieldProps('confirm_new_password')}
                      error={touched.confirm_new_password && !!errors?.confirm_new_password}
                      errorMessage={errors?.confirm_new_password}
                    />
                  </div>
                  <Button type="submit" disabled={Object.keys(errors).length > 0}>
                    {resetPasswordMutation.isLoading ? (
                      <LoadingIcon height={16} className={classNames('-ml-1 mr-3 h-5 w-5 animate-spin text-white')} />
                    ) : (
                      'Reset Password'
                    )}
                  </Button>
                </FormikForm>
              )}
            </Formik>
            <div className="m-auto underline hover:no-underline w-fit mt-4 cursor-pointer" onClick={() => navigate('')}>
              Login?
            </div>
          </>
        )}
      </Card>
    </div>
  );
};
