/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { FC, useState } from 'react';
import { UilDraggabledots, UilEditAlt, UilEye, UilEyeSlash } from '@iconscout/react-unicons';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Button,
  Card,
  Label
} from '../../flexyui';
import { InputTextField } from '../../shared';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface CardProps {
  id: number;
  layout: string;
  index: number;
  cards?: any;
  visibility?: boolean;
  showAction?: boolean;
  handleEdit?: (value: any, type: number) => void;
  handleVisibility?: (value: any, type: number) => void;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
}

export const LayoutCard: FC<CardProps> = ({
  id,
  layout,
  index,
  showAction = true,
  handleVisibility,
  handleEdit,
  visibility
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: `${id}` });

  const [open, setOpen] = useState<boolean>(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '1000' : 'auto'
  };

  return (
    <>
      <Card
        ref={setNodeRef}
        style={style}
        className={classNames(
          'rounded-none border-white flex justify-between items-center px-3 pointer-events-none border border-b-border last:border-b-0',
          {
            'border-b-0': index === 3,
            'py-[14px]': showAction === false,
            'py-2': showAction === true
          }
        )}
        key={index}
      >
        <Label size={'paragraph'}>
          <UilDraggabledots
            {...listeners}
            {...attributes}
            color="rgba(136, 141, 155, 1)"
            className={classNames('inline-block mr-3 cursor-grab pointer-events-auto outline-none')}
          />
        </Label>
        <div className="w-full mr-1">
          <Label
            size={'paragraph'}
            className={classNames('text-left', {
              'opacity-25': visibility === false || layout?.trim() === ''
            })}
          >
            {layout?.trim() === '' ? 'Add banner text here' : layout}
          </Label>
        </div>
        {showAction ? (
          <div className="flex justify-around space-x-3">
            <Button
              variant={'icon'}
              className="p-[6px] pointer-events-auto"
              onClick={() => {
                if (handleVisibility !== undefined) {
                  handleVisibility(!visibility, id);
                }
              }}
            >
              {visibility ? <UilEye /> : <UilEyeSlash />}
            </Button>
            <Button variant={'icon'} className="p-[6px] pointer-events-auto" onClick={() => setOpen(true)}>
              <UilEditAlt />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Card>

      <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <Formik
          initialValues={{
            text: layout
          }}
          onSubmit={async (values, { resetForm }) => {
            handleEdit?.(values, id);
            resetForm();
          }}
          enableReinitialize
        >
          {(props) => (
            <Form>
              <DialogContent size="sm" close={true}>
                <DialogHeader>
                  <DialogTitle>Edit Banner</DialogTitle>
                  <div className="flex flex-col px-5">
                    <Label variant="default" size="paragraph" className="">
                      Banner
                    </Label>
                    <InputTextField name="text" type="text" key={layout} />
                  </div>
                </DialogHeader>
                <DialogFooter className="sm:justify-end">
                  <Button
                    variant="outline"
                    size="md"
                    onClick={() => {
                      setOpen(false);
                      props.resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    onClick={() => {
                      setOpen(false);
                      props.submitForm();
                    }}
                  >
                    Save
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
