import { Button } from '../flexyui';

const ErrorHandling = () => {
  return (
    <div className="min-h-[500px] flex justify-center items-center flex-col space-y-3">
      <div>Check your connection and Try again.</div>
      <div className="">
        <Button variant={'outline'} size={'md'} onClick={() => window.location.reload()}>
          Reload
        </Button>
      </div>
    </div>
  );
};

export default ErrorHandling;
