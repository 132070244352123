import React, { useState } from 'react';
import classNames from 'classnames';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Card, Skeleton } from '../../flexyui';
import { logEvent } from '../../../utils/log-event';
import { CredentialsInput } from '../credentials-input/index';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';
import { useSetCheckoutActivationMutation, useQueryQuery } from '../../../graphql';

type Props = {
  data: any;
  loading: boolean;
};

export const SettingsStoreDetails: React.FC<Props> = ({ data, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const [setCheckoutActivationMutation, { loading: loadingMutation }] = useSetCheckoutActivationMutation();
  const { data: activationStatus, loading: loadingActivation, refetch } = useQueryQuery();

  const handleConfirm = async () => {
    try {
      await setCheckoutActivationMutation({
        variables: {
          activation: !activationStatus?.checkoutActivation
        }
      });
      logEvent(activationStatus?.checkoutActivation ? 'Disable Activation' : 'Enable Activation');
    } catch (error) {
      logEvent('Checkout Activation Error', {
        error
      });
    }
    await refetch();
    setShowModal(false);
  };

  return (
    <>
      <Card className="h-fit p-4 mb-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)]">
        <div className="font-medium mb-4">Store</div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilStore size={14} />
            </div>
            <div className="flex-row">
              <div className="text-[#595F74] text-xs ">Store Name</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-[200%] h-[20px] !m-0" />
                ) : (
                  <p className="text-sm">{data?.profile?.shop_name}</p>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilLinkAdd size={14} />
            </div>
            <div className="flex-row">
              <div className="text-[#595F74] text-xs">Store URL</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-[200%] h-[20px] !m-0" />
                ) : (
                  <p className="text-sm">{data?.profile?.domains?.main_domain}</p>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilLink size={14} />
            </div>
            <div className="flex-row">
              <div className="text-[#595F74] text-xs">Shopify Domain Name</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-[200%] h-[20px] !m-0" />
                ) : (
                  <p className="text-sm">{data?.profile?.domains?.shopify_domain}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <div className="font-medium mb-4">Credentials</div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilKeySkeleton size={14} />
            </div>
            <div className="flex-row w-[calc(100%-50px)]">
              <div className="text-[#595F74] text-xs ">Admin API Access token</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-full h-[40px] !m-0 !mt-1" />
                ) : (
                  <CredentialsInput
                    value={data?.profile.credentials.access_token}
                    placeholder="credentials"
                    readOnly={true}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilKeySkeleton size={14} />
            </div>
            <div className="flex-row w-[calc(100%-50px)]">
              <div className="text-[#595F74] text-xs">Storefront API Access Token</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-full h-[40px] !m-0 !mt-1" />
                ) : (
                  <CredentialsInput
                    value={data?.profile?.credentials.storefront_access_token}
                    placeholder="credentials"
                    readOnly={true}
                  />
                )}
              </div>
            </div>
          </div>

          {/* <div className="flex items-start gap-2">*/}
          {/*  <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">*/}
          {/*    <Unicons.UilKeySkeleton size={14} />*/}
          {/*  </div>*/}
          {/*  <div className="flex-row w-[calc(100%-50px)]">*/}
          {/*    <div className="text-[#595F74] text-xs">Storefront Client Secret</div>*/}
          {/*    <div className="text-[#121B38]">*/}
          {/*      {loading ? (*/}
          {/*        <Skeleton className="w-full h-[40px] !m-0 !mt-1" />*/}
          {/*      ) : (*/}
          {/*        <CredentialsInput*/}
          {/*          value={data?.profile?.credentials.client_secret}*/}
          {/*          placeholder="credentials"*/}
          {/*          readOnly={true}*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/* </div>*/}
        </div>
      </Card>

      <Card className="h-fit px-4 pt-4 pb-6 mb-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)]">
        <div className="flex font-medium mb-4 items-center"> User</div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilUser size={14} />
            </div>
            <div className="flex-row">
              <div className="text-[#595F74] text-xs ">Name</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-[150px] h-[20px] !m-0" />
                ) : (
                  <p className="text-sm">{data?.profile?.user?.full_name}</p>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilEnvelope size={14} />
            </div>
            <div className="flex-row">
              <div className="text-[#595F74] text-xs">Email</div>
              <div className="text-[#121B38]">
                {loading ? (
                  <Skeleton className="w-[200px] h-[20px] !m-0" />
                ) : (
                  <p className="text-sm">{data?.profile?.user?.email}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="h-fit p-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)] border-[#FF0606]">
        <div className="block sm:flex justify-between items-center">
          <div className="bg-white rounded-[10px] space-y-3 text-sm">
            <div className="flex items-center justify-between">
              <div className="text-base font-semibold">Danger zone</div>
              <div className="block sm:hidden">
                {loadingActivation ? (
                  <Skeleton className="w-[100px] sm:w-[150px] h-[30px] !m-0" />
                ) : (
                  <Button
                    variant={activationStatus?.checkoutActivation ? 'outline' : 'default'}
                    size={'md'}
                    onClick={() => setShowModal(true)}
                    className={classNames('whitespace-nowrap', {
                      'border-[#FF0606] text-[#FF0606]': activationStatus?.checkoutActivation
                    })}
                  >
                    {activationStatus?.checkoutActivation ? 'Disable Flexype' : 'Enable Flexype'}
                  </Button>
                )}
              </div>
            </div>
            <div className="font-medium">Checkout Activation</div>
            <div className="block sm:flex justify-between items-center text-[#595F74] font-normal">
              <div className="max-w-[450px]">
                {loadingActivation ? (
                  <>
                    <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                    <Skeleton className="w-[250px] !m-0" />
                  </>
                ) : activationStatus?.checkoutActivation ? (
                  "This action will deactivate FlexyPe's checkout on the store and activate Shopify's default checkout."
                ) : (
                  "Activate FlexyPe's Checkout to convert more"
                )}
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            {loadingActivation ? (
              <Skeleton className="w-[150px] h-[30px] !m-0" />
            ) : (
              <Button
                variant={activationStatus?.checkoutActivation ? 'outline' : 'default'}
                size={'md'}
                onClick={() => setShowModal(true)}
                className={classNames('whitespace-nowrap', {
                  'border-[#FF0606] text-[#FF0606]': activationStatus?.checkoutActivation
                })}
              >
                {activationStatus?.checkoutActivation ? 'Disable Flexype' : 'Enable Flexype'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={handleConfirm}
        text={
          activationStatus?.checkoutActivation
            ? "This action will disable FlexyPe's Checkout. Are you sure you want to continue?"
            : "Enabling FlexyPe's one-click checkout experience"
        }
        loading={loadingMutation}
      />
    </>
  );
};
