import { RadioGroupItem } from '../../flexyui';
import React from 'react';

type Props = {
  onClick: () => void;
  value: string;
  label: string;
};
export const RadioWithLabel: React.FC<Props> = ({ onClick, value, label }) => {
  return (
    <div className="flex items-center space-x-2 mb-1.5 w-fit">
      <RadioGroupItem value={value} id={value} onClick={onClick} />
      <label className="text-black cursor-pointer" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};
