import React from 'react';
import classNames from 'classnames';
import { Button } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  value: any[];
  setFieldValue?: any;
  on?: string;
  onRemove?: (value: any) => void;
  singleSelect?: boolean;
};

const SelectedCollections = ({ value, setFieldValue, on, onRemove, singleSelect = false }: Props) => {
  const getBorderRadius = (index: number, length: number) => {
    if (length <= 1) {
      return 'border rounded-lg';
    }
    if (index === 0) {
      return 'border rounded-t-lg';
    }
    if (index === length - 1) {
      return 'border border-t-[0px] rounded-b-lg';
    }
    return 'border border-t-[0px]';
  };

  return (
    <div>
      {value &&
        value.length > 0 &&
        value?.map((item: any, index: number) => {
          return (
            <div
              className={classNames(
                'flex justify-center flex-col w-full pr-4 border-solid border-black-100',
                getBorderRadius(index, value.length ? value.length : -1)
              )}
            >
              <div className="flex this-testing items-center justify-between gap-2 border-black font-medium text-sm">
                <div className="pl-2.5 py-2.5">
                  <div className="flex items-center">
                    <div className="border rounded-sm border-black-200 border-solid p-1">
                      {!item?.image_src || item?.image_src === '' ? (
                        <Unicons.UilImage style={{ height: '1.5rem' }} />
                      ) : (
                        <img src={item?.image_src} style={{ height: '1.5rem' }} />
                      )}
                    </div>
                    <div className="flex flex-col ml-2">
                      <div className="font-normal">{item?.title}</div>
                      {item?.product_count ? (
                        <div className="text-xs font-normal text-[#a0a0a0] mt-[-2px]">
                          (contains {item.product_count} {item.product_count > 1 ? 'products' : 'product'})
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {!singleSelect && (
                  <Button
                    size="icon"
                    variant="ghost"
                    className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
                    onClick={() => {
                      if (onRemove)
                        onRemove(value?.filter((collection: any) => collection?.collection_id !== item?.collection_id));
                      else
                        setFieldValue(
                          on,
                          value?.filter((collection: any) => collection?.collection_id !== item?.collection_id)
                        );
                    }}
                  >
                    <Unicons.UilMultiply size={16} className="text-[#2A324C]" />
                  </Button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SelectedCollections;
