/* eslint-disable import/default */
import React, { useCallback, useState } from 'react';
import Charts from 'react-apexcharts';
import { useFunnelMetricsQuery, ComparableMetrics, useSendMetricsMailMutation, RequestType } from '../../../graphql';
import { convertAmount } from '../../../utils/convert-amount';
import { enqueueSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Skeleton,
  Card
} from '../../flexyui';
import { isValidEmail } from '../../../utils/validation';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';

const steps: any = {
  CHECKOUT: 'Checkout',
  LOGIN: 'Login',
  ADDRESS: 'Address',
  PAYMENT: 'Payment',
  ORDER: 'Orders'
};
type Props = {
  metricsData?: ComparableMetrics;
  loading: boolean;
  dateRange?: any;
};
export const ConversionFunnel: React.FC<Props> = ({ metricsData, dateRange, loading }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [sendMetricsMail] = useSendMetricsMailMutation();
  const { data: graphData } = useFunnelMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(dateRange.startDate).toISOString(),
        end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
      } // value for 'dateRange'
    }
  });

  const totalOrdersNumber = convertAmount(Number(metricsData?.current?.total_orders));
  const totalOrders = parseFloat(totalOrdersNumber.replace(/,/g, ''));

  const handleSendMetricsMail = async () => {
    try {
      await sendMetricsMail({
        variables: {
          dateRange: {
            start: new Date(dateRange.startDate).toISOString(),
            end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
          },
          requestType: RequestType.Funnel,
          receiverEmail: email
        }
      });
      enqueueSnackbar('Report sent successfully!', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const options: any = {
    chart: {
      // height: 350,
      animations: {
        enabled: false
      },
      type: 'area',
      offsetY: 10,
      stacked: false,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    tooltip: {
      enabled: false
    },
    markers: {
      enabled: false // Hide data points
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2,
      show: true,
      colors: ['#2F72FF'],
      point: {}
    },

    legend: {
      show: false
    },
    xaxis: {
      min: (val: any) => {
        return val.index;
      },
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    fill: {
      type: 'solid',
      colors: [
        'rgba(47, 114, 255, 0.222)',
        'rgba(47, 114, 255, 0.498)',
        'rgba(47, 114, 255, 0.664)',
        'rgba(47, 114, 255, 0.73)',
        'rgba(47, 114, 255, 1)'
      ]
    },
    yaxis: {
      show: false,
      min: 0
    },
    grid: {
      show: false
    }
  };

  const calculateData = useCallback(() => {
    const data = graphData?.funnelMetrics?.current?.[0];

    return {
      series: [
        {
          name: 'Checkout',
          data: [data?.CHECKOUT || 0, data?.LOGIN || 0],
          x: 0
        },
        {
          name: 'Login',
          data: [null, data?.LOGIN || 0, data?.ADDRESS || 0],
          x: 1
        },
        {
          name: 'Address',
          data: [null, null, data?.ADDRESS || 0, data?.PAYMENT || 0]
        },
        {
          name: 'Payment',
          data: [null, null, null, data?.PAYMENT || 0, totalOrders || 0]
        },
        // {
        //   name: 'Orders',
        //   data: [null, null, null, null, data?.ORDER || 0, data?.ORDER || 0]
        // },
        {
          name: 'Orders',
          data: [null, null, null, null, totalOrders || 0, totalOrders || 0]
        }
      ]
    };
  }, [graphData, metricsData]);

  return (
    <>
      <Card className="relative col-span-1 sm:col-span-2 min-h-[15rem] overflow-x-scroll sm:overflow-hidden scrollbar-hide">
        <div className="pt-4 px-4 flex items-center justify-between sticky left-0 sm:static">
          <div className="text-black font-medium flex items-center">
            <div>Sales Conversion Funnel</div>
            <ButtonWithInfo
              heading="Sales Conversion Funnel"
              description={
                <div>
                  <div className="mb-1">Representing the funnel from checkout to order.</div>
                  <div className="mb-1">
                    Click on <span className="text-[#2F72FF] italic">Get report</span> to download the complete analysis
                    including user information.
                  </div>
                  <div>
                    <span className="font-medium">Note:</span> Checkout is the number of clicks on checkout button while
                    others are the count of unique visitors after login.
                  </div>
                </div>
              }
              calculation={false}
            >
              <Unicons.UilInfoCircle size={15} className="ml-1.5" />
            </ButtonWithInfo>
          </div>
          {!loading && (
            <div
              className="text-[#2F72FF] flex items-center cursor-pointer font-medium px-3.5 py-1 rounded-2xl shadow-[1px_4px_20px_rgba(0,0,0,0.1)]"
              onClick={() => setShowModal(true)}
            >
              <Unicons.UilFileDownloadAlt size={18} />
              Get report
            </div>
          )}
        </div>

        <div className="w-[600px] sm:w-auto">
          <div className="flex justify-between font-medium border-dotted text-[rgba(45,45,45,0.5)] px-4 mt-4">
            {Object.entries(steps).map(([key, value]: any) => {
              const funnelMetrics = graphData?.funnelMetrics?.current?.[0];
              const funnelMetricValue = (funnelMetrics as any)?.[key as keyof string] || 0;
              return (
                <div key={key} className="flex flex-col pt-2">
                  {value}
                  <div className="font-bold text-3xl text-black pt-2">
                    {!loading ? (
                      graphData?.funnelMetrics?.current?.[0] && convertAmount(funnelMetricValue)
                    ) : (
                      <Skeleton size={'label'} className="w-full !m-0" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {loading ? (
            <div className="p-4">
              <Skeleton className="w-full !m-0 h-[100px]" />
            </div>
          ) : (
            <>
              {graphData?.funnelMetrics?.current?.[0] && graphData?.funnelMetrics?.current[0]?.CHECKOUT !== 0 ? (
                <Charts options={options} series={calculateData().series} type="area" height={200} />
              ) : (
                <div className="text-center my-20">
                  <div className="text-gray-300 text-base">Not Available</div>
                </div>
              )}
            </>
          )}
        </div>
      </Card>

      <Dialog open={showModal} onOpenChange={(value: boolean) => setShowModal(value)}>
        <DialogContent close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Download Report</p>
              </DialogTitle>
              <DialogDescription className="text-gray-600 !mt-4">
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="example@work.com"
                  className="mt-1"
                  value={email}
                  onChange={(e: any) => setEmail(e?.target?.value || '')}
                  onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      await handleSendMetricsMail();
                      setEmail('');
                      setShowModal(false);
                    }
                  }}
                />
                <div className="text-[rgb(89,95,116)] leading-4 text-sm mt-4">
                  The report will be send by email within a few minutes
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={() => {
                setEmail('');
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size={'md'}
              disabled={!isValidEmail(email)}
              onClick={async () => {
                await handleSendMetricsMail();
                setEmail('');
                setShowModal(false);
              }}
            >
              Send
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
