import { cn } from '../../lib/utils';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const LayoutWrapper = ({ children, className }: Props) => {
  return (
    <div className="min-w-full max-w-full w-full h-full flex justify-center">
      <div className={cn('max-w-[75rem] w-full', className)}>{children}</div>
    </div>
  );
};

export default LayoutWrapper;
