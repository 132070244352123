import React from 'react';
import { Field } from 'formik';
import { Input } from '../../flexyui';

type Props = {
  type: string;
  name: string;
  placeholder?: string;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  minPrice?: string | number;
  maxPrice?: string | number;
  defaultValue?: number | number;
  icon?: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
  iconPosition?: 'start' | 'end';
  metaTouched?: boolean;
};
export const InputTextField: React.FC<Props> = ({
  type = 'text',
  name,
  handleKeyDown,
  placeholder = '',
  disabled,
  minPrice,
  defaultValue,
  icon,
  error,
  errorMessage,
  iconPosition,
  metaTouched
}) => {
  return (
    <Field name={name} className="relative">
      {({ field, form, meta }: any) => (
        <>
          <Input
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            type={type}
            min={minPrice}
            defaultValue={defaultValue}
            icon={icon}
            error={metaTouched ? meta.touched && error : error}
            iconPosition={iconPosition}
            onInput={() => form.setFieldTouched(field.name, true)}
            {...field}
          />
          {error && !!errorMessage && <p className="text-[#cf3c3c] text-xs mt-0.5 ml-0.5">{errorMessage}</p>}
        </>
      )}
    </Field>
  );
};
