import React from 'react';
import { CouponPerformanceMetrics } from '../../../graphql';

type CardProps = {
  couponPerformanceWithCoupon?: CouponPerformanceMetrics['with_coupon'];
  couponPerformanceWithOutCoupon?: CouponPerformanceMetrics['without_coupon'];
  withCoupon: boolean;
};

export const CouponMetricCard: React.FC<CardProps> = ({
  couponPerformanceWithCoupon,
  couponPerformanceWithOutCoupon,
  withCoupon
}) => {
  return (
    <div className={`p-2 ${withCoupon ? 'border-2 bg-[#F9F9F9] rounded-lg' : 'mt-4'}`}>
      <div className="font-medium border-dotted text-black border-b-2 mb-2">
        {withCoupon ? 'With' : 'Without'} Coupons:
      </div>
      <div className="flex items-center">
        <div className="w-[40%]">
          <div className="text-[rgba(45,45,45,0.5)] leading-[16px]">Conversions</div>
          <div className="text-[#121B38] font-bold text-2xl">
            {withCoupon
              ? (couponPerformanceWithCoupon?.percentage || 0).toFixed(2)
              : (couponPerformanceWithOutCoupon?.percentage || 0).toFixed(2)}
            %
          </div>
        </div>
        <div className="w-[30%]">
          <div className="text-[rgba(45,45,45,0.5)]">Orders</div>
          <div className="text-[#121B38] font-bold text-2xl">
            {withCoupon
              ? couponPerformanceWithCoupon?.order_with_coupon
              : couponPerformanceWithOutCoupon?.order_without_coupon}
          </div>
        </div>
        <div className="w-[30%]">
          <div className="text-[rgba(45,45,45,0.5)]">Sessions</div>
          <div className="text-[#121B38] font-bold text-2xl">
            {' '}
            {withCoupon ? couponPerformanceWithCoupon?.sessions : couponPerformanceWithOutCoupon?.sessions}
          </div>
        </div>
      </div>
    </div>
  );
};
