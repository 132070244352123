'use client';

import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as SeparatorPrimitive from '@radix-ui/react-separator';

import { cn } from '../../../lib/utils';

type SeparatorProps = React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & {
  lineStyle?: 'solid' | 'dotted' | 'dashed';
};

const Separator = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, SeparatorProps>(
  ({ className, orientation = 'horizontal', decorative = true, lineStyle = 'solid', ...props }, ref) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        'shrink-0 border-border',
        orientation === 'horizontal' ? 'h-[1px] w-full border-t my-5' : 'h-full w-[1px] border-r mx-3',
        lineStyle === 'dotted' ? 'border-dotted' : lineStyle === 'dashed' ? 'border-dashed' : 'border-solid',
        className
      )}
      {...props}
    />
  )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
