import React, { useState } from 'react';
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons';
import { Field } from 'formik';
import { Input } from '../../flexyui';

type Props = {
  type: string;
  name: string;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};
export const PasswordTextfield: React.FC<Props> = ({ type = 'password', name, handleKeyDown }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <div className="relative">
      <Field name={name}>
        {({ field }: any) => (
          <Input
            onKeyDown={handleKeyDown}
            type={showPassword ? 'text' : type}
            icon={showPassword ? <UilEyeSlash onClick={handleShowPassword} /> : <UilEye onClick={handleShowPassword} />}
            iconPosition="end"
            {...field}
          />
        )}
      </Field>
    </div>
  );
};
