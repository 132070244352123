/* eslint-disable import/default */
import React from 'react';
import { PaymentMetricsQuery } from '../../../graphql';
import { Select as SelectCN, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../flexyui/Select/index';
import { Skeleton, Card } from '../../flexyui';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';

type ProgressMetricProps = {
  progress: number;
  title: string;
};
const ProgressMetric: React.FC<ProgressMetricProps> = ({ progress, title }) => {
  const mappedProgress = (progress / 100) * 90 + 10;

  return (
    <div className="w-full relative mb-0.5">
      <div
        className="h-[30px] bg-[#2f72ff] rounded-[3px]"
        style={{
          width: `${mappedProgress}%`,
          opacity: `${mappedProgress / 100}`
        }}
      />
      <div className="absolute top-0 left-0 h-[30px] flex items-center pl-4">
        <div
          className="bg-white text-[#2f72ff]
                text-center py-[1px] px-2.5 rounded-2xl
                text-[11px] font-semibold flex items-center
                shadow-[0px_4px_4px_0px_rgba(0,0,0,0.05)]"
        >
          <div>{Math.round(progress)}%</div>
          <div className="w-[3px] h-[3px] rounded-[50%] bg-[#2f72ff] mx-1" />
          <div>{title}</div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  paymentGraphData?: PaymentMetricsQuery;
  loading: boolean;
};
export const PaymentSuccessRates: React.FC<Props> = ({ paymentGraphData, loading }) => {
  const data = (paymentGraphData?.paymentMetrics?.current || {}) as Record<string, any>;
  const excludedProperties = ['start', 'end', 'cod', 'partial_cod'];

  const paymentModesArray = Object.keys(data)
    .filter((key) => !excludedProperties.includes(key))
    .map((key: string) => ({
      ...data[key],
      success_rate: data[key]?.success_rate || 0,
      type:
        key === 'upi' || key === 'emi'
          ? key.toUpperCase()
          : key.toString().charAt(0).toUpperCase() + key.toString().slice(1)
    }))
    .sort((a, b) => b.success_rate - a.success_rate);

  return (
    <Card>
      <div className="font-medium text-black pt-4 px-4 mb-4 flex items-center">
        <div>Payment Gateway Success Rate</div>
        <ButtonWithInfo
          heading="Payment Gateway Success Rate"
          description="The percentage of successful transactions through the payment gateway and payment methods."
          calculation={false}
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      <SelectCN
        value={'All'}
        defaultValue={'All'}
        // onValueChange={(value) => {
        //   handlePaymentChange(value, 'filter');
        // }}
      >
        <SelectTrigger className="h-8 w-[8rem] ml-4 my-2">
          <SelectValue placeholder={'Hello'} />
        </SelectTrigger>
        <SelectContent side="bottom">
          <SelectItem value={'All'}>All</SelectItem>
        </SelectContent>
      </SelectCN>
      {loading ? (
        <div className="p-4">
          <Skeleton className="w-full !m-0 h-[100px]" />
        </div>
      ) : (
        <>
          {paymentGraphData?.paymentMetrics?.current ? (
            <div className="px-4 mt-4 mb-2">
              {paymentModesArray.map((item) => (
                <ProgressMetric key={item.type} progress={item.success_rate} title={item.type} />
              ))}
              <div className="w-full flex items-center justify-between mt-2">
                <div className="text-[rgba(45,45,45,0.5)] text-xs font-semibold">0%</div>
                <div className="text-[rgba(45,45,45,0.5)] text-xs font-semibold">100%</div>
              </div>
            </div>
          ) : (
            <div className="text-center my-20">
              <div className="text-gray-300 text-base">Not Available</div>
            </div>
          )}
        </>
      )}
    </Card>
  );
};
