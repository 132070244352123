import React, { ReactNode } from 'react';

type PaymentItemProps = {
  children: ReactNode;
};

const MethodLayoutCard: React.FC<PaymentItemProps> = ({ children }) => {
  return (
    <div className="group flex items-center justify-between py-3 pl-2 pr-4 border border-border w-full sm:w-[392px] rounded-2xl mt-2">
      {children}
    </div>
  );
};

export default MethodLayoutCard;
