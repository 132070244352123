import { AxiosResponse } from 'axios';
import { dashboardAPI } from '..';

export const pinCodesUpload = async (data: FormData): Promise<AxiosResponse<any>> => {
  return dashboardAPI.post('/v1/dashboard/upload-resource', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
