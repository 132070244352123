import { AxiosResponse } from 'axios';
import { dashboardAPI } from '..';
import { Response } from './types';
import { FileData } from '@/src/components/customization/customization-tabs/general-tab';

export const imageUpload = async (data: FileData): Promise<AxiosResponse<Response>> => {
  return dashboardAPI.post('/v1/dashboard/customisations/upload-logo', data, {
    maxBodyLength: Infinity
  });
};
export const customisationToken = async (): Promise<AxiosResponse<Response>> => {
  return dashboardAPI.get('/v1/dashboard/customisations/token');
};
