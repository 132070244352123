/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';

export const paymentOfferDataHandler = (values: any) => {
  const body = { ...values };
  const paymentOffers: any = {};
  if (body.apply_discount_to === 'ALL_PAYMENT_MODE') {
    paymentOffers.ALL = {
      active: true,
      amount: body.discount_amount,
      discount_max_cap: body.show_discount_max_cap !== false ? body?.discount_max_cap : 0
    };
    paymentOffers.UPI = null;
    paymentOffers.CARD = null;
    paymentOffers.EMI = null;
    paymentOffers.WALLET = null;
    paymentOffers.PAY_LATER = null;
    paymentOffers.NET_BANKING = null;
    paymentOffers.COD = null;
  } else if (body.apply_discount_to === 'SPECIFIC_PAYMENT_MODE') {
    paymentOffers.UPI = null;
    paymentOffers.CARD = null;
    paymentOffers.EMI = null;
    paymentOffers.WALLET = null;
    paymentOffers.PAY_LATER = null;
    paymentOffers.NET_BANKING = null;
    paymentOffers.COD = null;
    paymentOffers.ALL = null;
    if (body.payment_offers.upi.active) {
      paymentOffers.UPI = {
        active: body.payment_offers.upi.active,
        amount: body.payment_offers.upi.amount,
        discount_max_cap:
          body.payment_offers.upi.show_discount_max_cap !== false ? body?.payment_offers.upi.discount_max_cap : 0
      };
    }
    if (body.payment_offers.emi.active) {
      paymentOffers.EMI = {
        active: body.payment_offers.emi.active,
        amount: body.payment_offers.emi.amount,
        discount_max_cap:
          body.payment_offers.emi.show_discount_max_cap !== false ? body?.payment_offers.emi.discount_max_cap : 0
      };
    }
    if (body.payment_offers.cards.active) {
      paymentOffers.CARD = {
        active: body.payment_offers.cards.active,
        amount: body.payment_offers.cards.amount,
        discount_max_cap:
          body.payment_offers.cards.show_discount_max_cap !== false ? body?.payment_offers.cards.discount_max_cap : 0
      };
    }
    if (body.payment_offers.wallets.active) {
      paymentOffers.WALLET = {
        active: body.payment_offers.wallets.active,
        amount: body.payment_offers.wallets.amount,
        discount_max_cap:
          body.payment_offers.wallets.show_discount_max_cap !== false
            ? body?.payment_offers.wallets.discount_max_cap
            : 0
      };
    }
    if (body.payment_offers.pay_later.active) {
      paymentOffers.PAY_LATER = {
        active: body.payment_offers.pay_later.active,
        amount: body.payment_offers.pay_later.amount,
        discount_max_cap:
          body.payment_offers.pay_later.show_discount_max_cap !== false
            ? body?.payment_offers.pay_later.discount_max_cap
            : 0
      };
    }
    if (body.payment_offers.net_banking.active) {
      paymentOffers.NET_BANKING = {
        active: body.payment_offers.net_banking.active,
        amount: body.payment_offers.net_banking.amount,
        discount_max_cap:
          body.payment_offers.net_banking.show_discount_max_cap !== false
            ? body?.payment_offers.net_banking.discount_max_cap
            : 0
      };
    }
  }
  body.payment_offers = paymentOffers;

  if (body?.purchase_requirement?.show_max_amount === false) {
    body.purchase_requirement.max_amount = null;
  }
  if (body?.application?.type === 'UNIVERSAL') {
    delete body.application.products;
  }
  if (body?.application?.type === 'PRODUCTS') {
    const products = [...body.application.products];
    body.application.products = products.map((product) => {
      return {
        ...product,
        product_id: Number(product.product_id),
        variants: product.variants
          ? product.variants.map((variant: any) => {
              return { ...variant, variant_id: Number(variant.variant_id) };
            })
          : []
      };
    });
    let variantList: Array<any> = [];
    for (let i = 0; i < products.length; i += 1) {
      variantList = [
        ...variantList,
        ...products[i].variants.map((variant: any) => {
          return { product_id: products[i].product_id, variant_id: variant.variant_id };
        })
      ];
    }
    body.application.products = variantList;
  }

  if (body?.purchase_requirement?.type === 'NONE') {
    delete body.purchase_requirement.products;
  } else {
    const products = [...body.purchase_requirement.products];
    let variants: any = [];
    if (typeof products[0] !== 'string' && typeof products[0] !== 'number') {
      for (let i = 0; i < products.length; i += 1) {
        variants = [
          ...variants,
          ...products[i].variants.map((item: any) => {
            return { product_id: products[i].product_id, variant_id: item.variant_id };
          })
        ];
      }
    }
    body.purchase_requirement.products = variants;
  }

  if (body?.purchase_requirement?.min_quantity === '') {
    body.purchase_requirement.min_quantity = null;
  }
  if (body?.purchase_requirement?.max_quantity === '') {
    body.purchase_requirement.max_quantity = null;
  }
  if (body?.purchase_requirement?.min_amount === '') {
    body.purchase_requirement.min_amount = null;
  }
  if (body?.purchase_requirement?.max_quantity === '') {
    body.purchase_requirement.max_quantity = null;
  }
  if (body?.purchase_requirement?.max_quantity_needed === false) {
    body.purchase_requirement.max_quantity = null;
  }

  if (body?.purchase_requirement?.show_max_quantity === false) {
    body.purchase_requirement.max_quantity = null;
  }

  if (body.per_customer_usage_limit !== '' && body.show_per_customer_usage_limit) {
    body.per_customer_usage_limit = Number(parseInt(body.per_customer_usage_limit, 10));
  }
  if (body.show_total_usage_limit) {
    body.total_usage_limit = Number(parseInt(body.total_usage_limit, 10));
  }
  if (body.show_total_usage_limit === false) {
    body.total_usage_limit = null;
  }

  if (body.show_per_customer_usage_limit === false) {
    body.per_customer_usage_limit = null;
  }

  if (body?.user_order_requirement?.type === 'all') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = null;
    delete body.user_order_requirement?.type;
  }
  if (body?.user_order_requirement?.type === 'first') {
    body.user_order_requirement.min_order_count = null;
    body.user_order_requirement.max_order_count = 0;
    delete body?.user_order_requirement?.type;
  }
  if (body?.user_order_requirement?.type === 'order') {
    delete body?.user_order_requirement?.type;
    body.user_order_requirement.max_order_count = body.user_order_requirement.max_order_count_needed
      ? body.user_order_requirement.max_order_count
      : null;
  }

  if (body?.activation_period?.start === null) {
    body.activation_period.start = dayjs(new Date()).toISOString();
  } else body.activation_period.start = dayjs(body.activation_period.start).toISOString();

  if (body?.activation_period?.has_endTime) {
    if (body?.activation_period?.end === null) body.activation_period.end = dayjs(new Date()).toISOString();
    else body.activation_period.end = dayjs(body.activation_period.end).toISOString();
  } else body.activation_period.end = null;

  if (body?.payment_offers?.cards) delete body.payment_offers.cards.show_discount_max_cap;
  if (body?.payment_offers?.emi) delete body.payment_offers.emi.show_discount_max_cap;
  if (body?.payment_offers?.net_banking) delete body.payment_offers.net_banking.show_discount_max_cap;
  if (body?.payment_offers?.pay_later) delete body.payment_offers.pay_later.show_discount_max_cap;
  if (body?.payment_offers?.upi) delete body.payment_offers.upi.show_discount_max_cap;
  if (body?.payment_offers?.wallets) delete body.payment_offers.wallets.show_discount_max_cap;

  delete body?.activation_period.has_endTime;
  delete body.show_discount_max_cap;
  delete body?.user_order_requirement?.max_order_count_needed;
  delete body?.purchase_requirement?.max_quantity_needed;
  delete body?.purchase_requirement.show_max_amount;
  delete body?.purchase_requirement.show_max_quantity;
  delete body?.show_per_customer_usage_limit;
  delete body?.show_total_usage_limit;
  delete body.apply_discount_to;
  delete body.coupon_type;
  delete body.discount_amount;
  delete body.discount_max_cap;
  delete body?.purchase_requirement.productType;
  // this needs to have combine but deleting now for testing
  delete body.combine;

  return body;
};
