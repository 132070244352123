import React from 'react';
import classNames from 'classnames';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { flexRender } from '@tanstack/react-table';
import { TableCell, TableRow } from '../../flexyui';
import { UilDraggabledots } from '@iconscout/react-unicons';

export const DataTableRowDnd = ({ row, onRowClick }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: row.original.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1
  };

  return (
    <TableRow
      style={style}
      ref={setNodeRef}
      id={row.original.id}
      key={row.original.id}
      className="cursor-pointer relative last-of-type:border-b-0"
      data-state={row.getIsSelected() && 'selected'}
      onClick={() => onRowClick && onRowClick(row.original)}
    >
      {row.getVisibleCells().map((cell: any, index: number) => (
        <TableCell
          key={cell.id}
          className={classNames('border-l border-gray-100', {
            'border-l-0': index === 0,
            'flex items-center pt-1': index === 0
          })}
        >
          {index === 0 && (
            <div
              className="text-[#888d9b] pointer-events-auto cursor-grab flex justify-center rounded-full m-2 p-1 items-center hover:text-black z-[50] bg-gray-100 h-6 w-6"
              {...listeners}
              {...attributes}
            >
              <UilDraggabledots size={15} className="inline-block" />
            </div>
          )}
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );
};
