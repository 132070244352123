'use client';

import * as React from 'react';
import { add, format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';

import { cn } from '../../../lib/utils';
import { Button } from '../Button';
import { Calendar } from '../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { TimePicker12Demo } from './time-picker-12h-demo';
import { useEffect, useState } from 'react';

interface DateTimePickerProps {
  initialDate: Date;
  onDateChange: (date: string) => void;
  error?: boolean;
  errorMessage?: string;
}

export function DateTimePicker({ initialDate, onDateChange, error, errorMessage }: DateTimePickerProps) {
  const [date, setDate] = useState<Date | undefined>(initialDate);

  const handleSelect = (newDay: Date | undefined) => {
    if (!newDay) return;
    if (!date) {
      setDate(newDay);
      return;
    }
    const diff = newDay.getTime() - date.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24);
    const newDateFull = add(date, { days: Math.ceil(diffInDays) });
    setDate(newDateFull);
  };

  useEffect(() => {
    if (date) {
      const formattedDate = date.toISOString();
      onDateChange(formattedDate);
    }
  }, [date]);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn('px-3 justify-between', {
              'text-muted-foreground': !date,
              'border-2 border-[#E50000] ring-0 ring-offset-[#cf3c3c] outline-none focus-visible:ring-offset-0 focus-visible:ring-0':
                error
            })}
          >
            {date ? format(date, 'do MMM yyyy, h:mm a') : <span>Pick a date & time</span>}
            <CalendarIcon className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 shadow-lg">
          <Calendar mode="single" selected={date} onSelect={(d) => handleSelect(d)} initialFocus defaultMonth={date} />
          <div className="p-3 border-t border-border">
            <TimePicker12Demo setDate={setDate} date={date} />
          </div>
        </PopoverContent>
      </Popover>
      {error && !!errorMessage && <p className="text-[#cf3c3c] text-xs mt-0.5 ml-0.5">{errorMessage}</p>}
    </>
  );
}
