import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import * as Unicons from '@iconscout/react-unicons';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import { GoogleAdsConfig, useAdsAnalyticsConfigQuery, useUpdateGoogleAdsConfigMutation } from '../../../../graphql';
import {
  Button,
  Card,
  Chip,
  Input,
  Label,
  Switch,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Skeleton
} from '../../../flexyui';
import { useNavigate } from 'react-router-dom';

export const GoogleAds: React.FC = () => {
  const navigate = useNavigate();
  const [GAEnabled, setGAEnabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTurnOn, setShowTurnOn] = useState(false);
  const [credentials, setCredentials] = useState<GoogleAdsConfig[]>([]);

  const { data: integrationData, loading, refetch } = useAdsAnalyticsConfigQuery();
  const [updateGoogleAds, { loading: googleAdsLoading }] = useUpdateGoogleAdsConfigMutation();
  
  const handleFormSubmitButtonClick = async () => {
    setOpenModal(false);

    try {
      await updateGoogleAds({
        variables: {
          configs: {
            enabled: GAEnabled,
            conversion_id: credentials[0]?.conversion_id?.trim(),
            conversion_label: credentials[0]?.conversion_label.trim(),
            enhanced_conversion: credentials[0]?.enhanced_conversion || false
          }
        }
      });
      await refetch();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    setGAEnabled(true);
    setCredentials(integrationData?.analyticsConfig.google_ads || []);
  }, [integrationData]);

  const handleConversionIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = [...credentials];
    newValues[0] = { ...newValues[0], conversion_id: e.target.value };
    setCredentials(newValues);
  }

  const handleConversionApiTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = [...credentials];
    const conversionLabel = e.target.value;

    newValues[0] = { ...newValues[0], 
    conversion_label: conversionLabel,
    enhanced_conversion: conversionLabel.trim() !=='' ? true : 
    newValues[0]?.enhanced_conversion };

    setCredentials(newValues);
  }

  const handleConversionApiStatus = (value: boolean) => {
    const newValues = [...credentials];
    newValues[0] = { ...newValues[0], enhanced_conversion: value };
    setCredentials(newValues);
  }

  const handleCancelClick = () => {
    setOpenModal(false);
    setCredentials(integrationData?.analyticsConfig.google_ads || []);
  }

  return (
    <>
      <div className="items-center font-semibold text-lg my-2 hidden sm:flex">
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
          onClick={() => navigate('/settings/integrations')}
        >
          <Unicons.UilArrowLeft className="text-[#2A324C]" />
        </Button>
        <div className="ml-1.5">Google Ads</div>
      </div>

      <Card className="p-3 pl-4">
        <div className="flex items-center">
          Enable Google Ads conversions
          <div className="mx-2 hidden sm:block">
            {loading ? (
              <Skeleton variant="circular" className="!m-0 w-[70px]" />
            ) : (
              <Chip
                text={integrationData?.analyticsConfig?.google_ads[0]?.enabled ? 'Enabled' : 'Disabled'}
                variant={integrationData?.analyticsConfig?.google_ads[0]?.enabled ? 'success' : 'draft'}
              />
            )}
          </div>
          <div className="flex items-center justify-end flex-1">
            {loading ? (
              <Skeleton className="w-[95px] h-[36px] !m-0" />
            ) : (
              <Button
                size={'md'}
                onClick={async () => {
                  setShowTurnOn(true);
                }}
                variant={integrationData?.analyticsConfig?.google_ads[0]?.enabled ? 'outline' : 'default'}
                disabled={
                  integrationData?.analyticsConfig.google_ads.length === 0 ||
                  !integrationData?.analyticsConfig.google_ads[0]?.conversion_id?.trim() ||
                  !integrationData?.analyticsConfig.google_ads[0]?.conversion_label?.trim()
                }
              >
                {integrationData?.analyticsConfig?.google_ads[0]?.enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <Card className="mt-4 p-4">
        <Label size={'paragraph'}>Conversion Actions</Label>
        <div className="border border-muted px-4 py-4 mt-2 rounded-[6px]">
          <div className="add-new-pixel">
            {loading ? (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Skeleton className="!m-0 h-5 w-5" />
                    <Skeleton className="w-[150px] !m-0" />
                  </div>
                  <div className="flex items-center gap-2">
                    <Skeleton className="!m-0 h-8 w-8" />
                    <Skeleton className="!m-0 h-8 w-8" />
                  </div>
                </div>
                <div className="mt-4">
                  <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                  <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
                </div>
              </>
            ) : (
              <>
                {integrationData?.analyticsConfig?.google_ads &&
                integrationData?.analyticsConfig?.google_ads.length === 0 ? (
                  <div className="text-center my-4">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <Unicons.UilPlus size={18} className="mr-2" />
                      Add new conversion action
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className="pixel-id-container my-2">
                      <div className="text-[#888D9B] text-xs">
                        <div className="flex items-center justify-end">
                          <div className="flex items-center">
                            <Button
                              size="icon"
                              variant="icon"
                              onClick={() => {
                                setOpenModal(true);
                              }}
                              className="mr-2"
                            >
                              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74]" />
                            </Button>
                            <Button
                              size="icon"
                              variant="icon"
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="text-[#595F74] hover:text-red-500"
                            >
                              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
                            </Button>
                          </div>
                        </div>
                        <div className="pixel-id-title font-normal text-[#595F74]">Conversions ID</div>
                        <div className="flex justify-start text-sm text-[#121B38] font-normal">
                          {integrationData?.analyticsConfig?.google_ads[0]?.conversion_id}
                        </div>
                        <div className="pixel-id-title mt-3 font-normal text-[#595F74]">Conversions Label</div>
                        <div className="max-w-[80%] mt-0.5">
                          <p className="flex justify-start text-sm text-[#121B38] font-normal break-all">
                            {integrationData?.analyticsConfig?.google_ads[0]?.conversion_label}
                          </p>
                        </div>
                        <div className="pixel-id-title mt-3 font-normal text-[#595F74]">Enhanced Conversion</div>
                        <div className="mt-1">
                          <Chip
                            text={
                              integrationData?.analyticsConfig?.google_ads[0]?.enhanced_conversion
                                ? 'Enabled'
                                : 'Disabled'
                            }
                            variant={
                              integrationData?.analyticsConfig?.google_ads[0]?.enhanced_conversion ? 'success' : 'draft'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Card>

      <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Credentials</p>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0">
                <div className="my-2 px-4">
                  <Label size={'paragraph'}>Conversion Id</Label>
                  <Input
                    placeholder="Conversion Id"
                    type="text"
                    name="Conversion Id"
                    value={credentials[0]?.conversion_id || ''}
                    onChange={handleConversionIdChange}
                    className="text-[#121b38]"
                  />
                </div>

                <div className="my-4 mt-2 px-4">
                  <Label size={'paragraph'}>Conversions API token</Label>
                  <Input
                    placeholder="Conversion Label"
                    type="text"
                    name="Conversion Label"
                    value={credentials[0]?.conversion_label || ''}
                    onChange={handleConversionApiTokenChange}
                    className="text-[#121b38]"
                  />
                </div>

                <div className="my-4 mt-2 px-4 flex justify-between items-center">
                  <Label size={'paragraph'}>Conversion API Status</Label>
                  <Switch
                    checked={credentials[0]?.enhanced_conversion}
                    className="pointer-events-auto mr-1"
                    id={'enhanced_conversion'}
                    onCheckedChange={handleConversionApiStatus}
                  />
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              disabled={
                credentials[0]?.conversion_id === '' ||
                credentials[0]?.conversion_id === null ||
                credentials[0]?.conversion_id === undefined ||
                credentials[0]?.conversion_label === '' ||
                credentials[0]?.conversion_label === null ||
                credentials[0]?.conversion_label === undefined
              }
              onClick={() => handleFormSubmitButtonClick()}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={async () => {
          await updateGoogleAds({ variables: { configs: [] } });
          await refetch();
        }}
        text="This Action will delete the Google Ads"
        loading={googleAdsLoading}
        confirmActionVariant="destructive"
      />

      <ConfirmationDialog
        showModal={showTurnOn}
        setShowModal={setShowTurnOn}
        onSave={async () => {
          try {
            updateGoogleAds({
              variables: {
                configs: {
                  enabled: !integrationData?.analyticsConfig?.google_ads[0]?.enabled,
                  conversion_id: credentials[0]?.conversion_id?.trim() || '',
                  conversion_label: credentials[0]?.conversion_label?.trim() || '',
                  enhanced_conversion: credentials[0]?.enhanced_conversion
                }
              }
            });
          } catch (error) {
            enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
              variant: 'error'
            });
          }

          await refetch();
          setGAEnabled(integrationData?.analyticsConfig?.google_ads[0]?.enabled || false);
        }}
        text={`This Action will ${
          integrationData?.analyticsConfig.google_ads[0]?.enabled ? 'disable' : 'enable'
        } the Google Ads`}
        loading={googleAdsLoading}
      />
    </>
  );
};
