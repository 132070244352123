'use client';

import * as React from 'react';
import { Field } from 'formik';
import { Checkbox } from '../../flexyui';

// eslint-disable-next-line max-len
const CheckboxInput = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  ({ className, name, ...props }) => {
    return (
      <Field name={name} className={className}>
        {({ field }: any) => <Checkbox {...field} {...props} />}
      </Field>
    );
  }
);
CheckboxInput.displayName = 'CheckboxInput';

export { CheckboxInput };
