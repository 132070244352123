/* eslint-disable no-use-before-define */
/* eslint-disable prefer-arrow-callback */
import React, { useCallback, useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ColumnDef } from '@tanstack/react-table';
import {
  Button,
  Checkbox,
  Chip,
  DataTable,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Switch
} from '../../components/flexyui';
import { Title } from '../../components/shared/dashboard-title/title';
import {
  ApplicationType,
  CouponType,
  useDeleteCouponMutation,
  useGetCouponsLazyQuery,
  useUpdateCouponMutation
} from '../../graphql';
import { CreateDiscount } from '../../components/coupons/create-discount/create-discount';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { CreateDiscountPreview } from '../../components/coupons/create-discount/coupons-preview';
import { DataTableColumnHeader } from '../../components/flexyui/DataTable/column-header';
import { formatIndianRupees } from '../../utils/format-currency';
import { CouponChip } from '../../components/coupons/coupon-chip/coupon-chip';
import { enqueueSnackbar } from 'notistack';
import { getCollectionSelection } from '../../utils/coupons/coupon-data-handler';
import debounce from 'debounce';

import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '../../components/flexyui/Drawer';
import { addCouponsData } from '../../constants/coupons/coupons';

export const Coupons = () => {
  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'code',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader className="" column={column} title="Code" />,
      cell: ({ row }) => {
        return (
          <div className="py-2 px-3 flex items-center font-semibold" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.code} <CouponChip data={row} />
          </div>
        );
      }
    },
    {
      accessorKey: 'orders',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Orders" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="text-center py-2 px-3" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.orders}
          </div>
        );
      }
    },
    {
      accessorKey: 'gross-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Gross Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex py-2 px-3 justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['gross-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'discount-amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Discount Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit py-2 px-3 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['discount-amount']}
          </div>
        );
      }
    },
    {
      accessorKey: 'net-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Net Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit py-2 px-3 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['net-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Visibility" className="flex justify-center" />
      ),
      id: 'visibility',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center">
            {row.original.row?.application_type !== ApplicationType.Automatic ? (
              <Checkbox
                disabled={row.original.row?.application_type === ApplicationType.Automatic}
                checked={
                  row.original.row?.application_type === ApplicationType.Automatic
                    ? false
                    : row.original.visibilityLoading
                      ? !row.original.row?.discoverable
                      : row.original.row?.discoverable
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                onChange={async () => {
                  await handleActiveStatus(row.original.row, 'visibility', row.original.id);
                }}
              />
            ) : (
              <> - </>
            )}
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Active" className="flex justify-center" />,
      id: 'Active',
      cell: ({ row }) => {
        return (
          <div className="flex py-2 px-3 justify-center items-center">
            <Switch
              checked={row?.original.activeLoading ? !row?.original.active : row?.original.active}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onCheckedChange={() => handleActiveStatus(row?.original.row, 'active', row.original.id)}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Action" className="flex justify-center" />,
      id: 'Action',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1.5 px-3">
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setCouponToEdit(row?.original.row);
                setEditPopup(true);
                setShowCreateDiscount(true);
                setShowModal(false);
              }}
              className="mr-2"
            >
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74] cursor-pointer" />
            </Button>
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setShowModal(true);
                setShowDetailsModal(false);
                setShowCreateDiscount(false);
                setMethodToDelete(row?.original?.id || '');
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];
  const [showModal, setShowModal] = useState<boolean>(false);
  const [methodToDelete, setMethodToDelete] = useState<string>('');
  const [couponToAdd, setCouponToAdd] = useState<string>('');
  const [couponToEdit, setCouponToEdit] = useState<any>();
  const [addPopup, setAddPopup] = useState<boolean>(false);
  const [editPopup, setEditPopup] = useState<boolean>(false);
  const [showCreateDiscount, setShowCreateDiscount] = useState<boolean>(false);

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [rows, setRows] = useState<any>(null);

  const [searchedValue, setSearchedValue] = useState({
    input: '',
    skip: 0,
    limit: 10
  });

  const [getCoupons, { data: couponData, loading, refetch }] = useGetCouponsLazyQuery({
    variables: {
      code: '',
      couponTypes: [
        CouponType.Bxgy,
        CouponType.Amount,
        CouponType.CartAmount,
        CouponType.ProductAmount,
        CouponType.PurchaseHistory
      ],
      skip: 0,
      limit: rowPerPage
    }
  });

  useEffect(() => {
    const rowData = couponData?.getCoupons.data.map((row) => {
      return {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        activeLoading: false,
        visibilityLoading: false,
        row
      };
    });
    setRows(rowData);
  }, [couponData]);

  const updateColumnData = (type: 'success' | 'error', rowId: string, handler: 'status' | 'visibility') => {
    const rowData = couponData?.getCoupons.data.map((row) => {
      const loadingState = row.id === rowId ? true : false;
      const activeData = {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        activeLoading: type === 'success' ? loadingState : false,
        visibilityLoading: false,
        row
      };
      const visibilityData = {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        activeLoading: false,
        visibilityLoading: type === 'success' ? loadingState : false,
        row
      };
      return handler === 'status' ? activeData : visibilityData;
    });
    setRows(rowData);
  };

  const [updateCoupon] = useUpdateCouponMutation();
  const [deleteCouponMutation, { loading: deleting }] = useDeleteCouponMutation();
  const handleDeleteCouponMethod = async () => {
    try {
      await deleteCouponMutation({
        variables: {
          couponId: methodToDelete
        }
      });
      enqueueSnackbar('Hurray! Coupon deleted successfully!', {
        variant: 'success'
      });
      await refetch();
    } catch (e) {
      setMethodToDelete('');
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };
  const toggleCouponsCard = () => setShowCreateDiscount((prev) => !prev);
  const getNetSale = (sales: number) => {
    const netSales = formatIndianRupees(Number(sales.toFixed(2) || '0'));
    return netSales;
  };
  const getDiscountAmount = (discount: number) => {
    const discountAmount = formatIndianRupees(Number(discount.toFixed(2) || '0'));
    return discountAmount;
  };
  const getGrossSales = (sale: number) => {
    const grossSales = formatIndianRupees(Number(sale.toFixed(2) || '0'));
    return grossSales;
  };
  const getOrdersCount = (count: number) => {
    return count || '0';
  };
  const formatProducts = (products: any) => {
    let variants: any = [];
    for (let i = 0; i < products?.length; i += 1) {
      variants = [
        ...variants,

        ...products[i].variants.map((item: any) => {
          return { product_id: products[i].product_id, variant_id: item.variant_id };
        })
      ];
    }
    return variants;
  };
  const handleActiveStatus = async (updatedData: any, type: any, rowId: string) => {
    // eslint-disable-next-line camelcase
    const {
      id,
      metrics,
      // eslint-disable-next-line camelcase
      payment_offers,
      platform,
      tags,
      // eslint-disable-next-line camelcase
      purchase_requirement,
      application,
      // eslint-disable-next-line camelcase
      purchase_activity,
      ...rest
    } = updatedData;
    // eslint-disable-next-line camelcase
    const { collection_selections, product_selections, ...restPurchaseRequirements } = purchase_requirement;
    const { collection_selections: collection, product_selections: product, ...restApplications } = application;

    const constructPurchaseActivityData = () => {
      // eslint-disable-next-line camelcase
      const couponId = purchase_activity?.coupon_usage?.coupon?.id;
      const couponUsage =
        // eslint-disable-next-line camelcase
        purchase_activity?.coupon_usage === null ? null : purchase_activity ? { coupon_id: couponId } : null;

      // eslint-disable-next-line camelcase
      return purchase_activity
        ? {
            // eslint-disable-next-line camelcase
            ...purchase_activity,
            coupon_usage: couponUsage
          }
        : null;
    };

    try {
      if (type === 'visibility') {
        updateColumnData('success', rowId, 'visibility');
        await updateCoupon({
          variables: {
            couponId: updatedData?.id,
            data: {
              ...rest,
              application: {
                ...restApplications,
                products: formatProducts(updatedData?.application?.products),
                collections: getCollectionSelection(updatedData?.application?.collections),
                type: updatedData?.application?.type
              },
              purchase_requirement: {
                ...restPurchaseRequirements,
                products: formatProducts(updatedData?.purchase_requirement?.products),
                collections: getCollectionSelection(updatedData?.purchase_requirement?.collections)
              },
              purchase_activity: constructPurchaseActivityData(),
              discoverable: !updatedData?.discoverable,
              active: updatedData?.active
            }
          }
        });
      } else {
        updateColumnData('success', rowId, 'status');
        await updateCoupon({
          variables: {
            couponId: updatedData?.id,
            data: {
              ...rest,
              application: {
                ...restApplications,
                products: formatProducts(updatedData?.application?.products),
                collections: getCollectionSelection(updatedData?.application?.collections),
                type: updatedData?.application?.type
              },
              purchase_requirement: {
                ...restPurchaseRequirements,
                products: formatProducts(updatedData?.purchase_requirement?.products),
                collections: getCollectionSelection(updatedData?.purchase_requirement?.collections)
              },
              purchase_activity: constructPurchaseActivityData(),
              discoverable: updatedData?.discoverable,
              active: !updatedData.active
            }
          }
        });
      }

      const messageType = type === 'visibility' ? 'visibility' : 'activation';
      enqueueSnackbar(`Hurray! Coupon ${messageType} updated successfully!`, {
        variant: 'success'
      });
    } catch (error) {
      updateColumnData('error', rowId, 'status');
      updateColumnData('error', rowId, 'visibility');
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
    await refetch();
  };
  const onRowClickHandler = (item: any) => {
    if (item?.row) {
      setShowDetailsModal(true);
      setCouponToEdit(item?.row);
    }
  };
  const handleConfirm = () => {
    handleDeleteCouponMethod();
    setShowModal(false);
  };

  function handleChangePage(event: any, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPageByValue(value: any) {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  }

  const handleUserInput = (e: any, type: string) => {
    setSearchedValue((prevState) => {
      return {
        ...prevState,
        [type]: e.target.value
      };
    });
  };

  const handleSearch = (searchParams: any, skip: number, limit: number) => {
    getCoupons({
      variables: {
        code: searchParams.input || '',
        skip,
        limit,
        couponTypes: [
          CouponType.Bxgy,
          CouponType.Amount,
          CouponType.CartAmount,
          CouponType.ProductAmount,
          CouponType.PurchaseHistory
        ]
      }
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    debouncedSearch(searchedValue, rowPerPage * page, rowPerPage);
  }, [searchedValue, rowPerPage, page]);

  return (
    <>
      <div className="mx-4">
        <Title title="Coupons">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setAddPopup(true);
            }}
            disabled={loading}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add Coupon
          </Button>
        </Title>
        <Input
          placeholder="Search"
          value={searchedValue.input}
          onChange={(e) => handleUserInput(e, 'input')}
          icon={<Unicons.UilSearch size={18} />}
          iconPosition={'start'}
          className="w-full sm:w-60"
        />

        <div className="mt-4">
          <DataTable
            columns={columns}
            data={rows}
            showFilterInput={false}
            showPagination={true}
            onRowClick={onRowClickHandler}
            rowSelection={false}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowPerPage}
            count={couponData?.getCoupons?.page_info?.total_count || 0}
            page={page}
            setPage={setPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPageByValue}
          />
        </div>
      </div>

      <CreateDiscount
        isEdit={editPopup}
        open={showCreateDiscount}
        setShowCreateDiscount={setShowCreateDiscount}
        refetch={refetch}
        couponDetails={couponToEdit}
        setCouponToEdit={setCouponToEdit}
        couponToAdd={couponToAdd}
        setCouponToAdd={setCouponToAdd}
      />

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={handleConfirm}
        headerText="Delete Coupon?"
        text="This Action will delete the selected coupons"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleting}
      />

      <Dialog open={addPopup} onOpenChange={() => setAddPopup(!addPopup)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Create Coupon</p>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0">
                <div className="text-[#121b38] mb-0.5">
                  {addCouponsData.map((each, index) => (
                    <div
                      key={each.id}
                      className={`flex items-center justify-between py-2 px-4 !cursor-pointer ${
                        index !== addCouponsData.length - 1 && 'border-b'
                      }`}
                      onClick={() => {
                        setCouponToAdd(each.id);
                        toggleCouponsCard();
                        setCouponToEdit(null);
                        setEditPopup(false);
                        setAddPopup(false);
                      }}
                    >
                      <div className="flex flex-col">
                        <Label size="sm" className="text-base font-medium cursor-pointer">
                          {each.title}
                        </Label>
                        <Label className="text-[#888D9B] cursor-pointer" size="sm">
                          {each.desc}
                        </Label>
                      </div>
                      {each?.isNew && <Chip text="NEW" variant="success" />}
                    </div>
                  ))}
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer direction="right" open={showDetailsModal} onOpenChange={(value: boolean) => setShowDetailsModal(value)}>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex justify-between items-center mb-4">
              <DrawerTitle>Coupon Details</DrawerTitle>
              <Button size="icon" variant="icon" onClick={() => setShowDetailsModal(false)}>
                <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
              </Button>
            </div>
            <DrawerDescription className="text-black text-left">
              <CreateDiscountPreview activeStep={4} selectedCoupon={couponToEdit} isEdit={showCreateDiscount} />
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </>
  );
};
