import { PaymentMethodResponse } from '../../../types/customization';

/* eslint-disable arrow-body-style */
type PaymentItemProps = {
  data: PaymentMethodResponse;
};

const MethodLayout1Content: React.FC<PaymentItemProps> = ({ data: { title, description, icon: Icon } }) => {
  return (
    <>
      <div className="mr-2 flex h-9 w-9 flex-row items-center justify-center overflow-hidden rounded-lg border">
        {typeof Icon === 'string' ? <img src={Icon} alt="payment methods" className="p-[2px]" /> : Icon && <Icon />}
      </div>
      <div className="mx-1 flex flex-col">
        <div className="text-sm font-medium text-[#4B5563]">{title}</div>
        <div className="whitespace-nowrap text-xs font-normal text-[#4B5563]">{description}</div>
      </div>
    </>
  );
};

export default MethodLayout1Content;
