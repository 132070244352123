/* eslint-disable indent */
import React, { FC } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

type props = {
  data: any;
};

export const CreateOfferInfoCard: FC<props> = ({ data }) => {
  return (
    <div className="mr-16 hidden sm:block">
      <div className="font-semibold mb-2">{data.heading}</div>
      <ul className="text-[#888D9B] list-disc">
        {data.info.map((item: any) => {
          return <li className="text-[#888D9B] ml-4 mb-4">{item} </li>;
        })}
      </ul>
    </div>
  );
};
