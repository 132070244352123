import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import { useAnalyticsConfigQuery, useUpdateGoogleAnalyticsConfigMutation } from '../../../../graphql';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  Card,
  Checkbox,
  Chip,
  Input,
  Label,
  Skeleton
} from '../../../flexyui';
import { useNavigate } from 'react-router-dom';

export const GoogleAnalytics: React.FC = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTurnOn, setShowTurnOn] = useState(false);
  const [credentials, setCredentials] = useState<any>({
    ua_enabled: false,
    ga_enabled: true,
    measurement_id: ''
  });

  const { data: integrationData, loading, refetch } = useAnalyticsConfigQuery();
  const [updateGAnalytics, { loading: googleAnalyticsLoading }] = useUpdateGoogleAnalyticsConfigMutation();

  const handleFormSubmitButtonClick = async () => {
    setOpenModal(false);
    await updateGAnalytics({
      variables: {
        configs: {
          ua_enabled: credentials.ua_enabled,
          ga_enabled: credentials.ga_enabled,
          measurement_id: credentials?.measurement_id?.trim()
        }
      }
    });
    await refetch();
  };

  useEffect(() => {
    if(integrationData?.analyticsConfig.google_analytics){
      setCredentials({
        measurement_id: integrationData?.analyticsConfig.google_analytics.measurement_id,
        ua_enabled: true,
        ga_enabled: integrationData.analyticsConfig.google_analytics.ga_enabled || false
    });
  }
  }, [integrationData]);

  const handleEnableGAChange = async () => {
    await updateGAnalytics({
      variables: {
        configs: {
          ua_enabled: integrationData?.analyticsConfig.google_analytics.ua_enabled || false,
          ga_enabled: !integrationData?.analyticsConfig.google_analytics.ga_enabled,
          measurement_id: credentials?.measurement_id?.trim()
        }
      }
    });
    await refetch();
  }

  const handleCancelClick = () => {
    setOpenModal(false);
    setCredentials({
      ua_enabled: integrationData?.analyticsConfig.google_analytics.ua_enabled || false,
      ga_enabled: integrationData?.analyticsConfig.google_analytics.ga_enabled,
      measurement_id: integrationData?.analyticsConfig.google_analytics.measurement_id
    });
  }

  return (
    <>
      <div className="items-center font-semibold text-lg my-2 hidden sm:flex">
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
          onClick={() => navigate('/settings/integrations')}
        >
          <Unicons.UilArrowLeft className="text-[#2A324C]" />
        </Button>
        <div className="ml-1.5">Google Analytics Conversions</div>
      </div>

      <Card className="p-3 pl-4">
        <div className="flex items-center">
          Enable Google Analytics(UA)
          <div className="mx-2 hidden sm:block">
            {loading ? (
              <Skeleton variant="circular" className="!m-0 w-[70px]" />
            ) : (
              <Chip
                text={integrationData?.analyticsConfig.google_analytics.ua_enabled ? 'Enabled' : 'Disabled'}
                variant={integrationData?.analyticsConfig.google_analytics.ua_enabled ? 'success' : 'draft'}
              />
            )}
          </div>
          <div className="flex items-center justify-end flex-1">
            {loading ? (
              <Skeleton className="w-[95px] h-[36px] !m-0" />
            ) : (
              <Button
                size={'md'}
                onClick={async () => {
                  setShowTurnOn(true);
                }}
                variant={integrationData?.analyticsConfig.google_analytics.ua_enabled ? 'outline' : 'default'}
                disabled={
                  !integrationData?.analyticsConfig?.google_analytics ||
                  !integrationData.analyticsConfig.google_analytics.measurement_id?.trim()
                }
              >
                {integrationData?.analyticsConfig.google_analytics.ua_enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <Card className="mt-4 p-4">
        <Label size={'paragraph'}>Conversion Actions</Label>
        <div className="border border-muted px-4 py-4 mt-2 rounded-[6px]">
          <div className="add-new-pixel">
            {loading ? (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Skeleton className="!m-0 h-5 w-5" />
                    <Skeleton className="w-[150px] !m-0" />
                  </div>
                  <div className="flex items-center gap-2">
                    <Skeleton className="!m-0 h-8 w-8" />
                    <Skeleton className="!m-0 h-8 w-8" />
                  </div>
                </div>
                <div className="mt-4">
                  <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                  <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
                </div>
              </>
            ) : (
              <>
                {integrationData?.analyticsConfig?.google_analytics?.measurement_id.trim() === '' ? (
                  <div className="text-center my-4">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <Unicons.UilPlus size={18} className="mr-2" />
                      Add Google Analytics Credentials
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className="pixel-id-container my-2">
                      <div className="text-[#888D9B] text-xs">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <Checkbox
                              disabled={googleAnalyticsLoading}
                              checked={integrationData?.analyticsConfig.google_analytics.ga_enabled}
                              id={'google_enabled'}
                              name={'Google Analytics Enabled'}
                              onChange={handleEnableGAChange}
                            />
                            <div className="grid gap-[5px] leading-none ml-3">
                              <Label
                                htmlFor={'pixel_enabled'}
                                size={'paragraph'}
                                className="font-normal text-[#595F74]"
                              >
                                Enable Google Analytics (GA4)
                              </Label>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Button
                              size="icon"
                              variant="icon"
                              onClick={() => {
                                setOpenModal(true);
                              }}
                              className="mr-2"
                            >
                              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74]" />
                            </Button>
                            <Button
                              size="icon"
                              variant="icon"
                              onClick={() => {
                                setShowModal(true);
                              }}
                              className="text-[#595F74] hover:text-red-500"
                            >
                              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
                            </Button>
                          </div>
                        </div>
                        <div className="pixel-id-title mt-3 font-normal text-[#595F74]">Measurement ID</div>
                        <div className="flex justify-start text-sm text-[#121B38] font-normal">
                          {integrationData?.analyticsConfig?.google_analytics?.measurement_id}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Card>

      <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Set Google Analytics</p>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0">
                <div className="my-2 px-4">
                  <Label size={'paragraph'}>Measurement Id</Label>
                  <Input
                    type="text"
                    name="measurement_id"
                    placeholder="Measurement Id"
                    value={credentials.measurement_id}
                    onChange={(e) => {
                      setCredentials({
                        ...credentials,
                        measurement_id: e.target.value
                      });
                    }}
                    className="text-[#121b38]"
                  />
                </div>
                <div className="mb-4" />
                {/* <div className="my-4 mt-2 px-4">*/}
                {/*  <Label size={'paragraph'}>API Key</Label>*/}
                {/*  <Input*/}
                {/*    type="text"*/}
                {/*    name="api_key"*/}
                {/*    placeholder="Api key"*/}
                {/*    value={credentials.ga_enabled}*/}
                {/*    onChange={(e) => {*/}
                {/*      setCredentials({*/}
                {/*        ...credentials,*/}
                {/*        ua_enabled: e.target.value*/}
                {/*      });*/}
                {/*    }}*/}
                {/*    className="text-[#121b38]"*/}
                {/*  />*/}
                {/* </div>*/}

                {/* <div className="my-4 mt-2 px-4 flex justify-between items-center">*/}
                {/*  <Label size={'paragraph'}>Enable Google Analytics</Label>*/}
                {/*  <Switch*/}
                {/*    checked={credentials.ga_enabled}*/}
                {/*    className="pointer-events-auto mr-1"*/}
                {/*    id={'enable google analytics'}*/}
                {/*    onCheckedChange={() => {*/}
                {/*      setCredentials({*/}
                {/*        ...credentials,*/}
                {/*        ga_enabled: !credentials.ga_enabled*/}
                {/*      });*/}
                {/*    }}*/}
                {/*  />*/}
                {/* </div>*/}
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              disabled={
                credentials?.measurement_id === '' ||
                credentials?.measurement_id === null ||
                credentials?.measurement_id === undefined
              }
              onClick={() => handleFormSubmitButtonClick()}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={async () => {
          await updateGAnalytics({
            variables: {
              configs: {
                ua_enabled: false,
                ga_enabled: true,
                measurement_id: ''
              }
            }
          });
          await refetch();
        }}
        text="This Action will delete the Google Analytics"
        loading={googleAnalyticsLoading}
        confirmActionVariant="destructive"
      />
      <ConfirmationDialog
        showModal={showTurnOn}
        setShowModal={setShowTurnOn}
        onSave={async () => {
          await updateGAnalytics({
            variables: {
              configs: {
                ua_enabled: !integrationData?.analyticsConfig.google_analytics.ua_enabled,
                ga_enabled: integrationData?.analyticsConfig.google_analytics.ga_enabled || false,
                measurement_id: credentials?.measurement_id?.trim()
              }
            }
          });
          await refetch();
        }}
        text={`This Action will ${
          integrationData?.analyticsConfig.google_analytics.ua_enabled ? 'disable' : 'enable'
        } the Google Analytics`}
        loading={googleAnalyticsLoading}
      />
    </>
  );
};
