/* eslint-disable indent */
import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { CreateDiscountInfoCard } from './create-coupons-info-card';
import { InfoCardUserEligibility } from '../../../constants/coupons/coupons';
import { Input, RadioGroup } from '../../flexyui';
import { RadioWithLabel } from '../../shared/radio-with-label/radio-with-label';
import { CheckboxWithLabel } from '../../shared/checkbox-with-label/checkbox-with-label';
import { DiscountFields } from '../create-discount/types';

export const CreateDiscountUserEligibility: FC = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <CreateDiscountInfoCard data={InfoCardUserEligibility[0]} />
        <div>
          <RadioGroup {...getFieldProps('user_order_requirement.type')} defaultValue="all">
            <RadioWithLabel
              label="All users"
              value="all"
              onClick={() => setFieldValue('user_order_requirement.type', 'all')}
            />
            <RadioWithLabel
              label="First time users"
              value="first"
              onClick={() => setFieldValue('user_order_requirement.type', 'first')}
            />
            <RadioWithLabel
              label="Total order count"
              value="order"
              onClick={() => setFieldValue('user_order_requirement.type', 'order')}
            />
          </RadioGroup>
          {values?.user_order_requirement?.type === 'order' && (
            <>
              <div className="flex gap-4 mt-8">
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Minimum order count</div>
                  <Input
                    {...getFieldProps('user_order_requirement.min_order_count')}
                    type="number"
                    error={
                      touched.user_order_requirement?.min_order_count &&
                      !!errors.user_order_requirement?.min_order_count
                    }
                    errorMessage={errors.user_order_requirement?.min_order_count}
                  />
                </div>
                {values?.user_order_requirement?.max_order_count_needed && (
                  <div className={'w-[150px]'}>
                    <div className="font-medium mb-1">Maximum order count</div>
                    <Input
                      {...getFieldProps('user_order_requirement.max_order_count')}
                      type="number"
                      error={
                        touched.user_order_requirement?.max_order_count &&
                        !!errors.user_order_requirement?.max_order_count
                      }
                      errorMessage={errors.user_order_requirement?.max_order_count}
                    />
                  </div>
                )}
              </div>
              <CheckboxWithLabel
                label="Maximum order count cap"
                value="user_order_requirement.max_order_count_needed"
                checked={values.user_order_requirement.max_order_count_needed}
                onClick={() =>
                  setFieldValue(
                    'user_order_requirement.max_order_count_needed',
                    !values.user_order_requirement.max_order_count_needed
                  )
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
