import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';

import { cn } from '../../../lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default: 'bg-primary text-[#ffffff] hover:bg-primary/90 disabled:bg-muted disabled:text-muted-foreground',
        primary:
          'bg-secondary border-[1.5px] border-[#2F72FF] text-[#2F72FF] hover:bg-[#f9fcff] rounded-lg disabled:bg-muted disabled:text-muted-foreground disabled:border-border',
        solid: 'bg-primary text-[#ffffff] hover:bg-primary/50 disabled:bg-muted disabled:text-muted-foreground',
        destructive: 'bg-destructive text-destructive-foreground',
        outline:
          'bg-secondary border border-border hover:bg-buttonHover disabled:bg-border disabled:text-muted-foreground',
        dangerOutline:
          'bg-secondary border border-[#ED6C03] hover:bg-[#FFFAF6] text-[#ED6C03] disabled:bg-border disabled:text-muted-foreground',
        secondary: 'bg-secondary border border-border text-secondary-foreground hover:bg-buttonHover !px-6',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline !p-0',
        info: 'text-black underline-offset-4 underline decoration-dashed decoration-slate-400	!p-0',
        icon: 'bg-secondary border border-border hover:bg-buttonHover',
        forward:
          'bg-primary text-[#ffffff] hover:bg-bg-primary/90 disabled:bg-muted disabled:text-muted-foreground h-9 max-w-fit rounded-md min-w-[81px] pr-3 pl-8',
        backward:
          'bg-secondary border border-border hover:bg-buttonHover disabled:bg-border disabled:text-muted-foreground h-9 max-w-fit rounded-md min-w-[81px] pl-3 pr-8'
      },
      size: {
        default: 'w-full h-[38px] px-4 py-2',
        sm: 'h-9 w-fit rounded-md px-3',
        md: 'h-9 max-w-fit rounded-md min-w-[81px] px-5',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-8 rounded-md px-1'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  forward?: boolean;
  backward?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, children, size, forward, backward, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {backward && <UilAngleLeft className="mr-4" />}
        {children}
        {forward && <UilAngleRight className="ml-4" />}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
