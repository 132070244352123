/* eslint-disable import/default */
import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import Charts from 'react-apexcharts';
import { DashboardGraphTitle } from '../title/title';
import { useGraphDataQuery, ComparableMetrics, MetricName } from '../../../graphql';
import { convertAmount, calculateIncrement } from '../../../utils/convert-amount';
import { SkeletonDashboard } from '../../shared/skeleton-dashboard/skeleton-dashboard';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  metricsData?: ComparableMetrics;
  loading: boolean;
  dateRange?: any;
};
export const TotalSales: React.FC<Props> = ({ metricsData, loading, dateRange }) => {
  const { data: graphData } = useGraphDataQuery({
    variables: {
      dateRange: {
        start: new Date(dateRange.startDate).toISOString(),
        end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
      },
      type: MetricName.TotalSales
    }
  });

  const calculateData = useCallback(() => {
    const dates: any = [];
    const currentData = graphData?.dataGraphMetrics?.current?.map((item: any) => {
      dates.push(dayjs.utc(item?.data_date_time).format('MMM D h:mm A'));
      return Math.round(item?.data);
    });
    const prevData = graphData?.dataGraphMetrics?.previous?.map((item: any) => {
      return Math.round(Number(item?.data));
    });
    return {
      series: [
        {
          name:
            dayjs.utc(metricsData?.current?.start).format('MMM D') !==
            dayjs.utc(metricsData?.current?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.current?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.current?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.current?.start).format('MMM D')}`,
          data: currentData || []
        },
        {
          name:
            dayjs.utc(metricsData?.previous?.start).format('MMM D') !==
            dayjs.utc(metricsData?.previous?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.previous?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.previous?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.previous?.end).format('MMM D')}`,
          data: prevData || [],
          color: '#C5C5C5'
        }
      ],
      dates
    };
  }, [graphData, metricsData]);
  // const firstDayOfMonth = dayjs(dateRange.startDate).startOf('month');
  // const lastDayOfMonth = dayjs(dateRange.startDate).endOf('month');
  // const datesArray = [];
  // for (let date = firstDayOfMonth; date.isBefore(lastDayOfMonth); date = date.add(1, 'day')) {
  //   datesArray.push(date.format('MMM D'));
  // }
  const options: any = {
    legend: {
      position: 'top',
      offsetY: 0,
      markers: {
        width: 10,
        height: 10
      },
      horizontalAlign: 'left',
      colors: ['black', '#A0A4AF'],
      style: {
        colors: ['black', '#A0A4AF']
      }
    },
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
        enabled: true,
        shared: true
      }
    },
    onDatasetHover: {
      highlightDataSeries: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      color: '#EEEEEE'
    },
    grid: {
      row: {
        colors: ['transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      tickAmount: calculateData().dates.length < 30 ? 3 : 4,
      labels: {
        rotate: 0,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'D",
          day: "MMM 'D",
          hour: 'HH:mm'
        },
        style: {
          colors: '#A0A4AF',
          fontSize: '10px' // Adjust the font size as needed
        }
      },
      axisBorder: {
        show: false // Hide the x-axis line
      },
      axisTicks: {
        show: false // Hide the x-axis ticks
      },
      crosshairs: {
        show: false // Hide the crosshair lines
      },
      tooltip: {
        enabled: false
      },
      categories:
        calculateData().dates.length === 24
          ? calculateData().dates.map((date: any) => dayjs(date).format('MMM D h:mm A'))
          : calculateData().dates.map((date: any) => dayjs(date).format('MMM D'))
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#A0A4AF'],
          fontSize: '10px' // Adjust the font size as needed
        },
        position: 'bottom', // Set the position to 'bottom' for y-axis labels at the bottom
        formatter: (value: number) => {
          if (value >= 1000000000) {
            // eslint-disable-next-line prefer-template
            return (value / 1000000000).toFixed(2) + 'B'; // Convert to "1b" format for billion or greater
          }
          if (value >= 1000000) {
            return `${(value / 1000000).toFixed(2).toString()} M`.replace('.00', ''); // Convert to "1m" format for million or greater
          }
          if (value >= 10000) {
            return `${(value / 1000).toFixed(2).toString()} K`.replace('.00', ''); // Convert to "10k" format for 10 thousand or greater
          }
          return value; // Return the original value for numbers below 10000
        }
      }
    }
  };

  return (
    <div className="bg-white row-span-2 border-b-[1px] md:border-b-[0px] md:border-r-[1px] sm:col-span-2 rounded-t-md md:rounded-tr-none md:rounded-bl-md">
      <div className="font-medium text-black pt-4 px-4 flex items-center">
        <div>Net Sales</div>
        <ButtonWithInfo
          heading="Net Sales"
          description="Total revenue after deducting the discounts."
          calculation={true}
          calculationVariable="Net Sales"
          calculationValue="gross - discounts"
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      {loading ? (
        <SkeletonDashboard heightGraph="h-[200px]" />
      ) : (
        <>
          <DashboardGraphTitle
            loading={loading}
            value={convertAmount(Number(metricsData?.current?.total_sales)) || '0'}
            title="Net Sales"
            prevValue={convertAmount(Number(metricsData?.previous?.total_sales)) || '0'}
            percentageChange={calculateIncrement(
              Number(metricsData?.current?.total_sales),
              Number(metricsData?.previous?.total_sales)
            )}
          />
          {metricsData?.current?.start ? (
            <Charts options={options} series={calculateData().series} type="line" height={300} />
          ) : (
            <div className="text-center my-40">
              <div className="text-gray-300 text-base">Not Available</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
